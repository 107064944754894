import { gql } from '@apollo/client'

export const GET_STUDY_QUERY = gql`
  query GetStudy($input: GetStudyInput!) {
    getStudy(input: $input) {
      study {
        id
        name
        participantIdPrefix
      }
    }
  }
`

export const GET_STUDY_FOLDERS = gql`
  query GetStudy($input: GetStudyInput!) {
    getStudy(input: $input) {
      study {
        id
        folders {
          id
          type
          name
        }
      }
    }
  }
`

export const UPDATE_STUDY_QUERY = gql`
  mutation UpdateStudy($input: UpdateStudyInput!) {
    updateStudy(input: $input) {
      study {
        id
        name
        participantIdPrefix
      }
    }
  }
`
