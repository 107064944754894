import { useApolloClient } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'

import FormattedDescription from '@@components/formatted-description'

import { UPDATE_REVISION_MUTATION } from './_queries'

export default function RevisionNote({ studyRevision }) {
  const [editing, setEditing] = useState(null)
  const client = useApolloClient()
  const editAreaRef = useRef()
  const note = studyRevision.note.trim()
  const isEditing = typeof editing === 'string'

  useEffect(() => {
    if (editAreaRef.current && editAreaRef.current.scrollIntoView) {
      editAreaRef.current.scrollIntoView()
    }
  })

  const onSave = () => {
    client.mutate({
      mutation: UPDATE_REVISION_MUTATION,
      variables: {
        input: {
          studyRevisionId: studyRevision.id,
          note: editing,
        },
      },
      optimisticResponse: {
        updateStudyRevision: {
          __typename: 'UpdateStudyRevisionPayload',
          studyRevision: {
            __typename: 'StudyRevision',
            id: studyRevision.id,
            note: editing,
            title: studyRevision.title,
          },
        },
      },
    })
    setEditing(null)
  }

  let content
  if (isEditing) {
    content = (
      <div ref={editAreaRef}>
        <div>
          <textarea
            autoFocus
            value={editing}
            onChange={(ev) => setEditing(ev.target.value)}
            rows="5"
            className="form-control"
            id="comment_editor"
          />
        </div>
        <div className="mt-3">
          <button type="button" onClick={onSave} className="btn btn-primary" id="save_comment_button">
            Save
          </button>
          <button type="button" onClick={() => setEditing(null)} className="btn btn-outline-secondary ms-2">
            Cancel
          </button>
        </div>
      </div>
    )
  } else if (note) {
    content = <FormattedDescription>{studyRevision.note.trim()}</FormattedDescription>
  } else {
    content = <p className="fw-light text-secondary fst-italic text-center">Comment is empty.</p>
  }

  return (
    <div className="card bg-light mt-4">
      <div className="card-body">
        <p className="fw-bold">
          Comment
          {!isEditing && (
            <button
              type="button"
              onClick={() => setEditing(note)}
              className="btn btn-link fs-7"
              id="edit_comment_button"
            >
              <i className="bi bi-pencil-fill" />
            </button>
          )}
        </p>
        {content}
      </div>
    </div>
  )
}
