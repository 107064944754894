import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'

import { StudyContext } from '@@pages/study'

import { GET_FILE_METADATA, GET_FILE_URLS, GET_PARTICIPANTS } from './_queries'

const AssetContext = React.createContext()

export function useAssetProvider() {
  const apollo = useApolloClient()
  const { participant } = useContext(AssetContext)
  const study = useContext(StudyContext)
  const [assetError, setAssetError] = useState(null)

  const getFolderMetadata = async ({ source, folder }) => {
    if (source === 'participant' && !participant) {
      setAssetError('You must select a participant.')
      return
    }
    const { data } = await apollo.query({
      query: GET_FILE_METADATA,
      variables: {
        input: {
          ownerType: source,
          ownerId: source === 'participant' ? participant : study.id,
          folderName: folder,
        },
      },
      fetchPolicy: 'network-only',
    })
    const files = data.quickGetFiles.files
    if (files.length > 0) {
      return {
        metadata: files.map((f) => ({
          filename: f.name,
          ...JSON.parse(f.metadata),
        })),
      }
    }
    setAssetError(`The ${source} does not have a folder named "${folder}".`)
  }

  // returns [source, folder, Map<filename, asset>]
  const groupAssetsByFolder = (assets) => {
    const folderBySource = new Map()

    for (const asset of assets) {
      if (!folderBySource.has(asset.source)) {
        folderBySource.set(asset.source, new Map())
      }
      const assetsByFolder = folderBySource.get(asset.source)
      if (!assetsByFolder.has(asset.folder)) {
        assetsByFolder.set(asset.folder, new Map())
      }
      assetsByFolder.get(asset.folder).set(asset.filename, asset)
    }

    const groupedAssets = []
    for (const [source, assetsByFolder] of folderBySource.entries()) {
      for (const [folder, folderAssets] of assetsByFolder.entries()) {
        groupedAssets.push([source, folder, folderAssets])
      }
    }

    return groupedAssets
  }

  const getFileUrls = async ({ assets }) => {
    const assetsByFolder = groupAssetsByFolder(assets)

    for (const [source, folder, folderAssets] of assetsByFolder) {
      const { data } = await apollo.query({
        query: GET_FILE_URLS,
        variables: {
          input: {
            ownerType: source,
            ownerId: source === 'participant' ? participant : study.id,
            folderName: folder,
            fileNames: Array.from(folderAssets.values()).map((f) => f.filename),
          },
        },
        fetchPolicy: 'network-only',
      })

      const files = data.getSpecificFiles.files
      // Look up assets by filename in the folder map and set their URL
      for (const { name, url } of files) {
        if (folderAssets.has(name)) {
          folderAssets.get(name).url = url
        } else {
          console.warn(`Asset not in lookup: "${name}"`)
        }
      }
    }

    // Just extracting the asset values from the folderAssets maps
    return {
      fileUrls: assetsByFolder.flatMap(([, , folderAssets]) => Array.from(folderAssets.values())),
    }
  }

  const handleAssetRequest = async (request, params) => {
    switch (request) {
      case 'metadata':
        return await getFolderMetadata(params)

      case 'urls':
        return await getFileUrls(params)
    }
  }
  return { assetError, handleAssetRequest }
}

function ParticipantSelector() {
  const { participant, setParticipant } = useContext(AssetContext)
  const study = useContext(StudyContext)
  const { loading, error, data } = useQuery(GET_PARTICIPANTS, {
    variables: {
      input: {
        id: study.id,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return (
      <div className="bg-light text-center p-2 border-top">
        <div className="spinner-border spinner-border-sm" />
      </div>
    )
  }

  return (
    <div className="bg-light p-2 border-top">
      <label htmlFor="select_participant" className="mb-1">
        Run as...
      </label>
      <select
        id="select_participant"
        value={participant || ''}
        onChange={(ev) => setParticipant(ev.target.value || null)}
        className="form-select"
      >
        <option value=""></option>
        {data.getStudy.study.participants.map((p) => (
          <option key={p.id} value={p.id}>
            {p.externalId}
          </option>
        ))}
      </select>
    </div>
  )
}

// i.e. select a participant
// ? could also use this are to set counterbalanceSeed, debug mode, etc.
export function AssetContextSelector() {
  const { participant } = useContext(AssetContext)
  const [expanded, setExpanded] = useState(Boolean(participant))

  return (
    <div className="my-3 d-grid">
      <button
        onClick={() => setExpanded(!expanded)}
        type="button"
        className="btn btn-light fs-7 p-1 text-start no-glow rounded-0"
      >
        {expanded ? <i className="bi bi-chevron-down me-2" /> : <i className="bi bi-chevron-right me-2" />}
        Test Settings
      </button>
      {expanded && <ParticipantSelector />}
    </div>
  )
}

export function AssetProvider({ children }) {
  const [participant, setParticipant] = useState(null)

  return <AssetContext.Provider value={{ participant, setParticipant }}>{children}</AssetContext.Provider>
}
