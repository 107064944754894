import React from 'react'
import { Link } from 'react-router-dom'

import { useUserAttributes } from '@@pages/login'

export default function AppLayout({ children }) {
  const { email } = useUserAttributes()
  return (
    <React.Fragment>
      <nav className="navbar fixed-top navbar-dark bg-primary py-1">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <b>Bruin</b>Health
          </Link>
          <div className="navbar-nav">
            <div className="nav-item dropdown">
              <button type="button" className="btn btn-link nav-link py-0 fs-7" data-bs-toggle="dropdown">
                {email}
                <i className="bi bi-caret-down-fill ms-1" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end position-absolute">
                <li>
                  <Link to="/logout" className="dropdown-item">
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="navbar-fix h-100">{children}</div>
    </React.Fragment>
  )
}
