import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import { UPDATE_ORG_QUERY } from './_queries.js'
import SingleTextEdit from './_single_text_edit.jsx'
import StudyList from './_study_list.jsx'

export default function Org({ org }) {
  const [editMode, setEditMode] = useState(false)
  const client = useApolloClient()

  const updateOrg = (name) => {
    if (!name || name.match(/^ *$/)) {
      return setEditMode(false)
    }

    const cache = client.cache
    cache.modify({
      id: cache.identify(org),
      fields: {
        name(cachedName) {
          return name
        },
      },
    })
    client.mutate({
      mutation: UPDATE_ORG_QUERY,
      variables: { input: { id: org.id, name } },
    })
    setEditMode(false)
  }

  const OrgHeader = () => {
    if (editMode) {
      return (
        <SingleTextEdit sizeClass="display-6" value={org.name} onCancel={() => setEditMode(false)} onSave={updateOrg}>
          Edit org name
        </SingleTextEdit>
      )
    } else {
      return (
        <div>
          <h1 className="display-6 d-inline-block pe-2">{org.name}</h1>
          {org.canEdit && (
            <button
              id={'edit-' + org.id}
              type="button"
              onClick={() => setEditMode(true)}
              className="btn btn-link position-relative px-2"
              style={{ top: '-4px' }}
            >
              <i className="bi bi-pencil-fill" />
            </button>
          )}
        </div>
      )
    }
  }

  return (
    <div className="mb-4">
      <OrgHeader />
      <StudyList org={org} />
    </div>
  )
}
