import { gql } from '@apollo/client'

// Define a consistent shape for cache consistency
const ORG_FIELDS = gql`
  fragment OrgFields on Org {
    id
    name
    canEdit
    studies {
      id
      name
    }
  }
`

export const GET_ORGS_QUERY = gql`
  ${ORG_FIELDS}
  query GetOrgs {
    getOrgs {
      id
      orgs {
        ...OrgFields
      }
    }
  }
`

export const UPDATE_ORG_QUERY = gql`
  mutation UpdateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      org {
        id
        name
      }
    }
  }
`

// Grab all fields so that the new org has the right shape
export const CREATE_ORG_QUERY = gql`
  ${ORG_FIELDS}
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      org {
        ...OrgFields
      }
    }
  }
`

export const CREATE_STUDY_QUERY = gql`
  mutation CreateStudy($input: CreateStudyInput!) {
    createStudy(input: $input) {
      study {
        id
        name
      }
    }
  }
`

export const GET_USER_QUERY = gql`
  query GetMyUser {
    me {
      id
      canCreateOrg
    }
  }
`
