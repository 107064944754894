import { useApolloClient } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from '@@components/modal'

import { AssessmentBankContext } from '.'
import { ADD_EVENT_MUTATION } from './_queries'

export default function AddAssessmentModal({ visit, onDone }) {
  const assessmentBank = useContext(AssessmentBankContext)
  const [selected, setSelected] = useState(null)
  const [waitForAdd, setWaitForAdd] = useState(false)
  const client = useApolloClient()

  const onAddClick = async () => {
    setWaitForAdd(true)
    await client.mutate({
      mutation: ADD_EVENT_MUTATION,
      variables: {
        input: {
          visitId: visit.id,
          studyAssessmentRevId: selected.currentRevision.id,
        },
      },
    })
    onDone(selected.currentRevision.id)
  }

  if (waitForAdd) {
    return (
      <Modal>
        <div className="align-self-center card shadow">
          <div className="card-body">
            <div className="spinner-border" />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal onClose={() => onDone()}>
      <div className="align-self-center card shadow" style={{ minWidth: '26rem' }}>
        <div className="card-header">
          <h5 className="my-2">Select an assessment from your bank.</h5>
        </div>
        <ul className="list-group list-group-flush overflow-auto" style={{ maxHeight: '20rem' }}>
          {assessmentBank.map((studyAssessment) => (
            <button
              id={'select-assessment-' + studyAssessment.id}
              type="button"
              onClick={() => setSelected(studyAssessment)}
              key={studyAssessment.id}
              className={
                'list-group-item list-group-item-action ' +
                (selected && selected.id === studyAssessment.id ? 'list-group-item-info fw-bold' : '')
              }
            >
              <i className="bi bi-calendar3-week me-2" />
              {studyAssessment.label}
            </button>
          ))}
          {assessmentBank.length === 0 && <li className="list-group-item py-3">Your assessment bank is empty!</li>}
        </ul>
        <div className="card-footer d-flex">
          <button
            id="add_study_assessment"
            onClick={onAddClick}
            disabled={!selected}
            type="button"
            className="btn btn-primary me-1"
          >
            Add
          </button>
          <button type="button" onClick={() => onDone()} className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
          <Link to="../assessments" className="ms-auto align-self-center text-decoration-none">
            Visit Assessment Library <i className="bi bi-box-arrow-up-right" />
          </Link>
        </div>
      </div>
    </Modal>
  )
}
