import React, { useEffect } from 'react'

import { getAuthFromRefreshToken } from './auth-client'

class RefreshTokenError extends Error {}

export async function getNewTokensFromRefresh() {
  const refreshToken = localStorage.getItem('refreshToken')
  if (refreshToken) {
    try {
      const data = await getAuthFromRefreshToken(refreshToken)
      if (data) {
        const { access_token, id_token } = data /* eslint camelcase: "off" */
        return {
          accessToken: access_token,
          idToken: id_token,
          refreshToken,
        }
      } else {
        console.dir(data)
      }
    } catch (err) {
      console.error(err)
    }
  }
  localStorage.clear()
  throw new RefreshTokenError('Could not refresh auth from auth token')
}

export default function Refresh({ onSuccess, onFailure }) {
  useEffect(async () => {
    try {
      onSuccess(await getNewTokensFromRefresh())
    } catch (err) {
      if (err instanceof RefreshTokenError) {
        onFailure()
      } else {
        throw err
      }
    }
  })

  return <div className="spinner-border" />
}
