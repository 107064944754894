import { getDefaultSettings } from '@bruinhealth/sdk'
import React, { useEffect, useRef, useState } from 'react'

import JsonEditor from '@@components/json-editor'

import { AssetContextSelector, AssetProvider } from './asset-provider'
import RunTask from './run-task'

export default function TaskVersionWidget({ onSave, name, version, initialConfig, children }) {
  const editorRef = useRef()
  const [runConfig, setRunConfig] = useState(() => {
    // Current, validated config being run
    try {
      const conf = sessionStorage.getItem('run-task-config')
      if (conf) return JSON.parse(conf)
    } catch (err) {
      console.error(err)
      sessionStorage.removeItem('run-task-config')
    }
    return null
  })
  const [waiting, setWaiting] = useState(true)
  const [hasEdits, setHasEdits] = useState(false)

  const isNew = !initialConfig

  useEffect(async () => {
    if (!initialConfig) {
      const defaultSettings = await getDefaultSettings({ name, version })
      const json = JSON.stringify(defaultSettings)
      editorRef.current.update(json)
      editorRef.current.saved(json)
    }
    if (runConfig) {
      editorRef.current.update(JSON.stringify(runConfig))
    }
    setWaiting(false)
  }, [name, version])

  const onRun = () => {
    const validatedConfig = editorRef.current.validate()
    if (validatedConfig) {
      // Store this in sessionStorage so it's preserved across refreshes (helpful in development)
      sessionStorage.setItem('run-task-config', validatedConfig)
      setRunConfig(JSON.parse(validatedConfig))
    }
  }

  const onCloseRunner = () => {
    sessionStorage.removeItem('run-task-config')
    setRunConfig(null)
  }

  const onReset = () => {
    editorRef.current.reset()
  }

  const onDefaults = async () => {
    setWaiting(true)
    const defaultSettings = await getDefaultSettings({ name, version })
    setWaiting(false)
    const json = JSON.stringify(defaultSettings)
    editorRef.current.update(json)
  }

  const handleSave = async () => {
    const json = editorRef.current.validate()
    if (json) {
      setWaiting(true)
      await onSave(json)
      editorRef.current.saved(json)
      setWaiting(false)
    }
  }

  //! When loading we want to be careful not to unmount the JsonEditor

  return (
    <AssetProvider>
      {runConfig && <RunTask name={name} version={version} config={runConfig} onClose={onCloseRunner} />}
      <div className="position-relative">
        <div style={{ width: '350px' }}>
          <div className="d-grid">
            <button
              id="run_task_button"
              type="button"
              onClick={onRun}
              className="btn btn-lg btn-outline-primary position-relative no-glow"
            >
              <i className="bi bi-play-circle-fill position-absolute top-50 start-0 translate-middle-y ms-3" />
              Preview Task
            </button>
          </div>
          <AssetContextSelector />
          <div className="d-flex justify-content-between align-items-end">
            <div className="fs-5">Task Config</div>
            <div className="text-end">
              <button type="button" onClick={onDefaults} className="btn btn-link p-0 fs-7 me-2">
                Defaults
              </button>
              <button type="button" onClick={onReset} className="btn btn-link p-0 fs-7">
                Reset
              </button>
            </div>
          </div>
          <JsonEditor ref={editorRef} waiting={waiting} json={initialConfig || '{}'} onHasEdits={setHasEdits} />
          <div className="d-grid mt-4">
            <button
              disabled={!isNew && !hasEdits}
              id="save_assessment_config"
              type="button"
              onClick={handleSave}
              className="btn btn-lg btn-primary position-relative no-glow"
            >
              <i className="bi bi-save position-absolute top-50 start-0 translate-middle-y ms-3" />{' '}
              {isNew ? 'Create' : 'Save'}
            </button>
          </div>
        </div>
        {(runConfig || waiting) && (
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: 'rgba(255,255,255,0.8)' }}
          >
            <div className="spinner-border" />
          </div>
        )}
      </div>
    </AssetProvider>
  )
}
