import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import Modal from '@@components/modal'

import { REMOVE_EVENT_MUTATION } from './_queries'

export default function DeleteEventModal({ event, onDone }) {
  const [waiting, setWaiting] = useState(false)
  const client = useApolloClient()

  const onConfirm = async () => {
    setWaiting(true)

    await client.mutate({
      mutation: REMOVE_EVENT_MUTATION,
      variables: {
        input: {
          id: event.id,
        },
      },
    })

    onDone(event)
  }

  if (waiting) {
    return (
      <Modal>
        <div className="align-self-center card shadow">
          <div className="card-body">
            <div className="spinner-border" />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal onClose={() => onDone()}>
      <div className="align-self-center card shadow">
        <div className="card-body">
          <p className="my-2 fs-5">Are you sure you want to delete this item?</p>
        </div>
        <div className="card-footer d-flex">
          <button id="delete_event_confirm" onClick={onConfirm} type="button" className="btn btn-danger me-1">
            Yes
          </button>
          <button type="button" onClick={() => onDone()} className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
