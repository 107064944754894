import React from 'react'

const urlRe = /\b(https?:\/\/[^\s$.?#].[^\s]*[^\s.,])/

export default function FormattedDescription({ children }) {
  return children.split('\n\n').map((pg, i) => (
    <div key={i} className="mb-4 text-break">
      {pg.split('\n').map((ln, j) => (
        <div key={j}>
          {ln.split(urlRe).map((s, k) => {
            if (k % 2 === 0) {
              return <span key={k}>{s}</span>
            }
            return (
              <a key={k} target="_blank" href={s} rel="noreferrer">
                {s}
              </a>
            )
          })}
        </div>
      ))}
    </div>
  ))
}
