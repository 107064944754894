import { gql } from '@apollo/client'

export const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolder($input: CreateFolderInput!) {
    createFolder(input: $input) {
      owner {
        id
        folders {
          id
          type
          name
        }
      }
    }
  }
`

export const UPDATE_FOLDER_MUTATION = gql`
  mutation UpdateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folder {
        id
        name
      }
    }
  }
`

export const GET_FOLDER_QUERY = gql`
  query GetFolder($input: GetFolderInput!) {
    getFolder(input: $input) {
      folder {
        id
        type
        name
        files {
          id
          type
          name
          url
          thumbnailUrl
          metadata
        }
      }
    }
  }
`

export const CREATE_UPLOAD_REQUEST = gql`
  mutation CreateUploadRequest($input: UploadRequest!) {
    createUploadRequest(input: $input) {
      url
      expiresAt
    }
  }
`

export const GET_UPLOAD_STATUS = gql`
  query GetUploadStatus($input: UploadStatusInput!) {
    getUploadStatus(input: $input) {
      key
      status
      statusMessage
      folder {
        id
        files {
          id
        }
      }
      file {
        id
        type
        name
        url
        thumbnailUrl
        metadata
      }
    }
  }
`

export const UPDATE_FILE = gql`
  mutation UpdateFileMutation($input: UpdateFileInput!) {
    updateFile(input: $input) {
      file {
        id
        name
        metadata
      }
    }
  }
`

export const DELETE_FILE = gql`
  mutation DeleteFileMutation($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      folder {
        id
        files {
          id
        }
      }
    }
  }
`
