import React, { useState } from 'react'

import { respondWithNewPassword } from './auth-client'

export default function NewPasswordPage({ session, username, onSuccess }) {
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const onSave = async () => {
    try {
      const data = await respondWithNewPassword(session, username, password)
      const { AccessToken, IdToken, RefreshToken } = data.AuthenticationResult
      onSuccess({
        accessToken: AccessToken,
        idToken: IdToken,
        refreshToken: RefreshToken,
      })
    } catch (err) {
      console.error(err)
      if (err.name === 'InvalidParameterException') {
        setErrorMessage('Invalid password.')
      } else {
        setErrorMessage(err.message)
      }
    }
  }

  const sizing = 'col-md-5 col-lg-4 col-xl-3'

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12" style={{ marginTop: '-40px' }} onKeyUp={({ key }) => key === 'Enter' && onSave()}>
          <h2 className="text-center mb-4">Please set a new password.</h2>
          <br />
          <div className={'mb-3 mx-auto ' + sizing}>
            <div className="form-floating">
              <input
                id="password"
                autoFocus
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                className="form-control form-control-lg"
                type="password"
                placeholder="Password"
              />
              <label htmlFor="password" className="text-muted">
                Password
              </label>
            </div>
          </div>
          <div className={'mb-2 mx-auto d-grid ' + sizing}>
            <button type="button" onClick={onSave} className="btn btn-primary btn-lg btn-block">
              Log in
            </button>
          </div>
          {errorMessage && (
            <div className="position-absolute start-50 translate-middle fw-bold text-danger text-center mt-4">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
