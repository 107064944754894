import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Waitable from '@@components/waitable'
import { useWebsocket } from '@@lib/websocket'
import { StudyContext, useStudyPaths } from '@@pages/study'

import { GET_DEFAULT_REVISION_QUERY, LOCK_PROTOCOL_MUTATION, START_SESSION_MUTATION } from './_queries'
import { ParticipantContext } from './show'

function NoRevisionWarning() {
  const { studyDesignPath } = useStudyPaths()

  return (
    <div className="d-flex align-items-center alert alert-danger shadow">
      <div className="col">
        You cannot begin testing with participants until you have completed and "locked" the study protocol. Visit the{' '}
        <Link to={studyDesignPath}>Study Design</Link> page to do this now.
      </div>
    </div>
  )
}

function NotLocked() {
  const participant = useContext(ParticipantContext)
  const [waiting, setWaiting] = useState(false)
  const { id: studyId } = useContext(StudyContext)
  const client = useApolloClient()
  const { loading, error, data } = useQuery(GET_DEFAULT_REVISION_QUERY, {
    variables: {
      input: {
        id: studyId,
      },
    },
  })

  if (error) throw error

  if (loading) return <div className="spinner-border" />

  if (!data.getStudy.study.defaultRevision) {
    return <NoRevisionWarning />
  }

  const onLockClick = async () => {
    setWaiting(true)
    await client.mutate({
      mutation: LOCK_PROTOCOL_MUTATION,
      variables: {
        input: {
          participantId: participant.id,
          studyRevisionId: data.getStudy.study.defaultRevision.id,
        },
      },
    })
  }

  return (
    <div className="d-flex align-items-center alert alert-warning shadow">
      <div className="col">
        When you're ready to begin testing with this participant, click the button to the right to lock in the current
        version of the study protocol and view the session list.
      </div>
      <div className="col-auto">
        <Waitable waiting={loading || waiting}>
          <button type="button" onClick={onLockClick} id="lock_revision_button" className="ms-2 btn btn-warning">
            Lock Study Protocol
          </button>
        </Waitable>
      </div>
    </div>
  )
}

export default function SessionList() {
  const participant = useContext(ParticipantContext)
  const [sessionWaiting, setSessionWaiting] = useState(null)
  const client = useApolloClient()
  const ws = useWebsocket()
  const history = useHistory()

  if (!participant.studyRevision) {
    return <NotLocked />
  }

  // Only the first started/unstarted session can be accessed
  const accessible = participant.sessions.find((s) => s.state === 'started' || s.state === 'unstarted')

  const startClick = async (session) => {
    setSessionWaiting(session.id)
    const result = await client.mutate({
      mutation: START_SESSION_MUTATION,
      variables: {
        input: {
          participantId: participant.id,
          sessionId: session.id,
        },
      },
    })
    await ws.sendMessage({
      type: 'SessionStarted',
      data: {
        id: result.data.startSession.participant.currentSession.id,
      },
      confirm: true,
    })
    setSessionWaiting(null)
    history.push('/session/' + result.data.startSession.participant.currentSession.id)
  }

  const SessionActions = ({ session }) => {
    switch (session.state) {
      case 'unstarted':
        if (accessible === session) {
          return (
            <button
              onClick={() => startClick(session)}
              id={`start_session_${session.id}`}
              className="btn btn-sm btn-success"
            >
              Start
            </button>
          )
        }
        return <span className="fs-7 text-muted">Unstarted</span>

      case 'started':
        return (
          <div className="text-info d-inline-block">
            In progress
            <div className="spinner-border spinner-border-sm ms-2" />
          </div>
        )

      case 'incomplete':
        return (
          <div className="text-danger d-inline-block">
            Incomplete
            <i className="bi bi-exclamation-circle ms-2" />
          </div>
        )

      case 'complete':
        return (
          <div className="text-success">
            Complete
            <i className="bi bi-check-circle ms-2" />
          </div>
        )
    }
    return session.state
  }

  return (
    <ul className="list-group">
      {participant.sessions.map((session, i) => (
        <li key={session.id} id={'session_' + session.id} className="list-group-item d-flex align-items-center">
          <div className="fw-bold text-nowrap me-auto">Visit {i + 1}</div>
          <Waitable waiting={sessionWaiting === session.id}>
            <SessionActions session={session} />
          </Waitable>
          <div className="ms-2 dropdown d-inline-block">
            <button type="button" data-bs-toggle="dropdown" className="btn btn-link no-glow px-1">
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link to={'/session/' + session.id} id={`open_session_${session.id}`} className="dropdown-item">
                  Open
                </Link>
              </li>
              {session.state === 'incomplete' && (
                <li>
                  <button
                    onClick={() => startClick(session)}
                    disabled={Boolean(participant.currentSession)}
                    type="button"
                    className="dropdown-item"
                  >
                    Restart
                  </button>
                </li>
              )}
            </ul>
          </div>
        </li>
      ))}
    </ul>
  )
}
