import { ApolloClient, ApolloLink, from, fromPromise, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ApolloProvider } from '@apollo/client/react'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ErrorBoundary from '@@pages/errors/boundary'
import NotFoundErrorPage from '@@pages/errors/not-found.jsx'
import AuthBoundary, { getIdToken } from '@@pages/login'
import OrgsIndex from '@@pages/orgs/index.jsx'
import SessionPage from '@@pages/session'
import StudyPage from '@@pages/study/index.jsx'

import AppLayout from './app-layout'
import { performRefresh } from './pages/login'

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.dir(graphQLErrors)
    for (const { message, locations, path, errorType } of graphQLErrors) {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      if (errorType === 'UnauthorizedException') {
        // Refresh access token and retry
        console.info('Refreshing auth token')
        return fromPromise(
          performRefresh().catch((err) => {
            console.dir(err)
            console.warn('Token auth failed, redirecting to /login')
            window.location = '/login'
          })
        ).flatMap(() => forward(operation)) // authMiddleware will reset headers
      }
      // Retry once after a timeout
      if (errorType === 'ExecutionTimeout') {
        console.info('Retrying...')
        return forward(operation)
      }
    }
  }
  if (networkError) {
    console.dir(networkError)
    console.error(`[Network error]: ${networkError}`)
  }
})

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URI,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getIdToken(),
    },
  })
  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    StudyRevision: {
      fields: {
        visits: {
          merge: false,
        },
      },
    },
    Visit: {
      fields: {
        events: {
          merge: false,
        },
      },
    },
    Folder: {
      fields: {
        files: {
          merge: false,
        },
      },
    },
  },
})

const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authMiddleware, httpLink]),
  connectToDevTools: true, // TODO: turn off in production
})

// TODO: Refresh token if there's a token error

export default function App() {
  return (
    <AuthBoundary>
      <ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <Switch>
            <Route exact path="/">
              <AppLayout>
                <OrgsIndex />
              </AppLayout>
            </Route>
            <Route path="/study/:id">
              <StudyPage />
            </Route>
            <Route path="/session/:id">
              <SessionPage />
            </Route>
            <Route>
              <NotFoundErrorPage />
            </Route>
          </Switch>
        </ApolloProvider>
      </ErrorBoundary>
    </AuthBoundary>
  )
}
