import React, { useContext, useReducer, useState } from 'react'

import { TaskContext } from './run-task'

function hasOwn(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

function generateCSV(data, columns) {
  columns = Array.from(columns)

  // Make sure the block field is always included
  if (!columns.includes('block')) {
    columns.unshift('block')
  }

  const escape = (field) => {
    if (field == null) {
      return ''
    }
    if (typeof field === 'number' && !Number.isFinite(field)) {
      return ''
    }
    return JSON.stringify(field)
  }

  const rows = [columns.map(escape)]

  for (const record of data) {
    rows.push(columns.map((c) => escape(record[c])))
  }

  return rows.map((r) => r.join(',')).join('\n')
}

export function TaskData({ taskData, onClose }) {
  const [currentBlock, setCurrentBlock] = useState(taskData.currentBlock)
  const { name } = useContext(TaskContext)
  let columns, data, updated
  if (currentBlock === null) {
    columns = taskData.columns
    data = taskData.blocks.map((b) => b.data)
    updated = taskData.currentBlock
  } else {
    columns = taskData.blocks[currentBlock].columns
    data = taskData.blocks[currentBlock].trials
    updated = taskData.blocks[currentBlock].updated
  }

  const stringify = (field) => {
    if (typeof field === 'undefined' || field === null) {
      return '-'
    }
    if (typeof field === 'number' && !Number.isInteger(field)) {
      if (!Number.isFinite(field)) {
        return '-'
      }
      return field.toFixed(2)
    }
    return field.toString()
  }

  const onDownload = (ev) => {
    ev.preventDefault()
    let filename = `${name}`
    if (currentBlock === null) {
      filename += '-blocks.csv'
    } else {
      filename += `-trials-${currentBlock}.csv`
    }
    const blob = new Blob([generateCSV(data, columns)], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="task-tester-overlay">
      <div
        className="card task-tester-modal position-absolute top-50 start-50 translate-middle shadow-sm"
        style={{ maxWidth: '90%', maxHeight: '90%' }}
      >
        <div className="card-header d-flex">
          <div className="fw-bold">Data Output</div>
          <button onClick={onClose} type="button" className="btn-close ms-auto" />
        </div>
        {data.length === 0 && (
          <div className="card-body text-center text-muted">
            <i>Waiting for data...</i>
          </div>
        )}
        {data.length > 0 && (
          <>
            <div className="overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    {Array.from(columns).map((c) => (
                      <th key={c} scope="col">
                        {c}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, i) => (
                    <tr key={row.key} className={updated === row.key ? 'table-info' : ''}>
                      {Array.from(columns).map((c) => (
                        <td key={c} scope="col">
                          {stringify(row[c])}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="card-footer d-flex align-items-center">
          <div className="nav nav-pills flex-grow-1">
            <button
              onClick={() => setCurrentBlock(null)}
              type="button"
              className={'nav-link ' + (currentBlock === null ? 'active' : '')}
            >
              Overview
            </button>
            {taskData.blocks.map((block) => (
              <button
                onClick={() => setCurrentBlock(block.block)}
                key={block.block}
                type="button"
                className={'nav-link ' + (currentBlock === block.block ? 'active' : '')}
              >
                {block.block}
              </button>
            ))}
          </div>
          <div>
            <button type="button" title="Download CSV" onClick={onDownload} className="btn btn-link has-tooltip">
              <i className="bi bi-download" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function useTaskData() {
  const { dataDictionary } = useContext(TaskContext)

  const reducer = (state, action) => {
    const blocks = [...state.blocks]
    const newState = { ...state, blocks }

    if (!blocks[action.obj.block]) {
      blocks[action.obj.block] = {
        block: action.obj.block,
        columns: new Set(['trial', ...Object.keys(dataDictionary)]),
        trials: [],
        updated: null,
        data: {
          key: action.obj.block,
          block: action.obj.block,
        },
      }
    }

    switch (action.type) {
      case 'trial':
        blocks[action.obj.block].trials[action.obj.trial] = {
          key: action.obj.trial,
          block: action.obj.block,
          trial: action.obj.trial,
          ...action.obj.data,
        }
        // No longer adding missing columns to trial data; all cols must be defined in the data dictionary
        // blocks[action.obj.block].columns = new Set([...blocks[action.obj.block].columns, ...Object.keys(action.obj.data)])
        blocks[action.obj.block].updated = action.obj.trial
        newState.currentBlock = action.obj.block
        break

      case 'block':
        blocks[action.obj.block].trials = action.obj.trials.map((trial) => ({
          key: trial.trial,
          block: trial.block,
          trial: trial.trial,
          ...trial.data,
        }))
        blocks[action.obj.block].updated = null
        blocks[action.obj.block].data = {
          key: action.obj.block,
          block: action.obj.block,
          trials: action.obj.trials.length,
          practice: action.obj.isPractice,
          ...action.obj.data,
        }
        newState.currentBlock = null
        newState.columns = new Set([...newState.columns, ...Object.keys(action.obj.data)])
        break
    }

    return newState
  }
  const [taskData, addTaskData] = useReducer(reducer, {
    columns: new Set(['block', 'trials', 'practice']),
    blocks: [],
    currentBlock: null,
  })

  const createDictionaryWarnings = (trialData) => {
    for (const col of Object.keys(trialData)) {
      if (!hasOwn(dataDictionary, col)) {
        console.warn(`Trial data field not in dictionary: ${col}`)
      }
    }
  }

  return {
    taskData,

    addTrial: (trial) => {
      createDictionaryWarnings(trial.data)
      addTaskData({ type: 'trial', obj: trial })
    },

    addBlock: (block) => {
      addTaskData({ type: 'block', obj: block })
    },
  }
}
