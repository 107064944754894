import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useRef, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import FileUploads from '@@components/files'
import { useWebsocket, WebsocketProvider } from '@@lib/websocket'
import { getIdToken } from '@@pages/login'

import { GET_PARTICIPANT_QUERY } from './_queries.js'
import SessionList from './_session-list'

export const ParticipantContext = React.createContext()

function ParticipantPage() {
  const participant = useContext(ParticipantContext)
  const inputRef = useRef()
  const [copied, setCopied] = useState(false)
  const { url } = useRouteMatch()

  const client = useApolloClient()
  useWebsocket({
    onMessage: ({ type, data }) => {
      switch (type) {
        case 'ParticipantConnection':
          client.cache.modify({
            id: client.cache.identify(participant),
            fields: {
              isOnline() {
                return data.isOnline
              },
            },
          })
          break
      }
    },
  })

  const { first_name, last_name, slug, externalId, isOnline } = participant // eslint camelcase: "off"
  const participantUrl = `${window.location.protocol}//${window.location.host}/participant/${slug}`

  const copyClick = async () => {
    inputRef.current.focus()
    await navigator.clipboard.writeText(participantUrl)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div>
      <div className="fs-7 d-flex">
        <Link to="." className="text-decoration-none">
          <i className="bi bi-arrow-left-short" />
          Back
        </Link>
      </div>

      <div className="border-bottom pb-3 mb-3 d-flex align-items-center">
        <div className="fs-1">
          <i className="bi bi-person-circle" />
        </div>
        <div className="mx-3">
          <div className="fs-5">{externalId}</div>
          {(first_name || last_name) && (
            <div className="fs-6 text-muted text-capitalize">
              {[first_name, last_name].filter((n) => n && n !== '').join(' ') /* eslint camelcase: "off" */}
            </div>
          )}
        </div>
        <div>
          {isOnline ? (
            <div className="badge bg-success fw-normal" style={{ boxShadow: '0 0 3px rgba(0, 255, 0, 1)' }}>
              <i className="bi bi-arrow-left-right me-2" />
              Waiting
            </div>
          ) : (
            <div className="badge bg-secondary fw-normal">
              <i className="bi bi-cloud-slash me-2" />
              Not Connected
            </div>
          )}
        </div>
        <div className="ms-auto">
          <Link to={url + '/edit'} className="btn btn-sm btn-outline-primary">
            <i className="bi bi-pencil-fill" />
          </Link>
        </div>
      </div>

      <div className="py-2">
        <label htmlFor="participant_url" className="form-label">
          Participant session link
        </label>
        <div className="input-group">
          <input
            id="participant_url"
            ref={inputRef}
            readOnly
            type="text"
            className="form-control no-glow"
            value={participantUrl}
            onFocus={(ev) => ev.target.select()}
          />
          {copied ? (
            <button className="btn btn-success no-glow" type="button">
              <i className="bi bi-check" />
            </button>
          ) : (
            <button className="btn btn-outline-secondary no-glow" type="button" onClick={copyClick}>
              <i className="bi bi-clipboard" />
            </button>
          )}
        </div>
      </div>

      <div className="mt-4 py-2">
        <SessionList />
      </div>

      <div className="mt-5 pt-3 border-top">
        <h4 className="fw-normal">File Uploads</h4>
        <FileUploads owner={participant} />
      </div>
    </div>
  )
}

export default function ShowParticipantPage() {
  const { params } = useRouteMatch()
  const { loading, error, data } = useQuery(GET_PARTICIPANT_QUERY, {
    variables: {
      input: {
        id: params.id,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return <div id="show-participant-spinner" className="spinner-border" />
  }

  return (
    <WebsocketProvider role="researcher" token={getIdToken()} participantId={params.id}>
      <ParticipantContext.Provider value={data.getParticipant.participant}>
        <ParticipantPage />
      </ParticipantContext.Provider>
    </WebsocketProvider>
  )
}
