import bs from 'bootstrap/dist/js/bootstrap.bundle'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { useUserAttributes } from '@@pages/login'
import { StudyContext } from '@@pages/study'

import { useStudyPaths } from '.'
import { BreadcrumbContext, useBreadcrumb } from './_breadcrumb'

const CollapsedContext = React.createContext()

const createTooltip = (el) =>
  new bs.Tooltip(el, {
    trigger: 'hover',
    delay: 0,
    placement: 'right',
  })

function StudyMenuItemGroup({ id, title, icon, children }) {
  const collapsed = useContext(CollapsedContext)
  const buttonRef = useRef()

  useEffect(() => {
    if (collapsed) {
      const tip = createTooltip(buttonRef.current)
      return () => tip.dispose()
    }
  })

  const hideTooltip = (ev) => {
    const tip = bs.Tooltip.getInstance(buttonRef.current)
    if (tip) {
      tip.hide()
    }
  }

  return (
    <div className="dropend">
      <button type="button" data-bs-toggle="dropdown" className="btn btn-link text-decoration-none p-0 study-menu-item">
        <div onClick={hideTooltip} ref={buttonRef} title={title} className="d-flex align-items-center p-3">
          <i className={`bi bi-${icon} study-menu-icon`} />
          <div className="me-auto">{title}</div>
          <i className="bi bi-caret-right-fill" />
        </div>
      </button>
      <div className="dropdown-menu bg-study-menu">{children}</div>
    </div>
  )
}

function StudyMenuItem({ id, title, to, path, icon, noTip }) {
  const collapsed = useContext(CollapsedContext)
  const { rootPath } = useStudyPaths()
  const linkRef = useRef()

  if (!to) {
    to = rootPath + path
  }

  useEffect(() => {
    if (collapsed && !noTip) {
      const tip = createTooltip(linkRef.current)
      return () => tip.dispose()
    }
  }, [collapsed])

  return (
    <Link to={to} title={title} ref={linkRef} className="text-decoration-none p-3 study-menu-item d-flex">
      <i className={`bi bi-${icon} study-menu-icon`} />
      <div>{title}</div>
    </Link>
  )
}

function CollapseButton({ onClick }) {
  const collapsed = useContext(CollapsedContext)
  const buttonRef = useRef()

  useEffect(() => {
    if (collapsed) {
      const tip = createTooltip(buttonRef.current)
      return () => tip.dispose()
    }
  })

  return (
    <button
      type="button"
      title="Expand"
      ref={buttonRef}
      onClick={onClick}
      className="btn btn-link text-decoration-none p-3 study-menu-item d-flex align-items-center"
    >
      <i className={`bi bi-arrow-${collapsed ? 'right' : 'left'}-circle study-menu-icon`} />
      <div className="me-auto">Collapse</div>
    </button>
  )
}

export default function StudyLayout({ children }) {
  const [breadcrumb, breadcrumbContextObj] = useBreadcrumb()
  const [menuCollapsed, setMenuCollapsed] = useState(JSON.parse(localStorage.getItem('menuCollapsed')))
  const collapseClickedRef = useRef() // Use to determine if resize should be triggered
  const { email } = useUserAttributes()
  const study = useContext(StudyContext)

  useEffect(() => {
    if (collapseClickedRef.current) {
      window.dispatchEvent(new UIEvent('resize'))
    }
  }, [menuCollapsed])

  const collapseClick = () => {
    collapseClickedRef.current = true
    const newValue = !menuCollapsed
    localStorage.setItem('menuCollapsed', newValue)
    setMenuCollapsed(newValue)
  }

  const studyName = study ? study.name : '...'

  return (
    <div className="d-flex flex-row h-100 align-items-stretch">
      <CollapsedContext.Provider value={menuCollapsed}>
        <div className="d-flex flex-column bg-study-menu me-3">
          <div
            className={
              'flex-grow-1 d-flex flex-column study-menu-container ' +
              (menuCollapsed ? 'study-menu-container-collapsed' : '')
            }
          >
            <div className="bg-primary mb-2">
              <StudyMenuItem to="/" icon="house-fill" title="Orgs &amp; Studies" />
            </div>

            {/* <StudyMenuItem path="" id="ops" icon="layout-wtf" title="Study Operations" /> */}
            <StudyMenuItemGroup id="settings" icon="toggles" title="Study Settings">
              <StudyMenuItem path="/profile" icon="textarea-t" title="Profile" noTip />
              <StudyMenuItem path="/files" icon="folder" title="Files" noTip />
            </StudyMenuItemGroup>
            <StudyMenuItem path="/assessments/library" icon="book" title="Assessment Library" />
            <StudyMenuItem path="/assessments" icon="cart4" title="Saved Assessments" />
            <StudyMenuItem path="/revisions" icon="diagram-3" title="Study Design" />
            <StudyMenuItem path="/participants" id="particpants" icon="people" title="Participant Management" />
            {/* <StudyMenuItem path="" id="data" icon="bar-chart-line" title="Data Management" /> */}

            <div className="mt-auto">
              <CollapseButton onClick={collapseClick} />
            </div>
          </div>
        </div>
      </CollapsedContext.Provider>

      <div className="d-flex flex-grow-1 flex-column">
        <div className="d-flex flex-row">
          <div className="flex-grow-1 d-flex align-items-center lead" style={{ height: '60px' }}>
            {studyName}
            <span className="mx-2">&gt;</span>
            {breadcrumb.map(({ value, path }, i) => {
              let item = (
                <span key={i} className="text-dark">
                  {value}
                </span>
              )
              if (path) {
                item = (
                  <Link key={i} to={path} className="text-decoration-none text-dark">
                    {value}
                  </Link>
                )
              }
              if (i > 0) {
                return (
                  <React.Fragment key={i}>
                    <span className="mx-2">&gt;</span>
                    {item}
                  </React.Fragment>
                )
              }
              return item
            })}
          </div>
          <div className="d-flex" style={{ height: '60px' }}>
            <div className="dropdown align-self-center">
              <button
                type="button"
                className="btn btn-link text-muted fs-7"
                style={{ outline: 'none' }}
                data-bs-toggle="dropdown"
              >
                {email}
                <i className="bi bi-caret-down-fill ms-1" />
              </button>
              <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end position-absolute">
                <li>
                  <Link to="/logout" className="dropdown-item">
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-grow-1 overflow-auto">
          <div className="flex-grow-1 p-4">
            <BreadcrumbContext.Provider value={breadcrumbContextObj}>{children}</BreadcrumbContext.Provider>
          </div>
        </div>
      </div>
    </div>
  )
}
