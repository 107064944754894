import update from 'immutability-helper'
import React, { useState } from 'react'

function isBlank(str) {
  return !str || str.match(/^ *$/)
}

export default function ParticipantEditForm({ participant, onSave, onCancel }) {
  const [ptData, setPtData] = useState(participant || {})
  const [error, setError] = useState(null)
  const [waitForSave, setWaitForSave] = useState(false)

  const updateField = (field, value) => {
    setError(null)
    setPtData(update(ptData, { [field]: { $set: value } }))
  }

  const onSaveClick = async () => {
    setError(null)
    if (isBlank(ptData.externalId)) {
      return setError('Participant ID is required.')
    }
    setWaitForSave(true)
    try {
      await onSave(ptData)
    } catch (error) {
      if (error.message.match(/DUPLICATE_EXTERNAL_ID/)) {
        setError('This Participant ID has already been used.')
      } else {
        console.error(error)
        setError(error.message)
      }
      setWaitForSave(false)
    }
  }

  return (
    <div className="form mt-4 pe-4">
      <div className="row mb-4">
        <div className="col-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="externalId"
              placeholder="Participant ID"
              value={ptData.externalId || ''}
              onChange={(ev) => updateField('externalId', ev.target.value)}
            />
            <label htmlFor="externalId">Participant ID</label>
          </div>
          <div className="form-text">Must be unique for this study.</div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="first_name"
              placeholder="First name"
              value={ptData.first_name || ''}
              onChange={(ev) => updateField('first_name', ev.target.value)}
            />
            <label htmlFor="first_name">First name</label>
          </div>
        </div>
        <div className="col">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="last_name"
              placeholder="Last name"
              value={ptData.last_name || ''}
              onChange={(ev) => updateField('last_name', ev.target.value)}
            />
            <label htmlFor="last_name">Last name</label>
          </div>
        </div>
      </div>
      <hr />
      <div className="text-center">
        {waitForSave && <div className="spinner-border" />}
        {!waitForSave && (
          <>
            <button type="button" onClick={onSaveClick} id="save-button" className={'btn btn-primary me-1'}>
              Save Changes
            </button>
            <button type="button" onClick={onCancel} id="cancel-button" className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </>
        )}
      </div>
      {error && <div className="alert alert-danger text-center mt-4">{error}</div>}
    </div>
  )
}
