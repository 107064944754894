import { gql } from '@apollo/client'

export const GET_PARTICIPANTS = gql`
  query GetStudy($input: GetStudyInput!) {
    getStudy(input: $input) {
      id
      study {
        id
        participants {
          id
          externalId
        }
      }
    }
  }
`

export const GET_FILE_METADATA = gql`
  query GetFileMetadata($input: QuickGetFilesInput!) {
    quickGetFiles(input: $input) {
      files {
        id
        name
        metadata
      }
    }
  }
`

export const GET_FILE_URLS = gql`
  query GetFileUrls($input: GetSpecificFilesInput!) {
    getSpecificFiles(input: $input) {
      files {
        id
        name
        url
      }
    }
  }
`
