import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'

import FileUploads from '@@components/files'

import { StudyContext } from '.'
import { GET_STUDY_FOLDERS } from './_queries'

export default function StudyFiles() {
  const study = useContext(StudyContext)
  const { loading, error, data } = useQuery(GET_STUDY_FOLDERS, {
    variables: {
      input: {
        id: study.id,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return <div className="spinner-border" />
  }

  return <FileUploads owner={data.getStudy.study} />
}
