import './styles/index.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import bs from 'bootstrap/dist/js/bootstrap.bundle'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './app.jsx'
window.bootstrap = bs

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0, // ? Configure per env?
  })
} else {
  console.warn('Sentry not configured.')
}

// All elements with has-tooltip class will show `title` attr as tooltip
global.bsTooltipInstance = new bs.Tooltip(document.body, {
  selector: '.has-tooltip',
  trigger: 'hover',
  delay: 200,

  // Automatic placement isn't working right - the caret gets misplaced sometimes
  placement: (elTooltip, elTrigger) => {
    if (elTrigger.dataset.bsPlacement && elTrigger.dataset.bsPlacement !== 'auto') {
      return elTrigger.dataset.bsPlacement
    }
    // Annoyingly, tooltips aren't removed from the screen sometimes. This automatically removes it after 10 secs:
    const id = elTooltip.id
    setTimeout(() => {
      const el = document.getElementById(id)
      if (el) el.remove()
    }, 10000)

    const { left, right } = elTrigger.getBoundingClientRect()

    // Need to add the tooltip to the dom to get its width
    const { visibility, position } = elTooltip.style
    elTooltip.style.position = 'absolute'
    elTooltip.style.visibility = 'hidden'
    document.body.appendChild(elTooltip)
    const width = elTooltip.clientWidth
    elTooltip.remove()
    elTooltip.style.position = position
    elTooltip.style.visibility = visibility

    if (document.body.clientWidth - left < width) {
      return 'left'
    }

    if (right < width) {
      return 'right'
    }

    // It automatically switches to "bottom" the right way
    return 'top'
  },
})

const rootElement = document.createElement('div')
rootElement.id = '__app_root'
document.body.appendChild(rootElement)
ReactDOM.render(<App />, rootElement)
