import React, { useEffect, useState } from 'react'

import Waitable from '@@components/waitable'

import GoogleLoginButton from './_google_login_button.jsx'
import { getAuthFromCode, getAuthFromUserPass, loginWithGoogle } from './auth-client.js'

function getAndHideQueryString() {
  const params = new URLSearchParams(window.location.search)
  window.history.replaceState(null, document.title, window.location.pathname)
  return params
}

export default function LoginPage({ onNewPassword, onLink, onLoggedIn, onPasswordReset }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const params = getAndHideQueryString()

  useEffect(async () => {
    if (params.has('code')) {
      const authCode = params.get('code')
      try {
        const { access_token, id_token, refresh_token } = await getAuthFromCode(authCode) /* eslint camelcase: "off" */
        onLoggedIn({
          accessToken: access_token,
          idToken: id_token,
          refreshToken: refresh_token,
        })
      } catch (err) {
        console.error(err)
        setErrorMessage(err.message)
      }
    }

    if (params.has('error_description')) {
      const error = params.get('error_description').replace(/^.* failed with error /, '')
      if (error.includes('%%LINKED%%')) {
        onLink()
      } else {
        setErrorMessage(error)
      }
    }
  })

  if (params.has('code') || params.has('error_description')) {
    return <div className="spinner-border" />
  }

  const onLogin = async () => {
    setWaiting(true)
    try {
      const data = await getAuthFromUserPass(username, password)
      if (data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        return onNewPassword(username, data.Session)
      }
      const { AccessToken, IdToken, RefreshToken } = data.AuthenticationResult
      onLoggedIn({
        accessToken: AccessToken,
        idToken: IdToken,
        refreshToken: RefreshToken,
      })
    } catch (err) {
      console.error(err)
      if (err.name === 'PasswordResetRequiredException') {
        return onPasswordReset()
      }
      setErrorMessage(err.message)
      setWaiting(false)
    }
  }
  const sizing = 'col-md-5 col-lg-4 col-xl-3'

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12" style={{ marginTop: '-40px' }} onKeyUp={({ key }) => key === 'Enter' && onLogin()}>
          <h2 className="display-2 mb-4 text-center">Welcome to Bruin.</h2>
          <br />
          <div className={'mb-2 mx-auto d-grid ' + sizing}>
            <GoogleLoginButton onClick={loginWithGoogle} />
          </div>
          <div className={'mb-2 mx-auto text-center py-2 ' + sizing}>or</div>
          <div className={'mb-2 mx-auto ' + sizing}>
            <div className="form-floating">
              <input
                id="email"
                autoFocus
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                className="form-control form-control-lg"
                type="email"
                placeholder="Email address"
              />
              <label htmlFor="email" className="text-muted">
                Email address
              </label>
            </div>
          </div>
          <div className={'mb-3 mx-auto ' + sizing}>
            <div className="form-floating">
              <input
                id="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                className="form-control form-control-lg"
                type="password"
                placeholder="Password"
              />
              <label htmlFor="password" className="text-muted">
                Password
              </label>
            </div>
          </div>
          <div className={'mb-2 mx-auto d-grid ' + sizing}>
            <Waitable waiting={waiting}>
              <button type="button" onClick={onLogin} className="btn btn-primary btn-lg btn-block">
                Log in
              </button>
            </Waitable>
          </div>
          {errorMessage && (
            <div className="position-absolute start-50 translate-middle fw-bold text-danger text-center mt-4">
              {errorMessage}
            </div>
          )}
          <p className="text-center text-secondary fs-6 fixed-bottom mb-4">
            By logging in, you agree to our{' '}
            <a href="https://bruinhealth.com/terms-of-service" target="_blank" rel="noreferrer">
              terms of use
            </a>{' '}
            and{' '}
            <a href="https://bruinhealth.com/privacy-policy" target="_blank" rel="noreferrer">
              privacy policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}
