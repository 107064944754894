import React, { useState } from 'react'

export default function SingleTextEdit({ value, onSave, onCancel, sizeClass, children }) {
  const [newName, setNewName] = useState(value || '')

  const onKeyUp = (key) => {
    switch (key) {
      case 'Enter':
        onSave(newName)
        break

      case 'Escape':
        setNewName(value || '')
        onCancel()
        break
    }
  }

  return (
    <div className="mb-2" style={{ whiteSpace: 'nowrap' }}>
      <input
        id="orgname"
        type="text"
        autoFocus
        className={'border-0 bg-light ' + sizeClass}
        style={{ outline: 'none' }}
        value={newName}
        onChange={(ev) => setNewName(ev.target.value)}
        onBlur={() => onSave(newName)}
        onKeyUp={({ key }) => onKeyUp(key)}
      />
      <span className="form-text ms-2 pb-4">{children}</span>
    </div>
  )
}
