import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React from 'react'

import InternalErrorPage from './internal-error.jsx'
import NetworkErrorPage from './network-error.jsx'
import NotFoundErrorPage from './not-found.jsx'

function ErrorPage({ error, componentStack, resetError }) {
  console.dir(error)
  if (error instanceof ApolloError) {
    if (error.networkError) {
      return <NetworkErrorPage />
    }
    if (error.graphQLErrors.some((e) => e.errorType === 'ExecutionTimeout')) {
      return <InternalErrorPage />
    }
    return <NotFoundErrorPage />
  }
  return <InternalErrorPage />
}

export default function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={(props) => <ErrorPage {...props} />} showDialog={false}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
