import React from 'react'

import GoogleLogo from '@@images/google-signin-logo.svg'

export default function GoogleLoginButton(props) {
  return (
    <button className="btn btn-lg btn-outline-dark border-2 text-center" onClick={props.onClick}>
      <img src={GoogleLogo} className="mb-1 me-2" /> Sign in with Google
    </button>
  )
}
