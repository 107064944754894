import { getDataDictionary } from '@bruinhealth/sdk'
import { TaskRunner } from '@bruinhealth/sdk/react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import Modal from '@@components/modal'

import { useAssetProvider } from './asset-provider'
import { TaskData, useTaskData } from './task-data'

export const TaskContext = React.createContext()

function FatalErrorModal({ message }) {
  return (
    <Modal>
      <div className="align-self-center card shadow border-0">
        <div className="card-header bg-danger text-white">
          <i className="bi bi-exclamation-triangle-fill me-2" />
          Fatal Error
        </div>
        <div className="card-body">
          <p>{message}</p>
          <p>Please go back and fix the issue before trying again.</p>
        </div>
      </div>
    </Modal>
  )
}

function StartTask({ config, onClose }) {
  const { name, version } = useContext(TaskContext)
  const [showData, setShowData] = useState(false)
  const taskRef = useRef()
  const { taskData, addTrial, addBlock } = useTaskData()
  const { handleAssetRequest, assetError } = useAssetProvider()

  // Remove scrollbar from background content
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.removeProperty('overflow')
    }
  })

  // z-index same as $zindex-fixed
  return (
    <div className="position-fixed top-0 start-0 w-100 h-100" style={{ zIndex: '1030' }}>
      <TaskRunner
        role="tester"
        name={name}
        version={version}
        settings={config}
        onTrial={addTrial}
        onBlock={addBlock}
        assetRequestHandler={handleAssetRequest}
        ref={taskRef}
      />
      <div className="task-tester-menu fs-3 p-2">
        <div title="Exit" onClick={onClose} className="task-tester-menu-item mb-3 has-tooltip">
          <i className="bi bi-x-lg" />
        </div>
        <div
          title="Reload"
          onClick={() => taskRef.current.restart()}
          className="task-tester-menu-item mb-3 has-tooltip"
        >
          <i className="bi bi-arrow-counterclockwise" />
        </div>
        <div title="View data" onClick={() => setShowData(true)} className="task-tester-menu-item has-tooltip">
          <i className="bi bi-table" />
        </div>
      </div>
      <div className="task-tester-menu-bottom fs-3 p-2 d-flex flex-row">
        <div
          title="Restart task"
          onClick={() => taskRef.current.sendSignal('restart')}
          className="task-tester-menu-item mx-1 has-tooltip"
        >
          <i className="bi bi-skip-start-fill" />
        </div>
        <div
          title="Last block"
          onClick={() => taskRef.current.sendSignal('back')}
          className="task-tester-menu-item mx-1 has-tooltip"
        >
          <i className="bi bi-skip-backward-fill" />
        </div>
        <div
          title="Restart block"
          onClick={() => taskRef.current.sendSignal('retry')}
          className="task-tester-menu-item mx-1 has-tooltip"
        >
          <i className="bi bi-arrow-repeat" />
        </div>
        <div
          title="Next"
          onClick={() => taskRef.current.sendSignal('skip')}
          className="task-tester-menu-item mx-1 has-tooltip"
        >
          <i className="bi bi-skip-forward-fill" />
        </div>
        <div
          title="End task"
          onClick={() => taskRef.current.sendSignal('skip-all')}
          className="task-tester-menu-item mx-1 has-tooltip"
        >
          <i className="bi bi-skip-end-fill" />
        </div>
      </div>
      {showData && <TaskData onClose={() => setShowData(false)} taskData={taskData} />}
      {assetError && (
        <span className="task-tester-ontop">
          <FatalErrorModal message={assetError} />
        </span>
      )}
    </div>
  )
}

export default function RunTask({ name, version, config, onClose }) {
  const [dataDictionary, setDataDictionary] = useState()

  useEffect(async () => {
    setDataDictionary(await getDataDictionary({ name, version }))
  }, [name, version])

  if (!dataDictionary) {
    return null
  }

  return (
    <TaskContext.Provider value={{ name, version, dataDictionary }}>
      <StartTask config={config} onClose={onClose} />
    </TaskContext.Provider>
  )
}
