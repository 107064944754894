import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useMemo, useState } from 'react'
import { Link, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import FormattedDescription from '@@components/formatted-description'

import {
  GET_STUDY_ASSESSMENT_QUERY,
  GET_STUDY_ASSESSMENT_REVISION_QUERY,
  UPDATE_STUDY_ASSESSMENT_CONFIG_MUTATION,
  UPDATE_STUDY_ASSESSMENT_MUTATION,
} from './_queries.js'
import TaskVersionWidget from './task-version-widget'

const StudyAssessmentContext = React.createContext()

function EditableLabel() {
  const studyAssessment = useContext(StudyAssessmentContext)
  const [label, setLabel] = useState(studyAssessment.label)
  const [editMode, setEditMode] = useState(false)
  const client = useApolloClient()

  const save = () => {
    const saveLabel = label.trim()
    if (saveLabel === '') {
      return cancel()
    }

    const cache = client.cache
    cache.modify({
      id: cache.identify(studyAssessment),
      fields: {
        label(cachedLabel) {
          return saveLabel
        },
      },
    })
    client.mutate({
      mutation: UPDATE_STUDY_ASSESSMENT_MUTATION,
      variables: {
        input: {
          id: studyAssessment.id,
          label: saveLabel,
        },
      },
    })

    setLabel(saveLabel)
    setEditMode(false)
  }

  const cancel = () => {
    setLabel(studyAssessment.label)
    setEditMode(false)
  }

  const onKeyUp = ({ key }) => {
    switch (key) {
      case 'Enter':
        save()
        break

      case 'Escape':
        cancel()
        break
    }
  }

  if (editMode) {
    return (
      <div className="mb-2 position-relative text-nowrap" style={{ zIndex: '1000' }}>
        <h4 className="display-6 invisible">&nbsp;</h4>
        <div className="position-absolute start-0 top-0">
          <input
            id="label"
            type="text"
            autoFocus
            className="border-0 p-0 bg-light display-6"
            style={{ outline: 'none' }}
            value={label}
            onChange={(ev) => setLabel(ev.target.value)}
            onBlur={cancel}
            onKeyUp={onKeyUp}
            size={label.length}
          />
          <span className="form-text ms-2 pb-4">Edit label</span>
        </div>
      </div>
    )
  }

  return (
    <div className="mb-2">
      <h4 className="display-6">
        {label}
        <button
          id="edit_label"
          type="button"
          onClick={() => setEditMode(true)}
          className="btn btn-link position-relative ps-3"
          style={{ top: '-4px' }}
        >
          <i className="bi bi-pencil-fill" />
        </button>
      </h4>
    </div>
  )
}

function StudyAssessmentRevision({ revisionId }) {
  const studyAssessment = useContext(StudyAssessmentContext)
  const { loading, error, data } = useQuery(GET_STUDY_ASSESSMENT_REVISION_QUERY, {
    variables: {
      input: {
        id: revisionId,
      },
    },
  })
  const client = useApolloClient()

  if (error) {
    throw error
  }

  if (loading) {
    return <div className="spinner-border" />
  }

  const {
    config,
    assessmentVersion: { version },
  } = data.getStudyAssessmentRev.studyAssessmentRev
  const { name, title, description } = data.getStudyAssessmentRev.studyAssessmentRev.assessmentVersion.assessment

  const onSave = async (configJson) => {
    await client.mutate({
      mutation: UPDATE_STUDY_ASSESSMENT_CONFIG_MUTATION,
      variables: {
        input: {
          id: studyAssessment.id,
          config: configJson,
        },
      },
    })
  }

  // Config is guaranteed to be stored as valid JSON
  return (
    <div className="row gx-5">
      <div className="col">
        <Link to="." className="fs-7 text-decoration-none">
          <i className="bi bi-arrow-left-short" />
          Back
        </Link>
        <EditableLabel />
        <div className="mb-4 text-muted">
          {title} <b>v{version}</b>
        </div>
        <FormattedDescription>{description}</FormattedDescription>
      </div>
      <div className="col-auto">
        <TaskVersionWidget key={revisionId} name={name} version={version} initialConfig={config} onSave={onSave} />
      </div>
    </div>
  )
}

function RevisionRow({ revision, selected, current }) {
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  search.set('revision', revision.id)
  const createdAt = new Date(revision.createdAt)
  return (
    <Link
      type="button"
      replace
      to={{ ...location, search: search.toString() }}
      className={
        'revision-row list-group-item list-group-item-action d-flex justify-content-between align-items-center ' +
        (selected ? 'list-group-item-primary' : '')
      }
    >
      <div>
        {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
      </div>
      {current && <span className="badge bg-primary">Current</span>}
    </Link>
  )
}

export default function StudyAssessmentPage() {
  const location = useLocation()
  let selectedRevisionId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('revision')
  })
  const {
    params: { studyAssessmentId },
  } = useRouteMatch()
  const { loading, error, data, client } = useQuery(GET_STUDY_ASSESSMENT_QUERY, {
    variables: {
      input: {
        id: studyAssessmentId,
      },
    },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return <div className="spinner-border" />
  }

  const { currentRevision, revisions } = data.getStudyAssessment.studyAssessment

  // Write currentRevision to cache so it doesn't need to be refetched from server
  client.writeQuery({
    query: GET_STUDY_ASSESSMENT_REVISION_QUERY,
    variables: {
      input: {
        id: currentRevision.id,
      },
    },
    data: {
      getStudyAssessmentRev: {
        __typename: 'GetStudyAssessmentRevPayload',
        studyAssessmentRev: currentRevision,
      },
    },
  })

  if (selectedRevisionId) {
    // Make sure it's valid
    const found = revisions.find((rev) => rev.id === selectedRevisionId)
    if (!found) {
      return <Redirect to={{ ...location, search: '' }} />
    }
  } else {
    selectedRevisionId = currentRevision.id
  }

  return (
    <StudyAssessmentContext.Provider value={data.getStudyAssessment.studyAssessment}>
      <div className="container-fluid p-0">
        <StudyAssessmentRevision revisionId={selectedRevisionId} />
        <div className="row border-top mt-4 pt-2">
          <h4>Revision History</h4>
          <div className="list-group mt-4">
            {revisions.map((revision) => (
              <RevisionRow
                key={revision.id}
                revision={revision}
                current={revision.id === currentRevision.id}
                selected={revision.id === selectedRevisionId}
              />
            ))}
          </div>
        </div>
      </div>
    </StudyAssessmentContext.Provider>
  )
}
