import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/react'
import EventEmitter from 'events'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { CREATE_UPLOAD_REQUEST, GET_UPLOAD_STATUS } from './_queries'

const FileSizeLimit = 5 // MB

const FileUploadContext = React.createContext()

export function FileUploadProvider({ children }) {
  const apollo = useApolloClient()
  const uploadState = useRef()

  useEffect(() => {
    const ee = new EventEmitter()
    const uploadsData = new Map()
    let timerId

    const getUploadUrl = async (folderId, filename) => {
      const { data } = await apollo.mutate({
        mutation: CREATE_UPLOAD_REQUEST,
        variables: {
          input: {
            folderId,
            filename,
          },
        },
        fetchPolicy: 'no-cache',
      })
      return data.createUploadRequest.url
    }

    const getFolderUploads = (folderId) => {
      if (!uploadsData.has(folderId)) {
        uploadsData.set(folderId, new Map())
      }
      return uploadsData.get(folderId)
    }

    // Create a temporary record for UI feedback
    const createUploadPlaceholder = (folderId, file) => {
      const folderUploads = getFolderUploads(folderId)
      folderUploads.set(file.name, {
        url: file.name,
        name: file.name,
        status: 'initial',
      })
      ee.emit('uploads-updated', folderId)
    }

    const createUpload = async (folderId, file) => {
      const folderUploads = getFolderUploads(folderId)
      const url = await getUploadUrl(folderId, file.name)
      const uploadInfo = {
        url,
        name: file.name,
        status: 'initial',
      }
      folderUploads.set(url, uploadInfo)
      folderUploads.delete(file.name) // delete placeholder
      ee.emit('uploads-updated', folderId)
      return uploadInfo
    }

    const startUpload = ({ url, file, onProgress }) =>
      new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.upload.addEventListener('progress', (ev) => {
          if (ev.lengthComputable) {
            onProgress(ev.loaded / ev.total)
          }
        })
        xhr.addEventListener('loadend', () => {
          resolve(xhr.readyState === 4 && xhr.status === 200)
        })
        xhr.open('PUT', url, true)
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.send(file)
      })

    const getUploadStatus = async (url) => {
      const { data } = await apollo.query({
        query: GET_UPLOAD_STATUS,
        variables: {
          input: {
            url,
          },
        },
        fetchPolicy: 'network-only',
      })
      return data.getUploadStatus
    }

    // Single loop to update status of all uploads
    const updateStatus = async () => {
      for (const [folderId, folderUploads] of uploadsData.entries()) {
        for (const uploadInfo of folderUploads.values()) {
          if (uploadInfo.status === 'waiting') {
            try {
              const { status, statusMessage } = await getUploadStatus(uploadInfo.url)
              uploadInfo.status = status
              uploadInfo.statusMessage = statusMessage
            } catch (err) {
              Sentry.captureException(err)
              console.error(err)
              uploadInfo.status = 'error'
              uploadInfo.statusMessage = 'Internal error'
            }
            ee.emit('uploads-updated', folderId)
          }

          if (uploadInfo.status === 'success') {
            folderUploads.delete(uploadInfo.url)
            ee.emit('uploads-updated', folderId)
          }
        }
      }
      timerId = setTimeout(updateStatus, 1000)
    }

    // Start the status update loop
    updateStatus()

    uploadState.current = {
      onUpdate: (folderId, cb) => {
        const listener = (fid) => {
          if (fid === folderId) {
            cb(getFolderUploads(folderId))
          }
        }
        ee.on('uploads-updated', listener)
        return () => ee.off('uploads-updated', listener)
      },

      onError: (folderId, cb) => {
        const listener = (fid, errorMessage) => {
          if (fid === folderId) {
            cb(errorMessage)
          }
        }
        ee.on('on-error', listener)
        return () => ee.off('on-error', listener)
      },

      upload: async (folderId, files) => {
        if (Array.from(files).some((file) => file.size > FileSizeLimit * 1024 * 1024)) {
          ee.emit('on-error', folderId, 'Each file must be <5MB')
          return
        }

        // Create placeholders for all of the files in the list
        for (const file of files) {
          createUploadPlaceholder(folderId, file)
        }

        // Upload each file serially
        for (const file of files) {
          const uploadInfo = await createUpload(folderId, file)
          const success = await startUpload({
            url: uploadInfo.url,
            file,
            onProgress: (pct) => {
              uploadInfo.status = 'uploading'
              uploadInfo.progress = pct
              ee.emit('uploads-updated', folderId)
            },
          })

          if (success) {
            uploadInfo.status = 'waiting'
          } else {
            uploadInfo.status = 'error'
            uploadInfo.statusMessage = 'Upload error'
          }
          ee.emit('uploads-updated', folderId)
        }
      },

      remove: (folderId, url) => {
        const folderUploads = getFolderUploads(folderId)
        if (folderUploads.has(url)) {
          folderUploads.delete(url)
          ee.emit('uploads-updated', folderId)
        }
      },
    }
    return () => {
      clearTimeout(timerId)
      ee.removeAllListeners()
    }
  }, [])

  return <FileUploadContext.Provider value={uploadState}>{children}</FileUploadContext.Provider>
}

export function useUploader(folderId) {
  const { onUpdate, onError, upload, remove } = useContext(FileUploadContext).current
  const [folderUploads, setFolderUploads] = useState([])
  const [uploadError, setUploadError] = useState(null)

  useEffect(() => {
    const cleanupUpdate = onUpdate(folderId, (uploadsMap) => {
      setFolderUploads(Array.from(uploadsMap.values()))
    })
    const cleanupError = onError(folderId, (errorMessage) => {
      setUploadError(errorMessage)
    })
    return () => {
      cleanupUpdate()
      cleanupError()
    }
  }, [])

  return {
    // Sort uploads so that those in the "initial" state come last
    uploads: folderUploads
      .filter(({ status }) => status !== 'initial')
      .concat(folderUploads.filter(({ status }) => status === 'initial')),

    upload: (files) => {
      setUploadError(null)
      upload(folderId, files)
    },
    uploadError,
    remove: (upload) => {
      remove(folderId, upload.url)
    },
  }
}
