import React from 'react'

export default function NetworkErrorPage() {
  return (
    <div className="container-fluid h-100 bg-light">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 text-center">
          <h1 className="display-1 text-muted">
            <i className="bi bi-cloud-slash" /> Network Error
          </h1>
          <p className="lead text-muted">
            There is a problem with your connection to the server. Please reload to try again.
          </p>
        </div>
      </div>
    </div>
  )
}
