import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  // ForgotPasswordCommand,
  // ConfirmForgotPasswordCommand
} from '@aws-sdk/client-cognito-identity-provider'

const client = new CognitoIdentityProviderClient({ region: process.env.AWS_REGION })
const authUrl = `https://${process.env.USER_POOL_DOMAIN}.auth.${process.env.AWS_REGION}.amazoncognito.com`

export function loginWithGoogle() {
  // TODO: Use state param to prevent CSRF attacks (store code in localstorage?)
  const redirectUrl = encodeURIComponent(`${window.location.origin}/login`)
  window.location.href = `${authUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${process.env.USER_POOL_CLIENT_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20profile`
}

function post(url, contentType, data) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('post', url, true)
    xhr.setRequestHeader('Content-Type', contentType)
    xhr.responseType = 'json'
    xhr.send(data)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status
        if (status === 0 || (status >= 200 && status < 400)) {
          resolve(xhr.response)
        } else {
          reject(xhr.response.error)
        }
      }
    }
  })
}

export async function getAuthFromCode(authCode) {
  const redirectUrl = encodeURIComponent(`${window.location.origin}/login`)
  const data = await post(
    `${authUrl}/oauth2/token`,
    'application/x-www-form-urlencoded',
    `grant_type=authorization_code&code=${authCode}&client_id=${process.env.USER_POOL_CLIENT_ID}&redirect_uri=${redirectUrl}`
  )
  return data
}

export async function getAuthFromRefreshToken(refreshToken) {
  const redirectUrl = encodeURIComponent(`${window.location.origin}/login`)
  const data = await post(
    `${authUrl}/oauth2/token`,
    'application/x-www-form-urlencoded',
    `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${process.env.USER_POOL_CLIENT_ID}&redirect_uri=${redirectUrl}`
  )
  return data
}

export async function getAuthFromUserPass(username, password) {
  const cmd = new InitiateAuthCommand({
    AuthFlow: 'USER_PASSWORD_AUTH',
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
    ClientId: process.env.USER_POOL_CLIENT_ID,
  })

  // throws NotAuthorizedException if pw is wrong
  // throws PasswordResetRequiredException if pw reset is needed (old pw was correct)
  try {
    return await client.send(cmd)
  } catch (err) {
    switch (err.name) {
      case 'InvalidParameterException':
        throw new Error('Incorrect username or password.')
      default:
        throw err
    }
  }
}

export async function respondWithNewPassword(session, username, password) {
  const cmd = new RespondToAuthChallengeCommand({
    Session: session,
    ChallengeName: 'NEW_PASSWORD_REQUIRED',
    ChallengeResponses: { USERNAME: username, NEW_PASSWORD: password },
    ClientId: process.env.USER_POOL_CLIENT_ID,
  })
  return await client.send(cmd)
}

export async function getUserData(accessToken) {
  const cmd = new GetUserCommand({
    AccessToken: accessToken,
  })
  return await client.send(cmd)
}

export async function logout() {
  const redirectUrl = encodeURIComponent(`${window.location.origin}/login`)
  window.location.href = `${authUrl}/logout?logout_uri=${redirectUrl}&client_id=${process.env.USER_POOL_CLIENT_ID}`
}
