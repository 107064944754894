import { useApolloClient, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React, { useContext, useState } from 'react'
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import Modal from '@@components/modal'
import { StudyContext, useStudyPaths } from '@@pages/study'

import { GET_STUDY_PARTICIPANTS_QUERY, START_DATA_EXPORT } from './_queries.js'
import EditParticipantPage from './edit.jsx'
import NewParticipantPage from './new.jsx'
import ShowParticipantPage from './show.jsx'

function DataExportModal({ onClose, email, waiting, error }) {
  if (waiting) {
    return (
      <Modal>
        <div className="align-self-center card shadow" style={{ width: '640px', maxWidth: '90%' }}>
          <div className="card-header fw-bold fs-5 d-flex">Please wait...</div>
          <div className="card-body text-center fs-5">
            <div className="spinner-border" />
          </div>
        </div>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal>
        <div className="align-self-center card shadow" style={{ width: '640px', maxWidth: '90%' }}>
          <div className="card-header fw-bold fs-5 d-flex">
            An error ocurred.
            <div className="ms-auto">
              <button type="button" onClick={onClose} className="btn btn-close" />
            </div>
          </div>
          <div className="card-body fs-5">
            <p className="text-danger">{error}</p>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal>
      <div className="align-self-center card shadow" style={{ width: '640px', maxWidth: '90%' }}>
        <div className="card-header fw-bold fs-5 d-flex">
          Data export request received.
          <div className="ms-auto">
            <button type="button" onClick={onClose} className="btn btn-close" />
          </div>
        </div>
        <div className="card-body fs-5">
          <p className="mb-3">
            Your data export request has been received and is currently being processed. It may take several minutes to
            complete.
          </p>
          <p>
            We will email you at <span className="border-bottom">{email}</span> when it is ready.
          </p>
        </div>
      </div>
    </Modal>
  )
}

function ParticipantsList() {
  const apollo = useApolloClient()
  const study = useContext(StudyContext)
  const { url } = useRouteMatch()
  const { studyDesignPath } = useStudyPaths()
  const [selectedParticipants, setSelectedParticipants] = useState(new Set())
  const [exportModal, setExportModal] = useState()

  const { loading, error, data } = useQuery(GET_STUDY_PARTICIPANTS_QUERY, {
    variables: {
      input: {
        id: study.id,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return <div className="spinner-border" />
  }

  const { participants } = data.getStudy.study

  const toggleClick = (id) => {
    const newSet = new Set([...selectedParticipants])
    if (newSet.has(id)) {
      newSet.delete(id)
    } else {
      newSet.add(id)
    }
    setSelectedParticipants(newSet)
  }

  const toggleAllClick = () => {
    if (selectedParticipants.size === 0) {
      setSelectedParticipants(new Set(participants.map((p) => p.id)))
    } else {
      setSelectedParticipants(new Set())
    }
  }

  const ToggleAll = () => {
    if (selectedParticipants.size === 0) {
      return <i className="bi bi-square me-2" />
    }
    if (selectedParticipants.size === participants.length) {
      return <i className="bi bi-check-square me-2" />
    }
    return <i className="bi bi-dash-square me-2" />
  }

  const exportClick = async () => {
    if (selectedParticipants.size === 0) return
    setExportModal({ waiting: true })
    try {
      const { data } = await apollo.mutate({
        mutation: START_DATA_EXPORT,
        variables: {
          input: {
            studyId: study.id,
            participants: [...selectedParticipants],
          },
        },
      })
      setExportModal(data.startParticipantExport)
      setSelectedParticipants(new Set())
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      setExportModal({ error: err.message })
    }
  }

  return (
    <>
      {exportModal && <DataExportModal {...exportModal} onClose={() => setExportModal(null)} />}
      <div className="text-end">
        <button
          type="button"
          onClick={exportClick}
          disabled={selectedParticipants.size === 0}
          className={
            'btn fs-5 fw-light me-3 ' + (selectedParticipants.size === 0 ? 'btn-outline-primary' : 'btn-primary')
          }
        >
          Export Selected
        </button>
        <Link to={url + '/new'} className="btn btn-success fs-5 fw-light">
          <i className="bi bi-person-plus-fill me-3" />
          Add Participant
        </Link>
      </div>
      <div className="m-4 mt-0">
        <table className="table table-hover align-middle">
          <thead>
            <tr>
              <th role="button" onClick={toggleAllClick} scope="col">
                <ToggleAll />
              </th>
              <th scope="col"></th>
              <th scope="col" className="text-nowrap">
                Participant ID
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {participants.map(({ id, first_name, last_name, externalId } /* eslint camelcase: "off" */) => (
              <tr key={id} className={selectedParticipants.has(id) ? 'table-primary' : ''}>
                <td role="button" onClick={() => toggleClick(id)} className="text-nowrap w-0">
                  <i className={'bi me-2 ' + (selectedParticipants.has(id) ? 'bi-check-square' : 'bi-square')} />
                </td>
                <td className="text-nowrap w-0">
                  <i className="fs-4 m-0 me-2 bi bi-person-circle" />
                </td>
                <td className="text-nowrap">
                  <Link to={url + '/' + id}>{externalId}</Link>
                </td>
                <td className="text-capitalize w-100" style={{ fontWeight: 600 }}>
                  {first_name} {last_name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {participants.length === 0 && (
          <div className="alert alert-light">
            Use the button above to begin adding participants to your study. You can add participants at any time, but
            you will not be able to begin testing until you "lock" your <Link to={studyDesignPath}>study design</Link>.
          </div>
        )}
      </div>
    </>
  )
}

function MessageBox() {
  const { pathname, state } = useLocation()
  const history = useHistory()
  const message = state && state.message

  if (!message) return null

  const hideMessage = () => {
    history.replace(pathname, {})
  }

  return (
    <div className="position-fixed top-0 start-50 translate-middle-x p-3">
      <div className="toast align-items-center show" role="alert">
        <div className="d-flex">
          <div className="toast-body">{message}</div>
          <button
            type="button"
            className="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            onClick={hideMessage}
          ></button>
        </div>
      </div>
    </div>
  )
}

export default function ParticipantsPage() {
  const { url } = useRouteMatch()
  // TODO: Do "page not found" errors consistently

  return (
    <>
      <Switch>
        <Route exact path={url}>
          <ParticipantsList />
        </Route>
        <Route exact path={url + '/new'}>
          <NewParticipantPage />
        </Route>
        <Route exact path={url + '/:id'}>
          <ShowParticipantPage />
        </Route>
        <Route exact path={url + '/:id/edit'}>
          <EditParticipantPage />
        </Route>
        <Route>
          <div className="text-center fs-4 text-muted">Page Not Found</div>
        </Route>
      </Switch>
      <MessageBox />
    </>
  )
}
