import 'codemirror/mode/javascript/javascript'

import CodeMirror from 'codemirror'
import React, { useEffect, useRef, useState } from 'react'

export default React.forwardRef(({ json, onHasEdits, waiting }, fref) => {
  /* eslint react/display-name: "off" */
  const containerRef = useRef()
  const editorRef = useRef()
  const initialJsonRef = useRef(json)
  const [cursorInfo, setCursorInfo] = useState({ line: 0, ch: 0, pos: 0 })
  const [parseError, setParseError] = useState(null)

  const format = (j) => JSON.stringify(JSON.parse(j), null, 2)

  useEffect(() => {
    editorRef.current = new CodeMirror(containerRef.current, {
      value: format(json),
      mode: {
        name: 'javascript',
        json: true,
      },
      indentUnit: 2,
      indentWithTabs: false,
      tabSize: 2,
      smartIndent: true,
      lineNumbers: true,
      theme: 'custom',
    })
    editorRef.current.on('cursorActivity', (cm) => {
      const { line, ch } = cm.getCursor()
      const pos = cm.getRange({ line: 0, ch: 0 }, { line, ch }).length
      setCursorInfo({ line, ch, pos })
    })
    editorRef.current.on('change', () => {
      onHasEdits && onHasEdits(true)
    })
    fref.current = {
      validate: () => {
        setParseError(null)
        const doc = editorRef.current.getDoc()
        let parsed
        try {
          parsed = JSON.parse(doc.getValue())
        } catch (err) {
          console.warn(err)
          setParseError(err.message)
          return null
        }
        if (typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)) {
          return JSON.stringify(parsed)
        }
        setParseError('JSON object required')
      },
      update: (j) => {
        setParseError(false)
        const doc = editorRef.current.getDoc()
        doc.setValue(format(j))
        onHasEdits && onHasEdits(j !== initialJsonRef.current)
      },
      reset: () => {
        setParseError(false)
        const doc = editorRef.current.getDoc()
        doc.setValue(format(initialJsonRef.current))
        onHasEdits && onHasEdits(false)
      },
      saved: (j) => {
        initialJsonRef.current = j
        onHasEdits && onHasEdits(false)
      },
    }
  }, [])

  return (
    <>
      <div ref={containerRef} />
      <div className="text-end fs-7 font-monospace">
        Ln {cursorInfo.line}, Col {cursorInfo.ch}, Pos {cursorInfo.pos}
      </div>
      {parseError && <div className="text-danger mt-1">{parseError}</div>}
    </>
  )
})
