import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import Modal from '@@components/modal'

import { CREATE_REVISION_MUTATION } from './_queries'

export default function CreateRevisionModal({ studyRevisionId, onDone }) {
  const [waiting, setWaiting] = useState(false)
  const client = useApolloClient()

  const onConfirm = async () => {
    setWaiting(true)

    const { data } = await client.mutate({
      mutation: CREATE_REVISION_MUTATION,
      variables: {
        input: {
          studyRevisionId,
        },
      },
    })

    onDone(data.createEditableRevision.study.editRevision.id)
  }

  if (waiting) {
    return (
      <Modal>
        <div className="align-self-center card shadow">
          <div className="card-body">
            <div className="spinner-border" />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal onClose={() => onDone()}>
      <div className="align-self-center card shadow">
        <div className="card-body">
          <p className="fs-5 my-2">This will make a copy of the current revision that you can edit.</p>
          <p className="fs-5 my-2">Are you sure you want to continue?</p>
        </div>
        <div className="card-footer d-flex">
          <button id="create_revision_confirm" onClick={onConfirm} type="button" className="btn btn-primary me-1">
            Yes
          </button>
          <button type="button" onClick={() => onDone()} className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
