import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'

import GearImg from '@@images/gear.png'

import { Breadcrumb } from './_breadcrumb'
import StudyLayout from './_layout.jsx'
import { GET_STUDY_QUERY } from './_queries.js'
import AssessmentsPage from './assessments'
import StudyFiles from './files'
import ParticipantsPage from './participants'
import StudyProfile from './profile.jsx'
import VisitsPage from './visits'

export const StudyContext = React.createContext()
const StudyPathContext = React.createContext()

export function useStudyPaths() {
  const rootPath = useContext(StudyPathContext)
  return {
    rootPath,
    studyProfilePath: rootPath + '/profile',
    studyFilesPath: rootPath + '/files',
    assessmentsPath: rootPath + '/assessments',
    studyDesignPath: rootPath + '/revisions',
    participantsPath: rootPath + '/participants',
  }
}

function StudyRoutes() {
  const { pathname, state } = useLocation()
  const { rootPath, studyProfilePath, studyFilesPath, assessmentsPath, studyDesignPath, participantsPath } =
    useStudyPaths()

  return (
    <Switch>
      {pathname.endsWith('/') && (
        <Redirect strict from={pathname} to={{ pathname: pathname.replace(/\/+$/g, ''), state }} />
      )}
      <Route exact path={rootPath}>
        <Breadcrumb value="Dashboard" path={rootPath} />
        <div className="d-flex h-100 w-100 align-items-center justify-content-center">
          <img src={GearImg} height="25%" style={{ marginTop: '-3rem', opacity: '10%' }} />
        </div>
      </Route>
      <Route path={studyProfilePath}>
        <Breadcrumb value="Settings" />
        <Breadcrumb value="Study Profile" path={studyProfilePath} />
        <StudyProfile />
      </Route>
      <Route path={studyFilesPath}>
        <Breadcrumb value="Study Files" path={studyFilesPath} />
        <StudyFiles />
      </Route>
      <Route path={assessmentsPath}>
        <Breadcrumb value="Assessments" />
        <AssessmentsPage />
      </Route>
      <Route path={studyDesignPath}>
        <Breadcrumb value="Study Design" path={studyDesignPath} />
        <VisitsPage />
      </Route>
      <Route path={participantsPath}>
        <Breadcrumb value="Participants" path={participantsPath} />
        <ParticipantsPage />
      </Route>
      <Route>
        <Breadcrumb value="Page Not Found" />
        Invalid route.
      </Route>
    </Switch>
  )
}

export default function StudyPage() {
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_STUDY_QUERY, {
    variables: {
      input: {
        id,
      },
    },
  })
  const { url } = useRouteMatch()

  if (error) {
    throw error
  }

  if (loading) {
    return (
      <StudyPathContext.Provider value={url}>
        <StudyLayout>
          <div className="spinner-border" />
        </StudyLayout>
      </StudyPathContext.Provider>
    )
  }

  return (
    <StudyContext.Provider value={data.getStudy.study}>
      <StudyPathContext.Provider value={url}>
        <StudyLayout>
          <StudyRoutes />
        </StudyLayout>
      </StudyPathContext.Provider>
    </StudyContext.Provider>
  )
}
