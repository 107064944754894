import React from 'react'

export default function NotFoundErrorPage() {
  return (
    <div className="container-fluid h-100 bg-light">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 text-center">
          <h1 className="display-1 text-muted">
            <i className="bi bi-x-octagon" /> Page Not Found
          </h1>
          <p className="lead text-muted">
            The page you are looking for does not exist (or you may not be allowed to access it).
          </p>
        </div>
      </div>
    </div>
  )
}
