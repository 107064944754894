import * as Sentry from '@sentry/react'
import React, { useContext, useEffect, useRef, useState } from 'react'

const HEARTBEAT_INTERVAL = 5 * 60 * 1000 // 5 minutes
const REPLY_TIMEOUT = 30 * 1000 // 30 seconds

const WebsocketContext = React.createContext()

export class WebsocketRequestTimeoutError extends Error {
  constructor(requestId) {
    super(`Request timed out [${requestId}]`)
    this.requestId = requestId
  }
}

function generateRequestId(seq) {
  const uarr = new Uint8Array(8)
  const ia = Int32Array.from([seq])
  const fa = Float32Array.from([Math.random()])
  uarr.set(new Uint8Array(ia.buffer), 0)
  uarr.set(new Uint8Array(fa.buffer), 4)
  const str = String.fromCharCode.apply(null, uarr)
  return btoa(str)
}

// Initialize with an "open" websocket only
function WebsocketClient(client) {
  let sequenceNum = 0
  const replyHandlers = new Map()

  this.sendMessage = ({ type, data, onReply, confirm }) => {
    const msg = {
      action: 'control_message',
      type,
      data,
      confirm,
      requestId: generateRequestId(++sequenceNum),
    }

    // Clean up and prevent hanging
    const timer = setTimeout(() => {
      timeoutReplyHandler(msg.requestId)
    }, REPLY_TIMEOUT)

    const promise = new Promise((resolve) => {
      replyHandlers.set(msg.requestId, { onReply, resolve, timer })
    })

    client.send(JSON.stringify(msg))
    console.debug('Sent message:', JSON.stringify(msg))
    return promise
  }

  const messageHandlers = new Set()
  const disconnectHandlers = new Set()

  this.addMessageHandler = (handler) => {
    messageHandlers.add(handler)
  }

  this.removeMessageHandler = (handler) => {
    messageHandlers.delete(handler)
  }

  this.addDisconnectHandler = (handler) => {
    disconnectHandlers.add(handler)
  }

  this.removeDisconnectHandler = (handler) => {
    disconnectHandlers.delete(handler)
  }

  const callMessageHandlers = (messageData) => {
    const reply = ({ type, data }) => {
      const msg = {
        action: 'control_message',
        type,
        data,
        requestId: messageData.requestId,
      }
      client.send(JSON.stringify(msg))
      console.debug('Sent reply message:', JSON.stringify(msg))
    }

    const handlers = Array.from(messageHandlers)
    for (const handler of handlers) {
      handler({ ...messageData, reply })
    }
  }

  // Should only be called if the connection will not be retried - that decision is made external to this class
  this.callDisconnectHandlers = () => {
    const handlers = Array.from(disconnectHandlers)
    for (const handler of handlers) {
      handler()
    }
  }

  const timeoutReplyHandler = (requestId) => {
    if (replyHandlers.has(requestId)) {
      const { onReply, resolve } = replyHandlers.get(requestId)
      replyHandlers.delete(requestId)
      const error = new WebsocketRequestTimeoutError(requestId)
      if (onReply) {
        onReply({ error })
      }
      if (resolve) {
        resolve({ error })
      }
    }
  }

  const processControlMessage = (data) => {
    if (replyHandlers.has(data.requestId)) {
      // By default, request replies are not forwarded to message handlers
      const { onReply, resolve, timer } = replyHandlers.get(data.requestId)
      replyHandlers.delete(data.requestId)
      clearTimeout(timer)
      if (onReply) {
        onReply(data, callMessageHandlers)
      }
      if (resolve) {
        resolve(data)
      }
    } else {
      // Original messages (not replies) trigger message handlers
      callMessageHandlers(data)
    }
  }

  const onWebsocketMessage = (event) => {
    const data = JSON.parse(event.data)
    console.debug('Received message:', event.data)
    switch (data.action) {
      case 'control_message':
        processControlMessage(data)
        break

      default:
        throw new Error('Unknown message data type: ' + data.action)
    }
  }

  const onWebsocketClose = (event) => {
    client.removeEventListener('message', onWebsocketMessage)
    clearInterval(heartbeatIntervalId)
    console.log('Cleaning up WebsocketClient')
  }

  client.addEventListener('message', onWebsocketMessage)
  client.addEventListener('close', onWebsocketClose, { once: true })

  const heartbeatIntervalId = setInterval(() => {
    this.sendMessage({ type: 'Ping' })
  }, HEARTBEAT_INTERVAL)
}

export function WebsocketProvider({ token, role, participantId, children }) {
  const [error, setError] = useState()
  const [client, setClient] = useState()
  const clientRef = useRef() // client instance
  const wsRef = useRef() // websocket instance

  // To make `client` object available within the closures below
  clientRef.current = client

  const createWebsocket = () => {
    const url = `${process.env.WEBSOCKET_URI}?participantId=${participantId}&token=${token}`
    const ws = new WebSocket(url, role)
    wsRef.current = ws

    ws.onerror = (event) => {
      // Only handle errors if the component is mounted
      if (clientRef.current) {
        console.log(event)
        setError('There was a problem establishing a connection to the server.')
      }
    }

    ws.onopen = (event) => {
      console.log('Websocket connection established.')
      setClient(new WebsocketClient(ws))
    }

    ws.onclose = (event) => {
      // this means the component is being unmounted
      if (!clientRef.current) {
        return
      }

      console.debug(event)

      // Connection closed by the server in an expected way
      if (event.code === 1000) {
        // Do not attempt to reconnect
        // Signal to listeners that this is happening
        clientRef.current.callDisconnectHandlers()
        return
      }

      // Disconnection was not expected (e.g. connection timeout)
      // Attempt to reconnect
      //! I don't think this is working for timeouts because the token is expired
      Sentry.captureException(new Error(`[${event.code}] Websocket closed unexpectedly: ${event.reason}`))
      console.warn('Websocket connection lost. Reconnecting...')
      setClient(null) // Puts useWebsocket back in "loading" state
      createWebsocket() // Once new socket opens, setClient refreshes provider with new client
    }
  }

  useEffect(() => {
    createWebsocket()
    return () => {
      clientRef.current = null // this signals that the component is being unmounted
      wsRef.current.close(4000, 'Connection closed by user')
    }
  }, [token, role, participantId])

  if (error) {
    return <div className="alert alert-danger m-4">{error}</div>
  }

  return <WebsocketContext.Provider value={client}>{children}</WebsocketContext.Provider>
}

// onDisconnect only called reconnect not be attempted
export function useWebsocket({ onMessage, onDisconnect } = {}) {
  const client = useContext(WebsocketContext)

  useEffect(() => {
    if (!client) return

    if (onMessage) {
      client.addMessageHandler(onMessage)
    }
    if (onDisconnect) {
      client.addDisconnectHandler(onDisconnect)
    }
    return () => {
      client.removeMessageHandler(onMessage)
      client.removeDisconnectHandler(onDisconnect)
    }
  }, [client, onMessage, onDisconnect])

  if (!client) {
    return {
      loading: true,
      sendMessage: async () => {
        console.warn('websocket not initialized')
        return {
          error: new Error('websocket not initialized'),
        }
      },
    }
  }
  return {
    loading: false,
    sendMessage: client.sendMessage,
  }
}
