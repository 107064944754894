import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Modal from '@@components/modal'

import { RevisionListContext } from '.'
import CreateRevisionModal from './_create-revision-modal'
import EditTitleModal from './_edit-title-modal'
import LockRevisionModal from './_lock-revision-modal'
import SetDefaultModal from './_set-default-modal'

function RevisionSelector({ selected }) {
  const revisions = useContext(RevisionListContext)
  const history = useHistory()

  const onSelect = (revisionId) => {
    history.push('./' + revisionId)
  }

  const selectedRevision = revisions.get(selected)

  // TODO: Use date instead of id as title alternative -or- add date after title in list
  const displayTitle = ({ id, title }) => title || 'version ' + id.split('-')[0]

  return (
    <div className="dropdown d-inline-block" id="study-revision-selector">
      <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">
        {displayTitle(selectedRevision)}
      </button>
      <ul className="dropdown-menu">
        {Array.from(revisions.values()).map(({ id, title, editable, isDefault }) => (
          <li key={id}>
            <button
              type="button"
              onClick={() => onSelect(id)}
              className={'dropdown-item ' + (id === selected ? 'active' : '')}
              id={`select-version-${id}-button`}
            >
              <span className="d-flex flex-row">
                <span className="d-block flex-grow-1">{displayTitle({ id, title })}</span>
                {editable && (
                  <span className="d-block">
                    <i className="bi bi-pencil ms-2 fs-7" />
                  </span>
                )}
                {isDefault && (
                  <span className="d-block">
                    <i className="bi bi-patch-check-fill ms-2 fs-7" />
                  </span>
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function RevisionActionsRow({ studyRevision }) {
  const history = useHistory()
  const revisions = useContext(RevisionListContext)
  const [alertMessage, setAlertMessage] = useState(null)
  const [locking, setLocking] = useState(false)
  const [creating, setCreating] = useState(null)
  const [settingDefault, setSettingDefault] = useState(null)
  const [editTitle, setEditTitle] = useState(false)

  const { editable, isDefault } = revisions.get(studyRevision.id)
  const hasEditable = Array.from(revisions.values()).some((rev) => rev.editable)

  const lockRevision = () => {
    // Make sure there are no empty visits
    if (studyRevision.visits.length === 0) {
      setAlertMessage('You cannot save because there are no visits.')
    } else if (studyRevision.visits.some((v) => v.events.length === 0)) {
      setAlertMessage('You cannot save because there are empty visits.')
    } else {
      setLocking(true)
    }
  }

  const afterCreate = (studyRevisionId) => {
    if (studyRevisionId) {
      history.push('./' + studyRevisionId)
    } else {
      setCreating(null)
    }
  }

  return (
    <>
      <div className="d-flex flex-row pb-2 align-items-center">
        <div>
          <RevisionSelector selected={studyRevision.id} />
          <button
            type="button"
            onClick={() => setEditTitle(true)}
            title="Change revision name"
            className="btn has-tooltip visit-button fs-5 py-1 ms-2"
            id="edit_title_button"
          >
            <i className="bi bi-pencil-fill" />
          </button>
          {!editable && !hasEditable && (
            <button
              type="button"
              onClick={() => setCreating(studyRevision.id)}
              data-bs-html="true"
              title="Copy to new<br/>editable revision"
              className="btn has-tooltip visit-button fs-5 py-1 ms-1"
              id="create_revision_button"
            >
              <i className="bi bi-files" />
            </button>
          )}
        </div>
        {editable ? (
          <div className="ms-auto">
            <button
              type="button"
              onClick={lockRevision}
              title="Lock and set default"
              className="btn has-tooltip visit-button fs-5 py-1 ms-2"
              id="lock_revision_button"
            >
              <i className="bi bi-send" />
            </button>
            <span className="badge bg-success ms-2">Editing</span>
          </div>
        ) : (
          <div className="ms-auto">
            {!isDefault && (
              <button
                type="button"
                onClick={() => setSettingDefault(studyRevision.id)}
                title="Set as default"
                className="btn has-tooltip visit-button fs-5 py-1 ms-1"
                id="set_default_button"
              >
                <i className="bi bi-patch-plus" />
              </button>
            )}
            {isDefault && (
              <span id="default_revision_indicator" className="badge bg-primary ms-2">
                Default
              </span>
            )}
            <span id="revision_locked" className="badge bg-secondary ms-2">
              Locked
            </span>
          </div>
        )}
      </div>
      {alertMessage && (
        <Modal onClose={() => setAlertMessage(null)}>
          <div className="align-self-center card shadow">
            <div className="card-body">
              <p className="my-2 fs-5">{alertMessage}</p>
            </div>
            <div className="card-footer d-flex">
              <button
                id="delete_event_confirm"
                onClick={() => setAlertMessage(null)}
                type="button"
                className="btn btn-primary me-1"
              >
                Dismiss
              </button>
            </div>
          </div>
        </Modal>
      )}
      {locking && <LockRevisionModal onDone={() => setLocking(false)} />}
      {creating && <CreateRevisionModal studyRevisionId={creating} onDone={afterCreate} />}
      {settingDefault && <SetDefaultModal studyRevisionId={settingDefault} onDone={() => setSettingDefault(null)} />}
      {editTitle && <EditTitleModal studyRevision={studyRevision} onDone={() => setEditTitle(null)} />}
    </>
  )
}
