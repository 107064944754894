import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Link, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import FormattedDescription from '@@components/formatted-description'
import Modal from '@@components/modal'
import { StudyContext } from '@@pages/study'

import { ADD_STUDY_ASSESSMENT_MUTATION, GET_ASSESSMENT_QUERY, GET_STUDY_ASSESSMENT_QUERY } from './_queries.js'
import TaskVersionWidget from './task-version-widget'

function SaveModal({ initialValue, onCancel, onSave }) {
  const [label, setLabel] = useState(initialValue)
  const onSaveClick = () => {
    if (!label.match(/^ *$/)) {
      onSave(label)
    } else {
      // TODO: Error message
    }
  }
  return (
    <Modal onClose={onCancel}>
      <div className="align-self-center card shadow col-6">
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="label" className="form-label">
              Assessment name:
            </label>
            <input
              autoFocus
              type="text"
              className="form-control"
              id="label"
              value={label}
              onChange={(ev) => setLabel(ev.target.value)}
            />
            <div className="form-text">(You can change this later on)</div>
          </div>
          <div>
            <button id="save_study_assessment" type="button" onClick={onSaveClick} className="btn btn-primary me-1">
              Save
            </button>
            <button type="button" onClick={onCancel} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default function TaskPage() {
  const study = useContext(StudyContext)
  const [saveConfig, setSaveConfig] = useState()
  const [waitForSave, setWaitForSave] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const {
    params: { task },
  } = useRouteMatch()
  const client = useApolloClient()
  const { loading, error, data } = useQuery(GET_ASSESSMENT_QUERY, {
    variables: {
      input: {
        id: task,
      },
    },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return <div className="spinner-border" />
  }

  const { name, title, description, defaultVersion, versions } = data.getAssessment.assessment
  const search = new URLSearchParams(location.search)
  const currentVersion = search.get('v')
  const versionObj = versions.find((v) => v.version === currentVersion)
  if (!versionObj) {
    return <Redirect to={{ ...location, search: `?v=${defaultVersion.version}` }} />
  }

  const onSave = (config) => {
    if (config) {
      setSaveConfig(config)
    }
  }

  const onConfirmSave = async (label) => {
    // Save and redirect
    setWaitForSave(true)
    const { data } = await client.mutate({
      mutation: ADD_STUDY_ASSESSMENT_MUTATION,
      variables: {
        input: {
          studyId: study.id,
          assessmentVersionId: versionObj.id,
          label,
          config: saveConfig,
        },
      },
    })
    client.cache.modify({
      id: client.cache.identify(study),
      fields: {
        assessmentBank(cachedList = [], { toReference }) {
          return [...cachedList, toReference(data.addStudyAssessment.studyAssessment, true)]
        },
      },
    })
    client.writeQuery({
      query: GET_STUDY_ASSESSMENT_QUERY,
      variables: {
        input: {
          id: data.addStudyAssessment.studyAssessment.id,
        },
      },
      data: {
        id: data.addStudyAssessment.studyAssessment.id,
        studyAssessment: data.addStudyAssessment.studyAssessment,
      },
    })
    // TODO: Catch errors
    history.push({ pathname: `../${data.addStudyAssessment.studyAssessment.id}` })
  }

  return (
    <div className="container-fluid p-0">
      <div className="row gx-5">
        <div className="col">
          <Link to="../library" className="d-block fs-7 text-decoration-none">
            <i className="bi bi-arrow-left-short" />
            Back
          </Link>
          <div className="mb-4 d-flex align-items-center">
            <h4 className="display-6 my-0">{title}</h4>
            <div className="dropdown ms-2">
              <button
                type="button"
                className="btn btn-sm fs-5 py-0 fw-light btn-outline-secondary dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                v{currentVersion}
              </button>
              <ul className="dropdown-menu">
                {versions.map(({ version }) => (
                  <li key={version}>
                    <button
                      type="button"
                      onClick={() => history.replace({ ...location, search: `?v=${version}` })}
                      className={'dropdown-item ' + (version === currentVersion ? 'active' : '')}
                    >
                      v{version} {version === defaultVersion.version && <small>(default)</small>}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <FormattedDescription>{description}</FormattedDescription>
        </div>
        <div className="col-auto">
          <TaskVersionWidget name={name} version={currentVersion} onSave={(json) => onSave(json)} />
        </div>
        {waitForSave && (
          <Modal>
            <div className="align-self-center card shadow">
              <div className="card-body">
                <div className="spinner-border" />
              </div>
            </div>
          </Modal>
        )}
        {!waitForSave && saveConfig && (
          <SaveModal initialValue={title} onCancel={() => setSaveConfig(null)} onSave={onConfirmSave} />
        )}
      </div>
    </div>
  )
}
