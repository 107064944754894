import { useApolloClient, useQuery } from '@apollo/client'
import update from 'immutability-helper'
import React, { useState } from 'react'

import Waitable from '@@components/waitable'

import Org from './_org.jsx'
import { CREATE_ORG_QUERY, GET_ORGS_QUERY, GET_USER_QUERY } from './_queries.js'
import SingleTextEdit from './_single_text_edit.jsx'

function NoOrgs() {
  const [showForm, setShowForm] = useState(false)
  const [saving, setSaving] = useState(false)
  const client = useApolloClient()
  const { loading, error, data } = useQuery(GET_USER_QUERY)

  if (loading) return <div className="spinner-border" />
  if (error) throw error

  const { canCreateOrg } = data.me

  const newClick = (ev) => {
    ev.preventDefault()
    setShowForm(true)
  }

  const createOrg = (name) => {
    if (!name || name.match(/^ *$/)) {
      return setShowForm(false)
    }

    setSaving(true)
    client.mutate({
      mutation: CREATE_ORG_QUERY,
      variables: { input: { name } },
      update: (cache, result) => {
        const { getOrgs } = cache.readQuery({ query: GET_ORGS_QUERY })
        cache.writeQuery({
          query: GET_ORGS_QUERY,
          data: {
            getOrgs: update(getOrgs, { $set: { orgs: [...getOrgs.orgs, result.data.createOrg.org] } }),
          },
        })
      },
    })
  }

  if (!canCreateOrg) {
    return (
      <p className="fst-italic text-secondary text-center py-4 my-4">
        You do not have any orgs or studies assigned to you.
      </p>
    )
  }

  if (!showForm) {
    return (
      <div className="mt-4">
        <button type="button" className="fs-3 btn btn-link text-decoration-none text-black-50" onClick={newClick}>
          <i className="bi bi-plus" /> Create Org
        </button>
      </div>
    )
  }

  return (
    <Waitable waiting={saving}>
      <div className="mt-4">
        <SingleTextEdit sizeClass="fs-3" onCancel={() => setShowForm(false)} onSave={createOrg}>
          Enter org name
        </SingleTextEdit>
      </div>
    </Waitable>
  )
}

export default function OrgsIndex() {
  const { loading, error, data } = useQuery(GET_ORGS_QUERY)

  const OrgsList = ({ orgs }) => (
    <React.Fragment>
      {orgs.map((org) => (
        <Org key={org.id} org={org} />
      ))}
      {orgs.length === 0 && <NoOrgs />}
    </React.Fragment>
  )

  return (
    <div className="container-md pt-4">
      <div className="row">
        <div className="col-auto pt-4">
          <p className="text-uppercase text-muted mb-2 fs-7">Help &amp; Support</p>
          <p className="fs-7">
            <a href="mailto:support@bruinhealth.com" target="_blank" rel="noreferrer">
              support@bruinhealth.com
            </a>
          </p>
        </div>
        <div className="offset-1 col">
          {error && (
            <div className="alert alert-danger text-center">{error.message || JSON.stringify(error.networkError)}</div>
          )}
          {loading && <div className="spinner-border" />}
          {data && <OrgsList orgs={data.getOrgs.orgs} />}
        </div>
      </div>
    </div>
  )
}
