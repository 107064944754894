import { useApolloClient } from '@apollo/client'
import React, { useContext, useState } from 'react'

import { StudyContext } from '@@pages/study'

import { UPDATE_STUDY_QUERY } from './_queries.js'

export default function StudyProfile() {
  const study = useContext(StudyContext)
  const [changes, setChanges] = useState(false)
  const [name, setName] = useState(study.name)
  const [prefix, setPrefix] = useState(study.participantIdPrefix)
  const [error, setError] = useState(null)
  const [waitForSave, setWaitForSave] = useState(null)
  const client = useApolloClient()
  const disabledClass = changes ? '' : 'disabled'

  const updateName = (name) => {
    setName(name)
    setError(null)
    if (name !== study.name) {
      setChanges(true)
    }
  }

  const updatePrefix = (prefix) => {
    setPrefix(prefix)
    setError(null)
    if (prefix !== study.participantIdPrefix) {
      setChanges(true)
    }
  }

  const onCancel = () => {
    setName(study.name)
    setPrefix(study.participantIdPrefix)
    setError(null)
    setChanges(false)
  }

  const onSave = () => {
    setError(null)
    if (!name || name.match(/^ *$/)) {
      return setError('Invalid study name.')
    }
    if (!prefix || prefix.match(/^ *$/)) {
      return setError('Invalid participant ID prefix.')
    }
    setWaitForSave(true)

    client
      .mutate({
        mutation: UPDATE_STUDY_QUERY,
        variables: {
          input: {
            id: study.id,
            name,
            participantIdPrefix: prefix,
          },
        },
      })
      .then(() => setChanges(false))
      .finally(() => setWaitForSave(false))
  }

  return (
    <div className="form" style={{ maxWidth: '600px', paddingLeft: '12px' }}>
      <div className="mb-4">
        <label htmlFor="name" className="form-label">
          Study name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={name}
          onChange={(ev) => updateName(ev.target.value)}
        />
      </div>
      <div className="mb-4 col-sm-4">
        <label htmlFor="prefix" className="form-label">
          Participant ID Prefix
        </label>
        <input
          type="text"
          className="form-control"
          id="prefix"
          value={prefix}
          onChange={(ev) => updatePrefix(ev.target.value)}
        />
      </div>
      <hr />
      <div className="text-center">
        {waitForSave && <div className="spinner-border" />}
        {!waitForSave && (
          <>
            <button type="button" onClick={onSave} id="save-button" className={'btn btn-primary me-1 ' + disabledClass}>
              Save Changes
            </button>
            <button type="button" onClick={onCancel} id="cancel-button" className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </>
        )}
      </div>
      {error && <div className="alert alert-danger text-center mt-4">{error}</div>}
    </div>
  )
}
