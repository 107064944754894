import { gql } from '@apollo/client'

const PARTICIPANT_FIELDS = gql`
  fragment ParticipantFields on Participant {
    id
    first_name
    last_name
    externalId
  }
`

const SESSION_FIELDS = gql`
  fragment SessionFields on Session {
    id
    state
    visit {
      id
      sort_key
    }
  }
`

export const GET_STUDY_PARTICIPANTS_QUERY = gql`
  ${PARTICIPANT_FIELDS}
  query GetStudy($input: GetStudyInput!) {
    getStudy(input: $input) {
      id
      study {
        id
        participants {
          ...ParticipantFields
        }
      }
    }
  }
`

export const GET_NEXT_PARTICIPANT_ID_QUERY = gql`
  query GetStudyForParticipantId($input: GetStudyInput!) {
    getStudy(input: $input) {
      study {
        id
        nextParticipantExternalId
      }
    }
  }
`

export const GET_PARTICIPANT_QUERY = gql`
  ${PARTICIPANT_FIELDS}
  ${SESSION_FIELDS}
  query GetParticipant($input: GetParticipantInput!) {
    getParticipant(input: $input) {
      id
      participant {
        ...ParticipantFields
        slug
        isOnline
        studyRevision {
          id
        }
        sessions {
          ...SessionFields
        }
        currentSession {
          id
        }
        folders {
          id
          type
          name
        }
      }
    }
  }
`

export const CREATE_PARTICIPANT_MUTATION = gql`
  ${PARTICIPANT_FIELDS}
  mutation CreateParticipant($input: CreateParticipantInput!) {
    createParticipant(input: $input) {
      participant {
        ...ParticipantFields
      }
      study {
        id
        participants {
          id
        }
      }
    }
  }
`

export const UPDATE_PARTICIPANT_MUTATION = gql`
  ${PARTICIPANT_FIELDS}
  mutation UpdateParticipant($input: UpdateParticipantInput!) {
    updateParticipant(input: $input) {
      participant {
        ...ParticipantFields
      }
    }
  }
`

export const GET_DEFAULT_REVISION_QUERY = gql`
  query GetStudy($input: GetStudyInput!) {
    getStudy(input: $input) {
      study {
        id
        defaultRevision {
          id
        }
      }
    }
  }
`

export const LOCK_PROTOCOL_MUTATION = gql`
  ${SESSION_FIELDS}
  mutation LockProtocol($input: LockParticipantStudyRevisionInput!) {
    lockParticipantStudyRevision(input: $input) {
      participant {
        id
        studyRevision {
          id
        }
        sessions {
          ...SessionFields
        }
        currentSession {
          id
        }
      }
    }
  }
`

export const START_SESSION_MUTATION = gql`
  mutation StartSession($input: StartSessionInput!) {
    startSession(input: $input) {
      participant {
        id
        currentSession {
          id
          state
        }
      }
    }
  }
`

export const START_DATA_EXPORT = gql`
  mutation StartParticipantExport($input: ParticipantExportInput!) {
    startParticipantExport(input: $input) {
      requestId
      email
    }
  }
`
