import { useMutation } from '@apollo/client'
import { TaskRunner } from '@bruinhealth/sdk/react'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'

import Modal from '@@components/modal'
import Toast from '@@components/toast'
import Waitable from '@@components/waitable'
import { useWebsocket } from '@@lib/websocket'

import { SessionContext } from '.'
import { CREATE_READER_TOKEN_MUTATION } from './_queries'
import LogView from './log-view'

function InterruptionModal() {
  return (
    <div
      className="position-absolute top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center"
      style={{ zIndex: 1030, backgroundColor: 'rgba(255,255,255,0.8)' }}
    >
      <div className="card mx-4 mb-4 shadow-sm" style={{ maxWidth: '480px' }}>
        <div className="card-header bg-danger text-white text-center">
          <i className="bi bi-exclamation-triangle me-2 fs-5" />
          Assessment interrupted.
        </div>
        <div className="card-body text-start bg-light">
          Data collection for the current assessment may have ended prematurely. This can happen when the participant
          refreshes the page or opens the session in a new browser tab.
        </div>
      </div>
    </div>
  )
}

function OfflineModal() {
  return (
    <div
      className="position-absolute top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center"
      style={{ zIndex: 1030, backgroundColor: 'rgba(255,255,255,0.8)' }}
    >
      <div className="card mx-4 mb-4 shadow-sm" style={{ maxWidth: '480px' }}>
        <div className="card-header bg-warning text-center">
          <i className="bi bi-exclamation-triangle me-2 fs-5" />
          Connection Lost.
        </div>
        <div className="card-body text-start bg-light">
          <p>
            The connection to the participant has been lost. This has many causes and does not mean that the task has
            ended prematurely.
          </p>
          Do not abort the task as data may continue to be collected from the participant.
        </div>
      </div>
    </div>
  )
}

// Check that the participant session is actually running
function InterruptionCheck() {
  const [interrupted, setInterrupted] = useState(false)
  const [offline, setOffline] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkForInterruption = async () => {
    setLoading(true)
    const { error, data } = await ws.sendMessage({ type: 'EventStatus' })
    setLoading(false)
    // TODO: Check that the eventId is correct and show a special message?
    if (error || !data) {
      setInterrupted(true)
    } else if (data.eventId && data.started) {
      setInterrupted(false)
    } else if (data.eventId) {
      // Loading in process, wait a second and try again
      setLoading(true)
      setTimeout(checkForInterruption, 1000)
    } else {
      setInterrupted(true)
    }
  }

  const ws = useWebsocket({
    onMessage: ({ type, data }) => {
      switch (type.toLowerCase()) {
        case 'participantconnection':
          if (!data.isOnline) {
            setOffline(true)
          } else {
            checkForInterruption()
            setOffline(false)
          }
      }
    },
  })

  useEffect(() => {
    if (!ws.loading) {
      checkForInterruption()
    }
  }, [ws.loading])

  if (loading) {
    return (
      <div
        className="position-absolute top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center"
        style={{ zIndex: 1030, backgroundColor: 'rgba(255,255,255,0.8)' }}
      >
        <div className="card mx-4 mb-4 shadow-sm">
          <div className="card-body text-center bg-light">
            <div className="spinner-border" />
          </div>
        </div>
      </div>
    )
  }

  if (interrupted) {
    return <InterruptionModal />
  }

  if (offline) {
    return <OfflineModal />
  }

  return null
}

function useCaptureInput() {
  const [enabled, setEnabled] = useState(false)
  const [lostFocus, setLostFocus] = useState(false)
  const ws = useWebsocket()

  useEffect(() => {
    if (enabled) {
      const handler = (ev) => {
        ev.preventDefault()
        if (!ev.repeat) {
          const { code, key } = ev
          ws.sendMessage({
            type: 'SendKey',
            data: { code, key },
          })
        }
      }
      addEventListener('keydown', handler, true)

      // Poll for window focus status
      const timer = setInterval(() => {
        setLostFocus(!document.hasFocus())
      }, 1000)

      return () => {
        removeEventListener('keydown', handler, true)
        clearInterval(timer)
      }
    }
  }, [enabled])

  return {
    enabled,
    toggle: () => setEnabled(!enabled),
    lostFocus,
  }
}

function CaptureInputToggle() {
  const { enabled, toggle, lostFocus } = useCaptureInput()
  const [blink, setBlink] = useState(false)
  const toggleRef = useRef()

  useEffect(() => {
    if (lostFocus) {
      // Blink the indicator red
      let _blink = blink
      const timer = setInterval(() => {
        _blink = !_blink
        setBlink(_blink)
      }, 200)

      // Show a tooltip as well
      const tip = new bootstrap.Tooltip(toggleRef.current, {
        placement: 'top',
        trigger: 'manual',
        delay: 0,
        html: true,
        title: 'Focus lost!<br/>Click inside the window!',
      })
      tip.show()

      return () => {
        clearInterval(timer)
        tip.dispose()
      }
    }
  }, [lostFocus])

  if (enabled) {
    let colorClasses = 'bg-info-light text-primary border-primary'
    if (lostFocus) {
      if (blink) {
        colorClasses = 'bg-danger text-light border-danger'
      } else {
        colorClasses = 'bg-danger-light text-danger border-danger'
      }
    }
    return (
      <div
        ref={toggleRef}
        onClick={toggle}
        id="capture_input_button"
        className={'border rounded-pill fs-7 px-3 py-1 ' + colorClasses}
        style={{ cursor: 'pointer' }}
      >
        Input capture on
        <i className="bi bi-toggle-on ms-1" />
      </div>
    )
  }
  return (
    <div
      ref={toggleRef}
      onClick={toggle}
      id="capture_input_button"
      className="border rounded-pill fs-7 px-3 py-1"
      style={{ cursor: 'pointer' }}
    >
      Input capture off
      <i className="bi bi-toggle-off ms-1" />
    </div>
  )
}

const hideTooltip = (el) => {
  const tip = bootstrap.Tooltip.getInstance(el) /* eslint no-undef: "off" */
  if (tip) {
    tip.dispose()
  }
}

function ConfirmActionModal({ action, onConfirm, onCancel }) {
  const [waiting, setWaiting] = useState(false)

  // Take no action if waiting is in progress
  const onClose = () => {
    if (!waiting) {
      onCancel()
    }
  }

  const yesClick = () => {
    setWaiting(true)
    onConfirm()
  }

  return (
    <Modal onClose={onClose}>
      <div className="align-self-center card shadow">
        <Waitable waiting={waiting}>
          <div className="card-body text-center">
            <h5>Are you sure you want to {action.toLowerCase()}?</h5>
          </div>
          <div className="card-footer d-flex">
            <button id="action_confirm" type="button" onClick={yesClick} className="btn btn-danger me-1">
              Yes
            </button>
            <button id="action_cancel" type="button" onClick={onClose} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </div>
        </Waitable>
      </div>
    </Modal>
  )
}

function TaskControlButton({ tooltip, icon, onConfirm }) {
  const buttonRef = useRef()
  const [confirming, setConfirming] = useState(false)

  const handleClick = (ev) => {
    hideTooltip(buttonRef.current)
    setConfirming(true)
  }

  const confirmAction = () => {
    onConfirm()
  }

  return (
    <>
      <Waitable small waiting={confirming}>
        <button
          onClick={handleClick}
          ref={buttonRef}
          title={tooltip}
          className="btn btn-link session-tab border-0 py-2 has-tooltip task-control-button"
        >
          <i className={'bi bi-' + icon} />
        </button>
      </Waitable>
      {confirming && (
        <ConfirmActionModal action={tooltip} onConfirm={confirmAction} onCancel={() => setConfirming(false)} />
      )}
    </>
  )
}

function TaskControl() {
  const menuToggleRef = useRef()
  const [showControls, setShowControls] = useState(false)
  const [error, setError] = useState()
  const ws = useWebsocket()

  const menuToggleClick = () => {
    hideTooltip(menuToggleRef.current)
    setShowControls(!showControls)
  }

  const takeAction = async (action) => {
    try {
      const { error } = await ws.sendMessage({
        type: 'TaskControl',
        data: { action },
      })
      if (error) {
        console.error(error)
        setError(error)
      }
    } catch (err) {
      setError(err)
      console.error(err)
    }
    setShowControls(false)
  }

  return (
    <>
      <button
        id="task_control_toggle"
        title="Toggle task controls"
        ref={menuToggleRef}
        onClick={menuToggleClick}
        className="btn btn-link session-tab border-0 has-tooltip"
      >
        <i className={'bi bi-caret-' + (showControls ? 'down' : 'up') + '-fill'} />
      </button>
      {error && (
        <Toast
          onClose={() => setError(null)}
          timeout={5000}
          className="text-white bg-danger border-0 fw-bold text-center"
        >
          An error occurred.
        </Toast>
      )}
      {showControls && (
        <div className="d-inline-block position-absolute bottom-100 end-0 bg-light border border-end-0 fs-5 mb-1">
          <TaskControlButton
            onConfirm={() => takeAction('restart')}
            tooltip="Restart the task"
            icon="skip-start-fill"
          />
          <TaskControlButton
            onConfirm={() => takeAction('back')}
            tooltip="Go to last block"
            icon="skip-backward-fill"
          />
          <TaskControlButton onConfirm={() => takeAction('retry')} tooltip="Restart this block" icon="reply-fill" />
          <TaskControlButton onConfirm={() => takeAction('skip')} tooltip="Skip forward" icon="skip-forward-fill" />
          <TaskControlButton onConfirm={() => takeAction('skip-all')} tooltip="End the task" icon="stop-fill" />
        </div>
      )}
    </>
  )
}

function SessionTabView({ authToken }) {
  const [activeTab, setActiveTab] = useState(0)
  const [dataLog, addToDataLog] = useReducer(
    ({ log, serials }, newItem) => {
      if (typeof newItem.timestamp === 'number') {
        newItem.timestamp = newItem.timestamp.toFixed(3)
      }
      serials.add(newItem.serial)
      return {
        log: [...log, newItem],
        serials,
      }
    },
    { log: [], serials: new Set() }
  )

  // Make sure both tabs are rendered so we don't lose their state
  const tabs = [
    ['Task View', <TaskRunner role="reader" authToken={authToken} onData={addToDataLog} />],
    ['Log View', <LogView dataLog={dataLog.log} />] /* eslint react/jsx-key: "off" */,
  ]

  return (
    <div className="d-flex flex-column h-100 position-relative">
      {tabs.map(([, content], i) => (
        <div key={i} className={'flex-grow-1 overflow-hidden position-relative ' + (activeTab === i ? '' : 'd-none')}>
          {content}
        </div>
      ))}
      <div className="session-tabs">
        <div className="d-flex session-tab-group position-relative">
          {tabs.map(([tabName], i) => (
            <button
              key={i}
              onClick={() => setActiveTab(i)}
              className={'btn btn-link session-tab ' + (activeTab === i ? 'active' : '')}
            >
              {tabName}
            </button>
          ))}
          <div className="flex-grow-1" />
          <div className="m-1">
            <CaptureInputToggle />
          </div>
          <TaskControl />
        </div>
      </div>
      <InterruptionCheck />
    </div>
  )
}

// Really a running SessionEvent
export default function RunningSession() {
  const { session } = useContext(SessionContext)
  const [createToken, { data, loading, error }] = useMutation(CREATE_READER_TOKEN_MUTATION)

  useEffect(() => {
    createToken({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          sessionEventId: session.currentEvent.id,
        },
      },
    })
  }, [session.currentEvent.id])

  if (error) throw error
  if (loading || !data) return <div className="spinner-border" />

  return <SessionTabView key={data.createReaderToken.authToken} authToken={data.createReaderToken.authToken} />
}
