import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Waitable from '@@components/waitable'

import { CREATE_STUDY_QUERY } from './_queries.js'

function NewStudy({ onSave, onCancel }) {
  const [name, setName] = useState('')

  const onKeyUp = (key) => {
    switch (key) {
      case 'Enter':
        onSave(name)
        break

      case 'Escape':
        setName('')
        onCancel()
        break
    }
  }

  return (
    <div className="study-tile-new mb-4">
      <div className="study-tile-content">
        <i className="bi bi-pencil-fill" />
      </div>
      <div className="study-tile-label">
        <input
          autoFocus
          type="text"
          placeholder="Enter study name"
          className="study-tile-input py-0"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          onBlur={() => onSave(name)}
          onKeyUp={({ key }) => onKeyUp(key)}
        />
      </div>
    </div>
  )
}

function AddStudy({ org }) {
  const [showForm, setShowForm] = useState(false)
  const [saving, setSaving] = useState(false)
  const client = useApolloClient()

  const createStudy = (name) => {
    if (!name || name.match(/^ *$/)) {
      return setShowForm(false)
    }

    setSaving(true)
    client.mutate({
      mutation: CREATE_STUDY_QUERY,
      variables: {
        input: {
          org_id: org.id,
          name,
        },
      },
      update: (cache, result) => {
        cache.modify({
          id: cache.identify(org),
          fields: {
            studies(cachedStudies, { toReference }) {
              return [...cachedStudies, toReference(result.data.createStudy.study, true)]
            },
          },
        })
      },
    })
    setShowForm(false)
  }

  if (showForm) {
    return <NewStudy onSave={createStudy} onCancel={() => setShowForm(false)} />
  }

  return (
    <Waitable waiting={saving}>
      <div className="study-tile-add">
        <div className="study-tile-content" onClick={() => setShowForm(true)}>
          <i className="bi bi-plus" />
        </div>
        <div className="study-tile-label">&nbsp;</div>
      </div>
    </Waitable>
  )
}

function StudyTile({ study }) {
  return (
    <Link className="study-tile mb-4" key={study.id} to={`/study/${study.id}`}>
      <div className="study-tile-content">
        {study.name[0].toUpperCase()}
        {(study.name[1] || '').toLowerCase()}
      </div>
      <div className="study-tile-label">{study.name}</div>
    </Link>
  )
}

export default function StudyList({ org }) {
  return (
    <div className="py-4">
      {org.studies.map((study) => (
        <StudyTile key={study.id} study={study} />
      ))}
      {org.canEdit && (
        <>
          <AddStudy org={org} />
          {org.studies.length === 0 && (
            <div className="text-muted d-inline-block mt-4 ms-3">Add a study to get started!</div>
          )}
        </>
      )}
    </div>
  )
}
