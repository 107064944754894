import { useApolloClient } from '@apollo/client'
import React, { useContext, useState } from 'react'

import Modal from '@@components/modal'
import { StudyContext } from '@@pages/study'

import { LOCK_REVISION_MUTATION } from './_queries'

export default function LockRevisionModal({ onDone }) {
  const study = useContext(StudyContext)
  const [waiting, setWaiting] = useState(false)
  const client = useApolloClient()

  const onConfirm = async () => {
    setWaiting(true)

    await client.mutate({
      mutation: LOCK_REVISION_MUTATION,
      variables: {
        input: {
          studyId: study.id,
        },
      },
    })

    onDone(true)
  }

  if (waiting) {
    return (
      <Modal>
        <div className="align-self-center card shadow">
          <div className="card-body">
            <div className="spinner-border" />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal onClose={() => onDone()}>
      <div className="align-self-center card shadow">
        <div className="card-body">
          <p className="fs-5 my-2">This will lock the revision and set it as the default.</p>
          <p className="fs-5 my-2">Are you sure you want to continue?</p>
        </div>
        <div className="card-footer d-flex">
          <button id="lock_revision_confirm" onClick={onConfirm} type="button" className="btn btn-primary me-1">
            Yes
          </button>
          <button type="button" onClick={() => onDone(false)} className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
