import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { SessionContext } from '.'
import RunningSession from './running-session'

function PreEventMessage() {
  const { session } = useContext(SessionContext)

  if (session.state === 'unstarted') {
    return (
      <>
        <p className="mt-2">This session has not been started.</p>
        <p className="mb-2">
          Return to the{' '}
          <Link to={`/study/${session.participant.study.id}/participants/${session.participant.id}`}>
            participant page
          </Link>{' '}
          to start the session.
        </p>
      </>
    )
  }

  if (!session.participant.isOnline) {
    return <span className="text-danger">The participant is not connected to the session.</span>
  }

  return 'Ready.'
}

export default function SessionMain() {
  const { session } = useContext(SessionContext)

  if (!session.currentEvent || session.currentEvent.state !== 'started') {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="card">
          <div className="card-body bg-light text-center">
            <PreEventMessage />
          </div>
        </div>
      </div>
    )
  }

  return <RunningSession />
}
