import { gql } from '@apollo/client'

export const GET_BANK_AND_REVISIONS_QUERY = gql`
  query GetVisits($studyId: ID!) {
    getStudy(input: { id: $studyId }) {
      study {
        id

        editRevision {
          id
        }

        defaultRevision {
          id
        }

        revisions {
          id
          title
        }

        assessmentBank {
          id
          label
          currentRevision {
            id
          }
        }
      }
    }
  }
`

export const GET_REVISION_QUERY = gql`
  query GetStudyRevision($input: GetStudyRevisionInput!) {
    getStudyRevision(input: $input) {
      studyRevision {
        id
        title
        note
        visits {
          id
          sort_key
          counterbalance
          events {
            id
            sort_key
            item {
              id
              studyAssessment {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`

export const ADD_VISIT_MUTATION = gql`
  mutation AddVisit($input: AddVisitInput!) {
    addVisit(input: $input) {
      visit {
        id
        sort_key
        counterbalance
        events {
          id
        }
      }
      studyRevision {
        id
        visits {
          id
        }
      }
    }
  }
`

export const ADD_EVENT_MUTATION = gql`
  mutation AddVisitEvent($input: AddVisitEventInput!) {
    addVisitEvent(input: $input) {
      visitEvent {
        id
        sort_key
        item {
          id
          studyAssessment {
            id
            label
          }
        }
      }
      visit {
        id
        events {
          id
        }
      }
    }
  }
`

export const UPDATE_VISIT_MUTATION = gql`
  mutation UpdateVisit($input: UpdateVisitInput!) {
    updateVisit(input: $input) {
      visit {
        id
        sort_key
        counterbalance
      }
      studyRevision {
        id
        visits {
          id
        }
      }
    }
  }
`

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateVisitEvent($input: UpdateVisitEventInput!) {
    updateVisitEvent(input: $input) {
      visitEvent {
        id
        sort_key
      }
      fromVisit {
        id
        events {
          id
        }
      }
      toVisit {
        id
        events {
          id
        }
      }
    }
  }
`

export const REMOVE_VISIT_MUTATION = gql`
  mutation RemoveVisit($input: RemoveVisitInput!) {
    removeVisit(input: $input) {
      studyRevision {
        id
        visits {
          id
        }
      }
    }
  }
`

export const REMOVE_EVENT_MUTATION = gql`
  mutation RemoveVisitEvent($input: RemoveVisitEventInput!) {
    removeVisitEvent(input: $input) {
      visit {
        id
        events {
          id
        }
      }
    }
  }
`

export const LOCK_REVISION_MUTATION = gql`
  mutation LockEditableRevision($input: ChangeEditableRevisionInput!) {
    lockEditableRevision(input: $input) {
      study {
        id
        editRevision {
          id
        }
        defaultRevision {
          id
        }
      }
    }
  }
`

export const CREATE_REVISION_MUTATION = gql`
  mutation CreateRevisionMutation($input: CreateEditableRevisionInput!) {
    createEditableRevision(input: $input) {
      study {
        id
        editRevision {
          id
        }
        revisions {
          id
          title
        }
      }
    }
  }
`

export const SET_DEFAULT_REVISION_MUTATION = gql`
  mutation SetDefaultRevision($input: SetDefaultRevisionInput!) {
    setDefaultRevision(input: $input) {
      study {
        id
        defaultRevision {
          id
        }
      }
    }
  }
`

export const UPDATE_REVISION_MUTATION = gql`
  mutation UpdateStudyRevision($input: UpdateStudyRevisionInput!) {
    updateStudyRevision(input: $input) {
      studyRevision {
        id
        note
        title
      }
    }
  }
`
