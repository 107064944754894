import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { StudyContext } from '@@pages/study'

import { GET_BANK_AND_REVISIONS_QUERY } from './_queries'
import StudyRevisionPage from './revision'

export const AssessmentBankContext = React.createContext()
export const RevisionListContext = React.createContext()

export default function VisitsPage() {
  const { url } = useRouteMatch()
  const study = useContext(StudyContext)
  const { loading, error, data } = useQuery(GET_BANK_AND_REVISIONS_QUERY, {
    variables: {
      studyId: study.id,
    },
  })

  if (error) throw error

  if (loading) {
    return <div className="spinner-border" />
  }

  const { editRevision, defaultRevision, revisions, assessmentBank } = data.getStudy.study
  let redirectRevisionId
  if (editRevision) {
    redirectRevisionId = editRevision.id
  } else if (defaultRevision) {
    redirectRevisionId = defaultRevision.id
  } else {
    redirectRevisionId = revisions[0].id
  }

  const revisionsLookup = new Map()
  for (const rev of revisions) {
    revisionsLookup.set(rev.id, {
      ...rev,
      editable: editRevision && rev.id === editRevision.id,
      isDefault: defaultRevision && rev.id === defaultRevision.id,
    })
  }

  return (
    <Switch>
      <Route path={url + '/:revisionId'}>
        <RevisionListContext.Provider value={revisionsLookup}>
          <AssessmentBankContext.Provider value={assessmentBank}>
            <StudyRevisionPage />
          </AssessmentBankContext.Provider>
        </RevisionListContext.Provider>
      </Route>
      <Redirect to={url + '/' + redirectRevisionId} />
    </Switch>
  )
}
