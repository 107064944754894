import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import Modal from '@@components/modal'

import { UPDATE_REVISION_MUTATION } from './_queries'

export default function EditTitleModal({ studyRevision, onDone }) {
  const [title, setTitle] = useState(studyRevision.title)
  const client = useApolloClient()

  const onSave = () => {
    client.mutate({
      mutation: UPDATE_REVISION_MUTATION,
      variables: {
        input: {
          studyRevisionId: studyRevision.id,
          title,
        },
      },
      optimisticResponse: {
        updateStudyRevision: {
          __typename: 'UpdateStudyRevisionPayload',
          studyRevision: {
            __typename: 'StudyRevision',
            id: studyRevision.id,
            note: studyRevision.note,
            title,
          },
        },
      },
    })

    onDone()
  }

  const onKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      onSave()
    }
    if (ev.key === 'Escape') {
      ev.preventDefault()
      onDone()
    }
  }

  return (
    <Modal onClose={() => onDone()}>
      <div className="align-self-center card shadow" style={{ width: '400px', maxWidth: '100%' }}>
        <div className="card-header fs-5">Edit the revision name</div>
        <div className="card-body">
          <input
            autoFocus
            type="text"
            value={title}
            onChange={(ev) => setTitle(ev.target.value)}
            onKeyUp={onKeyUp}
            className="form-control"
            id="edit_title"
          />
        </div>
        <div className="card-footer d-flex">
          <button id="save_title_button" onClick={onSave} type="button" className="btn btn-primary me-1">
            Save
          </button>
          <button type="button" onClick={() => onDone()} className="btn btn-outline-secondary ms-1">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
