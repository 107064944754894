import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { StudyContext } from '@@pages/study'

import ParticipantEditForm from './_edit-form.js'
import { CREATE_PARTICIPANT_MUTATION, GET_NEXT_PARTICIPANT_ID_QUERY } from './_queries.js'

export default function NewParticipantPage() {
  const study = useContext(StudyContext)
  const client = useApolloClient()
  const history = useHistory()
  const { loading, error, data } = useQuery(GET_NEXT_PARTICIPANT_ID_QUERY, {
    fetchPolicy: 'network-only', // do not use cached values
    variables: {
      input: {
        id: study.id,
      },
    },
  })

  if (loading) return <div className="spinner-border" />
  if (error) throw error

  const onSave = async (participant) => {
    const { data } = await client.mutate({
      mutation: CREATE_PARTICIPANT_MUTATION,
      variables: {
        input: {
          study_id: study.id,
          ...participant,
        },
      },
    })
    history.push('.', {
      message: `Successfully created participant ${data.createParticipant.participant.id.split('-')[0]}.`,
    })
  }

  const onCancel = () => {
    history.push('.')
  }

  return (
    <>
      <h4>Add Study Participant</h4>
      <ParticipantEditForm
        participant={{ externalId: data.getStudy.study.nextParticipantExternalId }}
        onSave={onSave}
        onCancel={onCancel}
      />
    </>
  )
}
