import * as Sentry from '@sentry/react'
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { getUserData, logout } from './auth-client.js'
import LinkAccountPage from './link_account.jsx'
import LoginPage from './login.jsx'
import NewPasswordPage from './new_password.jsx'
import Refresh, { getNewTokensFromRefresh } from './refresh'

function updateLocalStorage({ accessToken, idToken, refreshToken }) {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('idToken', idToken)
  localStorage.setItem('refreshToken', refreshToken)
}

export function getIdToken() {
  return localStorage.getItem('idToken')
}

function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export async function performRefresh() {
  updateLocalStorage(await getNewTokensFromRefresh())
}

const UserAttributeContext = React.createContext()

export function useUserAttributes() {
  const attrs = useContext(UserAttributeContext)
  // This logic is helpful for tests that don't have this context defined
  return attrs || {}
}

const states = {
  REFRESH: 'REFRESH',
  LOGGEDOUT: 'LOGGEDOUT',
  LOGGEDIN: 'LOGGEDIN',
  LINK: 'LINK',
  NEW_PASSWORD: 'NEW_PASSWORD',
}

export default function AuthBoundary({ children }) {
  const [authState, setAuthState] = useState({ code: states.REFRESH })
  const [userAttributes, setUserAttributes] = useState({})

  const setLoginTokens = ({ accessToken, idToken, refreshToken }) => {
    updateLocalStorage({ accessToken, idToken, refreshToken })
    setAuthState({ code: states.LOGGEDIN })
  }

  const accessToken = getAccessToken()

  useEffect(async () => {
    if (accessToken) {
      const data = await getUserData(accessToken)
      const attrs = {}
      data.UserAttributes.forEach(({ Name, Value }) => {
        attrs[Name] = Value
      })
      setUserAttributes(attrs)
    } else {
      setUserAttributes({})
    }
  }, [accessToken])

  switch (authState.code) {
    case states.REFRESH:
      return <Refresh onSuccess={setLoginTokens} onFailure={() => setAuthState({ code: states.LOGGEDOUT })} />

    case states.LOGGEDIN:
      Sentry.setUser({
        email: userAttributes.email,
      })
      return (
        <Router>
          <Switch>
            <Route path="/login">
              <Redirect to="/" />
            </Route>
            <Route
              path="/logout"
              render={() => {
                localStorage.clear()
                logout()
                return <div className="spinner-border" />
              }}
            />
            <Route>
              <UserAttributeContext.Provider value={userAttributes}>{children}</UserAttributeContext.Provider>
            </Route>
          </Switch>
        </Router>
      )

    case states.LINK:
      return <LinkAccountPage />

    case states.NEW_PASSWORD:
      return <NewPasswordPage session={authState.session} username={authState.username} onSuccess={setLoginTokens} />
  }

  // Logged out
  Sentry.setUser(null)

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <LoginPage
            onLoggedIn={setLoginTokens}
            onLink={() => setAuthState({ code: states.LINK })}
            onNewPassword={(username, session) => setAuthState({ code: states.NEW_PASSWORD, username, session })}
            onPasswordReset={() => console.log('password reset requested')}
          />
        </Route>
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  )
}
