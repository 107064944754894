import { useApolloClient, useQuery } from '@apollo/client'
import React, { useRef, useState } from 'react'

import JsonEditor from '@@components/json-editor'
import Modal from '@@components/modal'
import Waitable from '@@components/waitable'

import { DELETE_FILE, GET_FOLDER_QUERY, UPDATE_FILE } from './_queries'
import { useUploader } from './uploader'

function UploadItem({ upload, onRemove }) {
  switch (upload.status) {
    case 'initial':
      return (
        <li className="list-group-item d-flex align-items-center py-3">
          <div className="me-3 text-truncate">{upload.name}</div>
          <div key={upload.status} className="progress my-auto flex-grow-1" style={{ minWidth: '50%' }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-secondary w-100" />
          </div>
        </li>
      )

    case 'uploading':
      return (
        <li className="list-group-item d-flex align-items-center py-3">
          <div className="me-3 text-truncate">{upload.name}</div>
          <div key={upload.status} className="progress my-auto flex-grow-1" style={{ minWidth: '50%' }}>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: `${Math.round(upload.progress * 100)}%` }}
            />
          </div>
        </li>
      )

    case 'complete':
    case 'waiting':
      return (
        <li className="list-group-item d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center" style={{ width: '64px', height: '64px' }}>
            <div className="spinner-border" />
          </div>
          <div className="m-3 me-auto text-truncate">{upload.name}</div>
        </li>
      )
  }

  // Otherwise, treat it as an error
  return (
    <li className="list-group-item list-group-item-danger d-flex align-items-center py-3">
      <div className="me-3 text-truncate">{upload.name}</div>
      <div className="my-auto flex-grow-1" style={{ minWidth: '65%' }}>
        {upload.statusMessage}
      </div>
      <button onClick={onRemove} type="button" className="btn-close" />
    </li>
  )
}

function FileItem({ file, onMetadataClick }) {
  const dropdownToggleRef = useRef()
  const apollo = useApolloClient()
  const [waitingForDelete, setWaitingForDelete] = useState(false)
  const [editName, setEditName] = useState(null)

  const deleteFile = () => {
    setWaitingForDelete(true)
    apollo.mutate({
      mutation: DELETE_FILE,
      variables: {
        input: {
          id: file.id,
        },
      },
    })
  }

  const saveName = () => {
    const name = editName.trim()
    if (name) {
      apollo.mutate({
        mutation: UPDATE_FILE,
        variables: {
          input: {
            id: file.id,
            name,
          },
        },
        optimisticResponse: {
          updateFile: {
            __typename: 'UpdateFilePayload',
            file: {
              __typename: 'File',
              id: file.id,
              name,
            },
          },
        },
      })
      setEditName(null)
    }
  }

  const onKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      saveName()
    }
    if (ev.key === 'Escape') {
      ev.preventDefault()
      setEditName(null)
    }
  }

  return (
    <li className="list-group-item d-flex align-items-center">
      <a href={file.url} className="me-3" target="_blank" rel="noreferrer">
        <img height="64" className="rounded" src={file.thumbnailUrl} />
      </a>
      {!editName && (
        <>
          <div className="text-break">{file.name}</div>
          <button onClick={() => setEditName(file.name)} type="button" className="btn btn-link me-auto text-secondary">
            <i className="bi bi-pencil-fill" />
          </button>
        </>
      )}
      {editName && (
        <div className="flex-grow-1 position-relative">
          <input
            autoFocus
            type="text"
            value={editName}
            onChange={(ev) => setEditName(ev.target.value)}
            onKeyUp={onKeyUp}
            className="form-control"
          />
          <div className="position-absolute end-0 top-50 translate-middle-y">
            <button onClick={saveName} type="button" className="btn btn-link ps-0 pe-2 text-success">
              <i className="bi bi-check-lg" />
            </button>
            <button onClick={() => setEditName(null)} type="button" className="btn btn-link ps-0 pe-2">
              <i className="bi bi-x-lg" />
            </button>
          </div>
        </div>
      )}
      <button onClick={onMetadataClick} type="button" className="btn btn-link text-secondary">
        <i className="bi bi-code-square" />
      </button>
      <div className="dropend">
        <button
          type="button"
          ref={dropdownToggleRef}
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          className="btn btn-link text-secondary text-hover-danger"
        >
          <i className="bi bi-trash-fill" />
        </button>
        <div className="dropdown-menu shadow p-2 text-nowrap">
          <div className="d-inline-block me-3">Are you sure you want to delete this file?</div>
          <Waitable waiting={waitingForDelete}>
            <button onClick={deleteFile} type="button" className="btn btn-danger btn-sm">
              Delete
            </button>
          </Waitable>
        </div>
      </div>
    </li>
  )
}

function MetadataEditModal({ file, onClose }) {
  const apollo = useApolloClient()
  const editorRef = useRef()
  const [waiting, setWaiting] = useState(false)

  const onSave = async () => {
    const json = editorRef.current.validate()
    if (json) {
      setWaiting(true)
      await apollo.mutate({
        mutation: UPDATE_FILE,
        variables: {
          input: {
            id: file.id,
            metadata: json,
          },
        },
      })
      onClose()
    }
  }
  return (
    <Modal onClose={onClose}>
      <div className="position-relative align-self-center card shadow" style={{ width: '640px' }}>
        <div className="card-header d-flex py-3">
          <h4 className="fw-normal mb-0">
            <a href={file.url} className="me-3" target="_blank" rel="noreferrer">
              <img height="64" className="rounded" src={file.thumbnailUrl} />
            </a>
            {file.name} - edit metadata
          </h4>
          <button onClick={onClose} type="button" className="ms-auto btn-close" />
        </div>
        <div className="card-body">
          <JsonEditor ref={editorRef} json={file.metadata} />
        </div>
        <div className="card-footer d-flex">
          <Waitable waiting={waiting}>
            <button onClick={onSave} id="save_file_metadata" type="button" className="btn btn-primary me-1">
              Save
            </button>
            <button type="button" onClick={onClose} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </Waitable>
        </div>
      </div>
    </Modal>
  )
}

export default function FileFolderModal({ folderId, onEdit, onClose }) {
  const fileRef = useRef()
  const listRef = useRef()
  const dropRef = useRef()
  const dragCount = useRef(0)
  const [dragOver, setDragOver] = useState(false)
  const { uploads, upload, uploadError, remove } = useUploader(folderId)
  const [metadataFile, setMetadataFile] = useState(null)
  const { loading, error, data } = useQuery(GET_FOLDER_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        id: folderId,
      },
    },
  })

  if (loading) {
    return (
      <Modal>
        <div className="align-self-center card shadow p-4">
          <div className="spinner-border" />
        </div>
      </Modal>
    )
  }

  if (error) throw error

  const { name, files } = data.getFolder.folder

  const uploadClick = () => {
    fileRef.current.click()
  }

  const filesSelected = (ev) => {
    if (fileRef.current.files.length === 0) {
      return
    }
    upload(fileRef.current.files)
  }

  const onDragEnter = (ev) => {
    ev.preventDefault()
    if (dragCount.current === 0) {
      setDragOver(true)
    }
    dragCount.current++
  }

  const onDragLeave = (ev) => {
    ev.preventDefault()
    dragCount.current--
    if (dragCount.current === 0) {
      setDragOver(false)
    }
  }

  const onDrop = (ev) => {
    ev.preventDefault()
    dragCount.current = 0
    setDragOver(false)

    const files = []
    if (ev.dataTransfer.items) {
      for (const item of ev.dataTransfer.items) {
        if (item.kind === 'file') {
          files.push(item.getAsFile())
        }
      }
    }

    if (files.length > 0) {
      upload(files)
    }
  }

  if (metadataFile) {
    return <MetadataEditModal file={metadataFile} onClose={() => setMetadataFile(null)} />
  }

  return (
    <Modal onClose={onClose}>
      <div
        ref={dropRef}
        onDragOver={(ev) => ev.preventDefault()}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        className="position-relative align-self-center card shadow"
        style={{ width: '640px', maxHeight: '80%' }}
      >
        <div className="card-header d-flex py-3">
          <h4 className="fw-normal mb-0 text-break">
            <i className="bi bi-images me-3 float-start" />
            {name}
          </h4>
          <button onClick={onEdit} type="button" className="btn btn-link text-secondary">
            <i className="bi bi-pencil-fill" />
          </button>
          <button onClick={onClose} type="button" className="ms-auto btn-close" />
        </div>
        <ul ref={listRef} className="list-group list-group-flush overflow-auto">
          {files.map((file) => (
            <FileItem key={file.id} file={file} onMetadataClick={() => setMetadataFile(file)} />
          ))}
          {uploads.map((up) => (
            <UploadItem key={up.url} upload={up} onRemove={() => remove(up)} />
          ))}
        </ul>
        <div className="card-body">
          <button onClick={uploadClick} type="button" className="btn btn-sm btn-primary">
            Upload
          </button>
          <input ref={fileRef} onChange={filesSelected} accept="image/*" type="file" multiple className="d-none" />
          {uploadError && <span className="text-danger ms-4">{uploadError}</span>}
        </div>
        {dragOver && (
          <div
            className="position-absolute start-0 top-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: 'rgba(255,255,255,0.8)' }}
          >
            <div
              className="border-5 border-primary rounded-3 text-center text-primary px-5 py-3 fw-bold"
              style={{ borderStyle: 'dashed' }}
            >
              <div>
                <i className="fs-1 bi bi-cloud-plus" />
              </div>
              <div>Upload Files</div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
