import React from 'react'

import GoogleLoginButton from './_google_login_button.jsx'
import { loginWithGoogle } from './auth-client.js'

export default function LinkAccountPage() {
  const sizing = 'col-md-5 col-lg-4 col-xl-3'

  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12" style={{ marginTop: '-40px' }}>
          <h2 className="text-center">Account linked!</h2>
          <br />
          <p className="text-center">We have successfully linked your Google account to your existing Bruin login.</p>
          <p className="text-center">Please click the button below again to log in.</p>
          <div className={'mt-4 mb-2 mx-auto d-grid ' + sizing}>
            <GoogleLoginButton onClick={loginWithGoogle} />
          </div>
        </div>
      </div>
    </div>
  )
}
