import React from 'react'

import VisitEventList from './_visit-event-list'

export default function SessionSidebar() {
  return (
    <div className="border-bottom">
      <VisitEventList />
    </div>
  )
}
