import { useApolloClient, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import Waitable from '@@components/waitable'
import { StudyContext } from '@@pages/study'

import { RevisionListContext } from '.'
import AddAssessmentModal from './_add-assessment-modal'
import DeleteEventModal from './_delete-event-modal'
import DeleteVisitModal from './_delete-visit-modal'
import { DragDrop, Draggable, Droppable } from './_drag-drop'
import { ADD_VISIT_MUTATION, GET_REVISION_QUERY, UPDATE_EVENT_MUTATION, UPDATE_VISIT_MUTATION } from './_queries'
import RevisionActionsRow from './_revision-actions'
import RevisionNote from './_revision-note'

function DragEventFloater({ children }) {
  return <div className="m-0 bg-white shadow">{children}</div>
}

export function useAddVisitMutation() {
  const client = useApolloClient()
  const study = useContext(StudyContext)
  const [loading, setLoading] = useState(false)

  const addVisit = async () => {
    setLoading(true)
    await client.mutate({
      mutation: ADD_VISIT_MUTATION,
      variables: {
        input: {
          studyId: study.id,
        },
      },
    })
    setLoading(false)
  }
  return [addVisit, loading]
}

function VisitEventsList({ visit, editable }) {
  const [confirmDelete, setConfirmDelete] = useState()
  const { events } = visit

  if (events.length === 0) {
    return (
      <div className="my-0 align-middle">
        <Draggable.Placeholder scope="events">
          {(show) => {
            if (show) {
              return (
                <div className="p-2 border-bottom visit-event-blank-row">
                  <div className="fs-4">&nbsp;</div>
                </div>
              )
            }
            return (
              <div className="visit-event-row-empty" data-visitid={visit.id}>
                <div className="text-muted fst-italic border-bottom p-2 py-4 text-center">
                  This visit is empty! Use the <i className="bi bi-plus-lg mx-2" /> button above to add an assessment.
                </div>
              </div>
            )
          }}
        </Draggable.Placeholder>
      </div>
    )
  }

  const DeleteEventButton = ({ event }) => (
    <button
      id={'event-' + event.id + '-delete'}
      onClick={() => setConfirmDelete(event)}
      title="Delete"
      type="button"
      className="btn has-tooltip visit-event-trash-button fs-5"
    >
      <i className="bi bi-trash-fill" />
    </button>
  )

  return (
    <>
      <div className="my-0 align-middle">
        <Draggable.Placeholder scope="events">
          <div className="d-flex p-2 border-bottom visit-event-blank-row">
            <div className="fs-4">&nbsp;</div>
          </div>
        </Draggable.Placeholder>
        <Draggable.Map scope="events" collection={events}>
          {(event) => (
            <div
              className="d-flex flex-row align-items-center border-bottom p-2 visit-event-row"
              data-eventid={event.id}
              data-visitid={visit.id}
            >
              <div className="text-nowrap">
                <i className="fs-4 m-0 me-2 bi bi-calendar3-week" />
              </div>
              <div className="text-nowrap">
                <Link to={'../assessments/' + event.item.studyAssessment.id + '?revision=' + event.item.id}>
                  {event.item.studyAssessment.label}
                </Link>
              </div>
              <div className="ms-auto">
                {editable && (
                  <div className="visit-event-buttons d-flex justify-content-end align-items-center">
                    <DeleteEventButton event={event} />
                    <Draggable.Mover>
                      <div
                        id={'event-' + event.id + '-dragdrop'}
                        className="d-inline-block fs-5 ms-3 me-1 text-muted"
                        style={{ cursor: 'move' }}
                      >
                        <i className="bi bi-arrow-down-up" />
                      </div>
                    </Draggable.Mover>
                  </div>
                )}
              </div>
            </div>
          )}
        </Draggable.Map>
      </div>
      {confirmDelete && <DeleteEventModal visit={visit} event={confirmDelete} onDone={() => setConfirmDelete(null)} />}
    </>
  )
}

function Visit({ visit, editable, order, initiallyExpanded, onAddEvent }) {
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const [confirmDelete, setConfirmDelete] = useState()
  const client = useApolloClient()

  const onAdd = () => {
    setExpanded(true)
    onAddEvent(visit)
  }

  const onDelete = (visit) => {
    setConfirmDelete({
      visit,
      noConfirm: visit.events.length === 0, // Only show confirmation dialog if visit has events
    })
  }

  const updateVisit = ({ counterbalance }) => {
    client.mutate({
      mutation: UPDATE_VISIT_MUTATION,
      variables: {
        input: {
          id: visit.id,
          counterbalance,
        },
      },
      optimisticResponse: {
        updateVisit: {
          visit: {
            __typename: 'Visit',
            id: visit.id,
            sort_key: visit.sort_key,
            counterbalance,
          },
        },
      },
    })
  }

  return (
    <Droppable
      scope="events"
      id={visit.id}
      Floater={DragEventFloater}
      onDrag={() => {
        !expanded && setExpanded(true)
      }}
    >
      <div className="visit-section" id={'visit-' + visit.id}>
        <div className="d-flex border-bottom align-items-center pt-4 pb-2">
          <div className="flex-grow-1">
            <button
              id={'visit-' + visit.id + '-expand'}
              onClick={() => setExpanded(!expanded)}
              className="visit-button btn text-dark fs-5"
            >
              <i className={`bi bi-chevron-${expanded ? 'up' : 'down'} me-2`} /> Visit {order}
            </button>
            <div title="Visit Options" className="has-tooltip dropdown d-inline-block">
              <button
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                className="visit-button btn text-dark fs-6 ms-1"
              >
                <i className="bi bi-ui-checks" />
              </button>
              <div className="dropdown-menu px-3">
                <div className="form-check">
                  <input
                    disabled={!editable}
                    type="checkbox"
                    checked={visit.counterbalance}
                    onChange={(ev) => updateVisit({ counterbalance: ev.target.checked })}
                    className="form-check-input"
                    id="counterbalance_checkbox"
                  />
                  <label className="form-check-label" htmlFor="counterbalance_checkbox">
                    Counterbalance
                  </label>
                </div>
              </div>
            </div>
            {editable && (
              <>
                <button
                  type="button"
                  onClick={onAdd}
                  id={'visit-' + visit.id + '-add'}
                  title="Add Assessment"
                  className="visit-button has-tooltip btn text-dark fs-6 ms-1"
                >
                  <i className="bi bi-plus-lg" />
                </button>
                {visit.events.length === 0 && (
                  <button
                    onClick={() => onDelete(visit)}
                    id={'visit-' + visit.id + '-delete'}
                    title="Delete Visit"
                    type="button"
                    className="btn has-tooltip fs-6 visit-delete-button ms-1"
                  >
                    <i className="bi bi-trash-fill" />
                  </button>
                )}
              </>
            )}
          </div>
          <div className={'align-self-end ' + (visit.events.length === 0 ? 'text-danger' : 'text-secondary')}>
            {visit.events.length} {visit.events.length === 1 ? 'item' : 'items'}
          </div>
        </div>
        {expanded && <VisitEventsList editable={editable} visit={visit} />}
        {confirmDelete && (
          <DeleteVisitModal {...confirmDelete} onDone={(deleted) => deleted || setConfirmDelete(null)} />
        )}
      </div>
    </Droppable>
  )
}

export default function StudyRevisionPage() {
  const {
    params: { revisionId },
  } = useRouteMatch()
  const client = useApolloClient()
  const revisions = useContext(RevisionListContext)
  const [addEventTo, setAddEventTo] = useState(null) // Visit we're adding an event to
  const [addVisit, waitingForAdd] = useAddVisitMutation()
  const { loading, error, data } = useQuery(GET_REVISION_QUERY, {
    variables: {
      input: {
        id: revisionId,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return <div className="spinner-border" />
  }

  const { studyRevision } = data.getStudyRevision
  const { visits } = studyRevision
  const { editable } = revisions.get(studyRevision.id)

  const onDragDrop = async (from, to) => {
    if (from.id === to.id && from.index === to.index) {
      return
    }

    const fromVisit = visits.find((v) => v.id === from.id)
    const toVisit = visits.find((v) => v.id === to.id)
    const fromEvents = [...fromVisit.events]
    const toEvents = fromVisit.id === toVisit.id ? fromEvents : [...toVisit.events]
    const [event] = fromEvents.splice(from.index, 1)

    let sortKey
    const afterEvent = toEvents[to.index - 1]
    const beforeEvent = toEvents[to.index]
    if (afterEvent && beforeEvent) {
      sortKey = (afterEvent.sort_key + beforeEvent.sort_key) / 2
    } else if (afterEvent) {
      sortKey = afterEvent.sort_key + 1.0
    } else if (beforeEvent) {
      sortKey = beforeEvent.sort_key - 1.0
    } else {
      sortKey = 1.0
    }

    toEvents.splice(to.index, 0, event)

    await client.mutate({
      mutation: UPDATE_EVENT_MUTATION,
      variables: {
        input: {
          id: event.id,
          sort_key: sortKey,
          visitId: toVisit.id,
        },
      },
      optimisticResponse: {
        updateVisitEvent: {
          __typename: 'UpdateVisitEventPayload',
          visitEvent: {
            __typename: 'VisitEvent',
            id: event.id,
            sort_key: sortKey,
          },
          fromVisit: {
            __typename: 'Visit',
            id: fromVisit.id,
            events: fromEvents,
          },
          toVisit: {
            __typename: 'Visit',
            id: toVisit.id,
            events: toEvents,
          },
        },
      },
    })
  }

  const onAddEvent = (visit) => {
    setAddEventTo(visit)
  }

  return (
    <>
      <div className="container-fluid">
        <RevisionActionsRow studyRevision={studyRevision} />
        {visits.length === 0 && (
          <div className="row pt-4">
            <Waitable waiting={waitingForAdd}>
              <div className="col text-center">
                <p className="fw-bold">You haven't created any visits yet!</p>
                <p>
                  You can use the{' '}
                  <button type="button" onClick={addVisit} className="btn btn-link p-0">
                    {' '}
                    <i className="bi bi-calendar-plus mx-2" />
                  </button>{' '}
                  button to add a visit.
                </p>
              </div>
            </Waitable>
          </div>
        )}
        <div className="row">
          <div className="col">
            <DragDrop disabled={!editable} scope="events" onDrop={onDragDrop}>
              {visits.map((visit, i) => (
                <Visit
                  editable={editable}
                  initiallyExpanded={true}
                  key={visit.id}
                  order={i + 1}
                  visit={visit}
                  onAddEvent={onAddEvent}
                />
              ))}
            </DragDrop>

            {editable && (
              <div className="pt-4">
                <Waitable waiting={waitingForAdd}>
                  <button id="add_visit_button" onClick={addVisit} className="visit-button btn text-dark fs-5">
                    <i className="bi bi-plus-lg me-2" /> Add Visit
                  </button>
                </Waitable>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col pt-4">
            <RevisionNote studyRevision={studyRevision} />
          </div>
        </div>
      </div>
      {addEventTo && <AddAssessmentModal visit={addEventTo} onDone={() => setAddEventTo(null)} />}
    </>
  )
}
