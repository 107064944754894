import { useApolloClient } from '@apollo/client'
import React, { useContext, useState } from 'react'

import Modal from '@@components/modal'
import Toast from '@@components/toast'
import Waitable from '@@components/waitable'

import { useWebsocket } from '../../lib/websocket'
import { SessionContext } from '.'
import { CANCEL_EVENT_MUTATION, CONFIRM_EVENT_MUTATION, START_EVENT_MUTATION } from './_queries'

function UnstartedRow({ index, event, label, active, waiting, onStart, onCancel }) {
  if (active) {
    return (
      <tr id={'event_' + event.id}>
        <th scope="row" className="text-nowrap text-dark">
          {index + 1}
        </th>
        <td className="w-100">{label}</td>
        <td className="text-end text-nowrap">
          <Waitable small waiting={waiting}>
            <button onClick={onStart} type="button" className="btn btn-sm btn-primary">
              Start
            </button>
            <div className="dropdown d-inline-block">
              <button type="button" data-bs-toggle="dropdown" className="btn btn-link no-glow px-1 ms-1">
                <i className="bi bi-three-dots-vertical" />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button onClick={onCancel} type="button" className="dropdown-item dropdown-item-danger">
                    Skip
                    <i className="bi bi-x-circle float-end" />
                  </button>
                </li>
              </ul>
            </div>
          </Waitable>
        </td>
      </tr>
    )
  }

  return (
    <tr id={'event_' + event.id} className="table-secondary text-muted">
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 text-muted">unstarted</span>
          <div className="dropdown d-inline-block">
            <button type="button" disabled={true} data-bs-toggle="dropdown" className="btn btn-link no-glow px-1 ms-1">
              <i className="bi bi-three-dots-vertical" />
            </button>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function SkippedRow({ index, event, label, active, waiting, onStart }) {
  let rowClass = 'table-secondary text-muted'
  if (active) {
    rowClass = 'table-danger text-danger'
  }

  return (
    <tr id={'event_' + event.id} className={rowClass}>
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 text-danger">skipped</span>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              disabled={!active}
              data-bs-toggle="dropdown"
              className="btn btn-link no-glow px-1 ms-1"
            >
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <button onClick={onStart} type="button" className="dropdown-item">
                  Restart
                  <i className="bi bi-skip-start-circle float-end" />
                </button>
              </li>
            </ul>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function StartedRow({ index, event, label, waiting, onCancel }) {
  return (
    <tr id={'event_' + event.id} className="">
      <th scope="row" className="text-nowrap text-dark">
        <div className="spinner-border spinner-border-sm" />
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 fw-bold text-primary">in progress</span>
          <div className="dropdown d-inline-block">
            <button type="button" data-bs-toggle="dropdown" className="btn btn-link no-glow px-1 ms-1">
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <button onClick={onCancel} type="button" className="dropdown-item dropdown-item-danger">
                  Abort
                  <i className="bi bi-x-circle float-end" />
                </button>
              </li>
            </ul>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function AbortedRow({ index, event, label, active, waiting, onStart }) {
  let rowClass = 'table-secondary text-muted'
  if (active) {
    rowClass = 'table-danger text-danger'
  }

  return (
    <tr id={'event_' + event.id} className={rowClass}>
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 text-danger">aborted</span>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              disabled={!active}
              data-bs-toggle="dropdown"
              className="btn btn-link no-glow px-1 ms-1"
            >
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <button onClick={onStart} type="button" className="dropdown-item">
                  Restart
                  <i className="bi bi-skip-start-circle float-end" />
                </button>
              </li>
            </ul>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function CompleteRow({ index, event, label, active, current, waiting, onCancel, onConfirm }) {
  let rowClass = 'table-secondary text-muted'
  if (active) {
    rowClass = 'table-success text-success'
  }

  return (
    <tr id={'event_' + event.id} className={rowClass}>
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          {current ? (
            <button onClick={onConfirm} type="button" className="btn btn-sm btn-success">
              Complete
            </button>
          ) : (
            <span className="fs-7 text-success">complete</span>
          )}
          <div className="dropdown d-inline-block">
            <button
              type="button"
              disabled={!active}
              data-bs-toggle="dropdown"
              className="btn btn-link no-glow px-1 ms-1"
            >
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <button onClick={onCancel} type="button" className="dropdown-item dropdown-item-danger">
                  Reject
                  <i className="bi bi-x-circle float-end" />
                </button>
              </li>
            </ul>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function RejectedRow({ index, event, label, active, waiting, onStart, onConfirm }) {
  let rowClass = 'table-secondary text-muted'
  if (active) {
    rowClass = 'table-danger text-danger'
  }

  return (
    <tr id={'event_' + event.id} className={rowClass}>
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 text-danger">rejected</span>
          <div className="dropdown d-inline-block">
            <button
              type="button"
              disabled={!active}
              data-bs-toggle="dropdown"
              className="btn btn-link no-glow px-1 ms-1"
            >
              <i className="bi bi-three-dots-vertical" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <button onClick={onConfirm} type="button" className="dropdown-item">
                  Recover
                  <i className="bi bi-check-circle float-end" />
                </button>
              </li>
              <li>
                <button onClick={onStart} type="button" className="dropdown-item">
                  Restart
                  <i className="bi bi-skip-start-circle float-end" />
                </button>
              </li>
            </ul>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function EventRow(props) {
  switch (props.event.state) {
    case 'unstarted':
      return <UnstartedRow {...props} />

    case 'aborted':
      return <AbortedRow {...props} />

    case 'started':
      return <StartedRow {...props} />

    case 'skipped':
      return <SkippedRow {...props} />

    case 'complete':
      return <CompleteRow {...props} />

    case 'rejected':
      return <RejectedRow {...props} />
  }

  // Default (unrecognized state)
  const { event, waiting, index, label } = props
  return (
    <tr id={'event_' + event.id} className="table-secondary text-muted">
      <th scope="row" className="text-nowrap text-dark">
        {index + 1}
      </th>
      <td className="w-100">{label}</td>
      <td className="text-end text-nowrap">
        <Waitable small waiting={waiting}>
          <span className="fs-7 text-muted">{event.state}</span>
          <div className="dropdown d-inline-block">
            <button type="button" disabled={true} data-bs-toggle="dropdown" className="btn btn-link no-glow px-1 ms-1">
              <i className="bi bi-three-dots-vertical" />
            </button>
          </div>
        </Waitable>
      </td>
    </tr>
  )
}

function CancelModal({ event, onCancel, onConfirm }) {
  const [waiting, setWaiting] = useState(false)
  let action = 'cancel'
  switch (event.state) {
    case 'unstarted':
      action = 'skip'
      break

    case 'started':
      action = 'abort'
      break

    case 'complete':
      action = 'reject'
      break
  }

  const yesClick = () => {
    setWaiting(true)
    onConfirm(event)
  }

  return (
    <Modal onClose={onCancel}>
      <div className="align-self-center card shadow">
        <div className="card-body text-center">
          <h5>Are you sure you want to {action} this assessment?</h5>
        </div>
        <div className="card-footer d-flex">
          <Waitable waiting={waiting}>
            <button id="cancel_confirm" type="button" onClick={yesClick} className="btn btn-danger me-1">
              Yes
            </button>
            <button id="cancel_cancel" type="button" onClick={onCancel} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </Waitable>
        </div>
      </div>
    </Modal>
  )
}

function RestartModal({ event, onCancel, onConfirm }) {
  const [waiting, setWaiting] = useState(false)

  const yesClick = () => {
    setWaiting(true)
    onConfirm(event)
  }

  return (
    <Modal onClose={onCancel}>
      <div className="align-self-center card shadow">
        <div className="card-body text-center">
          <h5>Are you sure you want to restart this assessment?</h5>
        </div>
        <div className="card-footer d-flex">
          <Waitable waiting={waiting}>
            <button id="start_confirm" type="button" onClick={yesClick} className="btn btn-danger me-1">
              Yes
            </button>
            <button id="start_cancel" type="button" onClick={onCancel} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </Waitable>
        </div>
      </div>
    </Modal>
  )
}

export default function VisitEventList() {
  const { session, saveAndClose, waiting } = useContext(SessionContext)
  const [waitingFor, setWaitingFor] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [cancelling, confirmCancelling] = useState(null)
  const [starting, confirmStarting] = useState(null)
  const client = useApolloClient()
  const ws = useWebsocket()

  const onStart = async (event) => {
    setWaitingFor(event.id)
    if (event.state === 'unstarted') {
      // No confirmation when starting an unstarted assessment - just confirm restarts
      await doStart(event)
    } else {
      confirmStarting(event)
    }
  }

  const doStart = async (event) => {
    confirmStarting(null)

    // LoadEvent: Asks participant to load event details from DB
    const { error, type } = await ws.sendMessage({
      type: 'LoadEvent',
      data: { id: event.id },
    })
    if (error) {
      console.log(error)
      setErrorMessage('Event cannot be started')
      setWaitingFor(null)
      throw error
    }

    // If the load was successful, create the dataToken + authToken
    if (type.toLowerCase() === 'eventloaded') {
      const { data } = await client.mutate({
        mutation: START_EVENT_MUTATION,
        variables: {
          input: {
            sessionEventId: event.id,
          },
        },
      })

      // StartEvent: Asks participant to start the task with the given dataToken
      ws.sendMessage({
        type: 'StartEvent',
        data: {
          id: event.id,
          authToken: data.startSessionEvent.participantAuthToken,
        },
      })
    } else {
      setErrorMessage('Event cannot be started')
    }
    setWaitingFor(null)
  }

  // For skipping, aborting, and rejecting
  const onCancel = async (event) => {
    confirmCancelling(event)
    setWaitingFor(event.id)
  }

  const doCancel = async (event) => {
    confirmCancelling(null)
    // If this event is being run on the participant's end then ask them to abort it
    const { error, data } = await ws.sendMessage({ type: 'EventStatus' })
    if (!error && data && data.eventId === event.id) {
      await ws.sendMessage({
        type: 'AbortEvent',
        data: {
          id: event.id,
        },
      })
    }
    await client.mutate({
      mutation: CANCEL_EVENT_MUTATION,
      variables: {
        input: {
          sessionEventId: event.id,
        },
      },
    })
    setWaitingFor(null)
  }

  const onConfirm = async (event) => {
    setWaitingFor(event.id)
    await client.mutate({
      mutation: CONFIRM_EVENT_MUTATION,
      variables: {
        input: {
          sessionEventId: event.id,
        },
      },
    })
    setWaitingFor(null)
  }

  const firstUnstarted = session.events.find((event) => event.state === 'unstarted')

  const isActive = (event) => {
    if (session.state !== 'started' || (waitingFor && waitingFor !== event.id)) {
      return false
    }
    if (session.currentEvent) {
      return event.id === session.currentEvent.id
    }
    if (event.state === 'unstarted') {
      return event.id === firstUnstarted.id
    }
    return true
  }

  const canComplete =
    session.events.every((event) => event.state !== 'unstarted' && event.state !== 'started') &&
    !session.currentEvent &&
    session.state === 'started'

  return (
    <>
      <div className="bg-light text-secondary text-center fw-bold p-3 border-bottom">Assessments</div>
      {canComplete && (
        <div className="text-center p-3 border-bottom d-grid">
          <p>All assessments are complete.</p>
          <Waitable waiting={waiting}>
            <button onClick={saveAndClose} type="button" className="btn btn-primary">
              End Session
            </button>
          </Waitable>
        </div>
      )}
      <table className="table table-border-bottom-0 m-0 align-middle">
        <tbody>
          {session.events.map((event, i) => (
            <EventRow
              key={event.id}
              index={i}
              event={event}
              label={event.visitEvent.item.studyAssessment.label}
              active={isActive(event)}
              current={session.currentEvent && event.id === session.currentEvent.id}
              waiting={waitingFor === event.id}
              onStart={() => onStart(event)}
              onCancel={() => onCancel(event)}
              onConfirm={() => onConfirm(event)}
            />
          ))}
        </tbody>
      </table>
      {errorMessage && (
        <Toast onClose={() => setErrorMessage(null)}>
          <span className="text-danger fw-bold">{errorMessage}</span>
        </Toast>
      )}
      {cancelling && (
        <CancelModal
          event={cancelling}
          onCancel={() => {
            confirmCancelling(null)
            setWaitingFor(null)
          }}
          onConfirm={doCancel}
        />
      )}
      {starting && (
        <RestartModal
          event={starting}
          onCancel={() => {
            confirmStarting(null)
            setWaitingFor(null)
          }}
          onConfirm={doStart}
        />
      )}
    </>
  )
}
