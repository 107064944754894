import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { StudyContext } from '@@pages/study'

import { GET_STUDY_ASSESSMENTS_QUERY } from './_queries.js'

function flattenStudyAssessment(studyAssessment) {
  return {
    id: studyAssessment.id,
    label: studyAssessment.label,
    assessmentVersion: studyAssessment.currentRevision.assessmentVersion.version,
    assessmentType: studyAssessment.currentRevision.assessmentVersion.assessment.type,
    assessmentName: studyAssessment.currentRevision.assessmentVersion.assessment.name,
  }
}

export default function AssessmentBank() {
  const study = useContext(StudyContext)
  const { url } = useRouteMatch()
  const { loading, error, data } = useQuery(GET_STUDY_ASSESSMENTS_QUERY, {
    variables: {
      input: {
        id: study.id,
      },
    },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return <div className="spinner-border" />
  }

  const studyAssessments = data.getStudy.study.assessmentBank.map(flattenStudyAssessment)

  return (
    <>
      <div className="text-end">
        <Link to={url + '/library'} className="btn btn-success fs-5 fw-light" id="browse_task_library">
          Browse Task Library
        </Link>
      </div>
      <div className="m-4">
        <table className="table table-hover align-middle">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Label</th>
              <th scope="col">Type</th>
              <th scope="col">Assessment</th>
              <th scope="col">Version</th>
            </tr>
          </thead>
          <tbody>
            {studyAssessments.map(({ id, label, assessmentVersion, assessmentType, assessmentName }) => (
              <tr key={id}>
                <td className="text-nowrap w-0">
                  <i className="fs-4 m-0 me-2 bi bi-calendar3-week" />
                </td>
                <td>
                  <Link to={url + '/' + id}>{label}</Link>
                </td>
                <td>{assessmentType}</td>
                <td>{assessmentName}</td>
                <td>{assessmentVersion}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {studyAssessments.length === 0 && (
          <div className="py-4 text-secondary text-center">
            <p className="fw-bold">There are no assessments in your bank!</p>
            <p>
              Visit the <Link to={url + '/library'}>task library</Link> to configure a task and add it to your bank.
            </p>
          </div>
        )}
      </div>
    </>
  )
}
