import { useApolloClient, useQuery } from '@apollo/client'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import ParticipantEditForm from './_edit-form.js'
import { GET_PARTICIPANT_QUERY, UPDATE_PARTICIPANT_MUTATION } from './_queries.js'

export default function EditParticipantPage() {
  const { params } = useRouteMatch()
  const client = useApolloClient()
  const history = useHistory()

  const { loading, error, data } = useQuery(GET_PARTICIPANT_QUERY, {
    variables: {
      input: {
        id: params.id,
      },
    },
  })

  if (error) throw error

  if (loading) {
    return <div id="edit-participant-spinner" className="spinner-border" />
  }

  const onSave = async ({ first_name, last_name, externalId }) => {
    /* eslint camelcase: "off" */
    await client.mutate({
      mutation: UPDATE_PARTICIPANT_MUTATION,
      variables: {
        input: {
          id: params.id,
          first_name,
          last_name,
          externalId,
        },
      },
    })
    history.push('.', { message: 'Successfully updated participant.' })
  }

  const onCancel = () => {
    history.push('.')
  }

  return (
    <>
      <h4>Edit Study Participant</h4>
      <ParticipantEditForm participant={data.getParticipant.participant} onSave={onSave} onCancel={onCancel} />
    </>
  )
}
