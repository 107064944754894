import React, { useContext, useEffect, useRef, useState } from 'react'

export const BreadcrumbContext = React.createContext()

// Used to add values to the breadcrumb
export function Breadcrumb({ value, path, children }) {
  const ctx = useContext(BreadcrumbContext)
  useEffect(() => {
    if (ctx) {
      // Makes testing easier when there is no conterxt
      ctx.add({ value, path })
      return ctx.remove // Call this when the effect is unmounted
    }
  }, [value])
  return children || ''
}

export function useBreadcrumb() {
  // Use state to trigger re-renders when breadcrumb changes
  const [breadcrumb, setBreadcrumb] = useState([])

  // Use ref to be the source of truth for breadcrumb since setBreadcrumb doesn't update breadcrumb immediately
  const breadcrumbRef = useRef([])

  // Pass this to the BreadcrumbContext.Provider so it can be used by the Breadcrumb component (above)
  const breadcrumbContextObj = {
    add: (obj) => {
      breadcrumbRef.current.push(obj)
      setBreadcrumb([...breadcrumbRef.current])
    },

    remove: () => {
      breadcrumbRef.current.pop()
      setBreadcrumb([...breadcrumbRef.current])
    },
  }

  return [breadcrumb.flat(), breadcrumbContextObj]
}
