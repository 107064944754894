import { gql } from '@apollo/client'

export const SESSION_EVENT_FRAGMENT = gql`
  fragment SessionEventFields on SessionEvent {
    state
    visitEvent {
      id
      item {
        id
        config
        studyAssessment {
          id
          label
        }
        assessmentVersion {
          id
          version
          assessment {
            id
            type
            name
            title
          }
        }
      }
    }
  }
`

export const GET_SESSION_QUERY = gql`
  ${SESSION_EVENT_FRAGMENT}
  query GetSession($input: GetSessionInput!) {
    getSession(input: $input) {
      session {
        id
        state
        serialnum
        participant {
          id
          first_name
          last_name
          externalId
          isOnline
          study {
            id
          }
        }
        events {
          id
          ...SessionEventFields
        }
        currentEvent {
          id
          ...SessionEventFields
        }
      }
    }
  }
`

export const COMPLETE_SESSION_MUTATION = gql`
  mutation CompleteSession($input: CompleteSessionInput!) {
    completeSession(input: $input) {
      session {
        id
        state
        events {
          id
          state
        }
        currentEvent {
          id
        }
        participant {
          id
          currentSession {
            id
          }
        }
      }
    }
  }
`

export const START_EVENT_MUTATION = gql`
  mutation StartEvent($input: StartSessionEventInput!) {
    startSessionEvent(input: $input) {
      dataToken
      participantAuthToken
      session {
        id
        currentEvent {
          id
          state
        }
      }
    }
  }
`

export const CREATE_READER_TOKEN_MUTATION = gql`
  mutation CreateReaderToken($input: CreateReaderTokenInput!) {
    createReaderToken(input: $input) {
      authToken
    }
  }
`

export const CANCEL_EVENT_MUTATION = gql`
  mutation CancelEvent($input: CancelSessionEventInput!) {
    cancelSessionEvent(input: $input) {
      session {
        id
        currentEvent {
          id
          state
        }
      }
      sessionEvent {
        id
        state
      }
    }
  }
`

export const CONFIRM_EVENT_MUTATION = gql`
  mutation ConfirmEvent($input: ConfirmSessionEventInput!) {
    confirmSessionEvent(input: $input) {
      session {
        id
        currentEvent {
          id
          state
        }
      }
      sessionEvent {
        id
        state
      }
    }
  }
`
