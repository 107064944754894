import { useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'

import { GET_ASSESSMENTS_QUERY } from './_queries.js'

export default function AssessmentLibrary() {
  const { loading, error, data } = useQuery(GET_ASSESSMENTS_QUERY, {
    variables: {
      input: {
        type: 'task',
      },
    },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return <div className="spinner-border" />
  }

  const assessments = data.getAssessments.assessments.filter((ass) => ass.published)

  return (
    <>
      <div className="row p-3 m-2 pt-0 mt-0 border-bottom" />
      {assessments.map(({ id, type, name, title, summary, defaultVersion: { version } }) => (
        <div key={id} id={`${type}-${id}`} className="row p-3 m-2 border-bottom">
          <div className="col">
            <h5>
              <Link className="text-decoration-none" to={'./task/' + name}>
                {title}
              </Link>
            </h5>
            <p>{summary}</p>
          </div>
        </div>
      ))}
    </>
  )
}
