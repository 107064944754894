import { gql } from '@apollo/client'

export const GET_ASSESSMENTS_QUERY = gql`
  query GetAssessments($input: GetAssessmentsInput!) {
    getAssessments(input: $input) {
      assessments {
        id
        type
        name
        title
        summary
        description
        published
        defaultVersion {
          id
          version
        }
      }
    }
  }
`

export const GET_ASSESSMENT_QUERY = gql`
  query GetAssessment($input: GetAssessmentInput!) {
    getAssessment(input: $input) {
      assessment {
        id
        type
        name
        title
        summary
        description
        defaultVersion {
          id
          version
        }
        versions {
          id
          version
        }
      }
    }
  }
`

export const ADD_STUDY_ASSESSMENT_MUTATION = gql`
  mutation AddStudyAssessment($input: AddStudyAssessmentInput!) {
    addStudyAssessment(input: $input) {
      studyAssessment {
        id
        label
        currentRevision {
          id
          config
          assessmentVersion {
            id
            version
            assessment {
              id
              type
              name
              title
              summary
              description
            }
          }
        }
      }
    }
  }
`

const RevisionFields = gql`
  fragment RevisionFields on StudyAssessmentRev {
    config
    assessmentVersion {
      id
      version
      assessment {
        id
        type
        name
        title
        summary
        description
      }
    }
  }
`

export const GET_STUDY_ASSESSMENT_QUERY = gql`
  ${RevisionFields}
  query GetStudyAssessment($input: GetStudyAssessmentInput!) {
    getStudyAssessment(input: $input) {
      studyAssessment {
        id
        label
        currentRevision {
          id
          ...RevisionFields
        }
        revisions {
          id
          createdAt
        }
      }
    }
  }
`

export const GET_STUDY_ASSESSMENT_REVISION_QUERY = gql`
  ${RevisionFields}
  query GetStudyAssessmentRevision($input: GetStudyAssessmentRevInput!) {
    getStudyAssessmentRev(input: $input) {
      studyAssessmentRev {
        id
        ...RevisionFields
      }
    }
  }
`

export const GET_STUDY_ASSESSMENTS_QUERY = gql`
  query GetAssessmentBank($input: GetStudyInput!) {
    getStudy(input: $input) {
      study {
        id
        assessmentBank {
          id
          label
          currentRevision {
            id
            assessmentVersion {
              id
              version
              assessment {
                id
                type
                name
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_STUDY_ASSESSMENT_MUTATION = gql`
  mutation UpdateStudyAssessment($input: UpdateStudyAssessmentInput!) {
    updateStudyAssessment(input: $input) {
      studyAssessment {
        id
        label
      }
    }
  }
`

export const UPDATE_STUDY_ASSESSMENT_CONFIG_MUTATION = gql`
  mutation UpdateStudyAssessmentRev($input: UpdateStudyAssessmentConfigInput!) {
    updateStudyAssessmentConfig(input: $input) {
      studyAssessment {
        id
        currentRevision {
          id
          config
          assessmentVersion {
            id
            version
            assessment {
              id
              type
              name
              title
              summary
              description
            }
          }
        }
      }
    }
  }
`
