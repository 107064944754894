import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import NotFoundErrorPage from '@@pages/errors/not-found.jsx'

import { Breadcrumb } from '../_breadcrumb'
import AssessmentBank from './bank.jsx'
import AssessmentLibrary from './library.jsx'
import StudyAssessmentPage from './study-assessment.jsx'
import TaskPage from './task.jsx'

export default function AssessmentsPage() {
  const { url } = useRouteMatch()
  return (
    <>
      <Switch>
        <Route path={url + '/task/:task'}>
          <Breadcrumb value="Library" path={url + '/library'} />
          <TaskPage />
        </Route>
        <Route exact path={url + '/library'}>
          <Breadcrumb value="Library" path={url + '/library'} />
          <AssessmentLibrary />
        </Route>
        <Route path={url + '/:studyAssessmentId'}>
          <Breadcrumb value="Bank" path={url} />
          <StudyAssessmentPage />
        </Route>
        <Route exact path={url}>
          <Breadcrumb value="Bank" path={url} />
          <AssessmentBank />
        </Route>
        <Route>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </>
  )
}
