import React from 'react'

export default function InternalErrorPage() {
  return (
    <div className="container-fluid h-100 bg-light">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 text-center">
          <h1 className="display-1 text-muted">
            <i className="bi bi-exclamation-triangle" /> Internal Error
          </h1>
          <p className="lead text-muted">An error occurred. Please go back and try again.</p>
        </div>
      </div>
    </div>
  )
}
