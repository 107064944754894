import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import Modal from '@@components/modal'
import Waitable from '@@components/waitable'

import { CREATE_FOLDER_MUTATION, UPDATE_FOLDER_MUTATION } from './_queries'
import FileFolderModal from './folder'
import { FileUploadProvider } from './uploader'

// If folderId provided, edit the folder name
function EditFolderModal({ owner, onClose, folderId, initialValue }) {
  const [name, setName] = useState(initialValue ?? '')
  const [waiting, setWaiting] = useState(false)
  const [error, setError] = useState(null)
  const client = useApolloClient()

  const onSave = async () => {
    setWaiting(true)
    setError(null)
    try {
      if (folderId) {
        await client.mutate({
          mutation: UPDATE_FOLDER_MUTATION,
          variables: {
            input: {
              id: folderId,
              name,
            },
          },
        })
      } else {
        await client.mutate({
          mutation: CREATE_FOLDER_MUTATION,
          variables: {
            input: {
              name,
              type: 'images',
              ownerType: owner.__typename.toLowerCase(),
              ownerId: owner.id,
            },
          },
        })
      }
      onClose()
    } catch (err) {
      if (err.message.includes('INVALID_FOLDER_NAME')) {
        setError('Invalid folder name.')
      } else if (err.message.includes('DUPLICATE_FOLDER_NAME')) {
        setError('Folder name already exists.')
      } else {
        console.error(err)
        setError('An error occurred.')
      }
      setWaiting(false)
    }
  }

  const onKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      onSave()
    }
    if (ev.key === 'Escape') {
      ev.preventDefault()
      onClose()
    }
  }

  return (
    <Modal onClose={onClose}>
      <div className="align-self-center card shadow" style={{ width: '480px', maxWidth: '100%' }}>
        <div className="card-header fs-5">
          <i className="bi bi-images me-3" />
          Enter folder name
        </div>
        <div className="card-body">
          <input
            autoFocus
            type="text"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            onKeyUp={onKeyUp}
            className={'form-control ' + (error ? 'is-invalid' : '')}
            id="edit_folder_name"
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
        <div className="card-footer d-flex">
          <Waitable waiting={waiting}>
            <button id="create_folder_button" onClick={onSave} type="button" className="btn btn-primary me-1">
              Save
            </button>
            <button type="button" onClick={onClose} className="btn btn-outline-secondary ms-1">
              Cancel
            </button>
          </Waitable>
        </div>
      </div>
    </Modal>
  )
}

export default function FileUploads({ owner }) {
  const [modalFolder, setModalFolder] = useState(null)
  const [editFolder, setEditFolder] = useState(null)
  const [showCreateModal, setShowCreateModal] = useState(false)

  return (
    <FileUploadProvider>
      <div className="row row-cols-1 row-cols-sm-auto gx-2">
        {owner.folders.map((folder) => (
          <div key={folder.id} className="d-grid pt-2">
            <button
              onClick={() => setModalFolder(folder)}
              type="button"
              className="col btn btn-outline-secondary text-start d-flex align-items-center text-break"
            >
              <i className="bi bi-images fs-5 me-3" />
              {folder.name}
            </button>
          </div>
        ))}
        <div className="d-grid pt-2 dropend">
          <button type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" className="btn btn-secondary">
            <i className="bi bi-folder-plus fs-5" />
          </button>
          <ul className="dropdown-menu shadow dropdown-menu-dark">
            <li>
              <button
                onClick={() => setShowCreateModal(true)}
                type="button"
                className="dropdown-item d-flex align-items-center"
              >
                <i className="bi bi-images fs-5 me-3" />
                Add image folder
              </button>
            </li>
          </ul>
        </div>
      </div>
      {showCreateModal && <EditFolderModal owner={owner} onClose={() => setShowCreateModal(false)} />}
      {editFolder && (
        <EditFolderModal
          owner={owner}
          folderId={editFolder.id}
          initialValue={editFolder.name}
          onClose={() => setEditFolder(null)}
        />
      )}
      {!editFolder && modalFolder && (
        <FileFolderModal
          folderId={modalFolder.id}
          onEdit={() => setEditFolder(modalFolder)}
          onClose={() => setModalFolder(null)}
        />
      )}
    </FileUploadProvider>
  )
}
