import React, { useEffect, useRef, useState } from 'react'

function LogType({ value }) {
  const components = value.split('.')
  const first = components.shift()
  return (
    <>
      <span className="log-type-1">{first}</span>
      {components.map((c, i) => (
        <React.Fragment key={i}>
          <span className="log-type-sep">.</span>
          <span className="log-type-n">{c}</span>
        </React.Fragment>
      ))}
    </>
  )
}

export default function LogView({ dataLog }) {
  const [filter, setFilter] = useState('')
  const scrollerRef = useRef()
  const scrollLockRef = useRef(true)

  useEffect(() => {
    // jsdom does not have a scroll mock
    if (scrollerRef.current.scroll && scrollLockRef.current) {
      scrollerRef.current.scroll(0, scrollerRef.current.scrollHeight)
    }
  })

  if (dataLog.length === 0) {
    return (
      <div ref={scrollerRef} className="h-100 d-flex justify-content-center align-items-center">
        <div className="card">
          <div className="card-body bg-light text-center">
            <div className="spinner-border spinner-border-sm me-2" />
            Session started. Waiting for data...
          </div>
        </div>
      </div>
    )
  }

  if (scrollerRef.current && scrollerRef.current.scroll) {
    // If we are scrolled to bottom, keep the scroll locked to bottom
    const scrollY = scrollerRef.current.scrollTop + scrollerRef.current.clientHeight
    scrollLockRef.current = scrollY + 10 > scrollerRef.current.scrollHeight // Add 10 to create a small grace length
  }

  const filterProc = ({ type }) => {
    if (!filter.trim()) return true
    for (const f of filter.trim().split(/\s+/)) {
      if (type.startsWith(f)) return true
    }
    return false
  }

  return (
    <div className="d-flex h-100 flex-column">
      <div className="bg-light border-bottom p-2 position-relative">
        <input
          type="text"
          id="log_filter_input"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          className="form-control"
          placeholder="Filter log"
        />
        {filter && (
          <button
            type="button"
            id="clear_filter_button"
            onClick={() => setFilter('')}
            className="btn-close position-absolute end-0 top-50 translate-middle-y me-3"
          />
        )}
      </div>
      <div ref={scrollerRef} className="flex-grow-1 overflow-auto d-flex flex-column">
        {dataLog.filter(filterProc).map(({ serial, timestamp, type, details }) => (
          <div key={serial} className="border-top d-flex flex-row font-monospace fs-7">
            <div className="text-nowrap p-1 fw-bold">
              <LogType value={type} />
            </div>
            <div className="text-break flex-grow-1 p-1">{JSON.stringify(details)}</div>
            <div className="text-nowrap p-1 text-muted">{timestamp}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
