import React, { useContext, useReducer, useRef } from 'react'
import ReactDOM from 'react-dom'

const DragDropContext = React.createContext({})
const DroppableContext = React.createContext() // For communication between Droppable and Draggable
const DraggableContext = React.createContext() // For communication between Draggable and Draggable.Mover

function useDragDropContext(scope = 'default') {
  return useContext(DragDropContext)[scope]
}

function DragDropContextProvider({ scope = 'default', value, children }) {
  const existingContext = useContext(DragDropContext) || {}
  const newContext = { [scope]: value, ...existingContext }
  return <DragDropContext.Provider value={newContext}>{children}</DragDropContext.Provider>
}

function startDragDrop({ onMouseMove, onMouseUp }) {
  const _onMouseMove = (ev) => {
    ev.preventDefault() // Prevent text selection
    onMouseMove(ev)
  }

  const _onMouseUp = (ev) => {
    window.removeEventListener('mousemove', _onMouseMove)
    window.removeEventListener('mouseup', _onMouseUp)
    onMouseUp(ev)
  }

  window.addEventListener('mousemove', _onMouseMove)
  window.addEventListener('mouseup', _onMouseUp)
}

function GeometryHelper(mouseDownEvent, draggingRef) {
  const { clientX: mouseDownX, clientY: mouseDownY } = mouseDownEvent
  // eslint-disable-next-line prefer-const
  let { x: startX, y: startY, width, height } = draggingRef.current.getBoundingClientRect()

  // Move it up and left initially to give it a "lift" effect
  startX -= 2
  startY -= 2

  this.height = height
  this.width = width
  this.x = startX
  this.y = startY
  this.midpoint = this.y + height / 2

  this.update = (mouseMoveEvent) => {
    const { clientX: moveX, clientY: moveY } = mouseMoveEvent
    this.x = startX + moveX - mouseDownX
    this.y = startY + moveY - mouseDownY
    this.midpoint = this.y + height / 2
  }

  this.isHovering = (ref) => {
    const { top, bottom } = ref.current.getBoundingClientRect()
    return this.midpoint >= top && this.midpoint <= bottom
  }

  this.isBottomHalf = (ref) => {
    const { top, height } = ref.current.getBoundingClientRect()
    return this.midpoint > top + height / 2
  }

  this.isAbove = (ref) => {
    const { top } = ref.current.getBoundingClientRect()
    return this.midpoint < top
  }
}

function useDragState() {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'RESET':
        return {
          dragging: null,
          draggingLoc: null,
          floating: null,
        }

      case 'INIT':
        return {
          dragging: { ...action.dragging },
          draggingLoc: { ...action.draggingLoc },
          floating: { ...action.floating },
        }

      case 'UPDATE-LOCATION':
        return { ...state, draggingLoc: { x: action.x, y: action.y } }

      case 'UPDATE-FLOATING':
        return { ...state, floating: { id: action.id, index: action.index } }
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    dragging: null,
    draggingLoc: null,
    floating: null,
  })

  // Need to store state in a ref so that the mouseMove callback can access new state\
  // Otherwise, mouseMove will only get state at the time the closure was made
  const stateRef = useRef()
  stateRef.current = state

  return {
    reset: () => {
      dispatch({ type: 'RESET' })
    },

    init: ({ droppableId, index, geo }) => {
      const id = droppableId
      dispatch({
        type: 'INIT',
        dragging: {
          id,
          width: geo.width,
          height: geo.height,
          index,
        },
        floating: { id, index },
        draggingLoc: {
          x: geo.x,
          y: geo.y,
        },
      })
    },

    updateLocation: (geo) => {
      dispatch({ type: 'UPDATE-LOCATION', x: geo.x, y: geo.y })
    },

    updateFloating: (id, index) => {
      dispatch({ type: 'UPDATE-FLOATING', id, index })
    },

    getState: () => stateRef.current,
  }
}

function useRegistry() {
  const registry = useRef()

  registry.current = {
    droppables: {},
  }

  return {
    registerDroppable: (id, ref, onDrag) => {
      registry.current.droppables[id] = {
        id,
        ref,
        onDrag,
        draggables: [],
      }
    },

    registerDraggable: (id, index, ref) => {
      index = parseInt(index)
      registry.current.droppables[id].draggables[index] = { index, ref }
    },

    registerPlaceholder: (ref) => {
      registry.current.placeholder = ref
    },

    get droppables() {
      return Object.values(registry.current.droppables)
    },

    get placeholder() {
      return registry.current.placeholder
    },
  }
}

export function DragDrop({ disabled, scope, onDrop, children }) {
  const registry = useRegistry()
  const dragState = useDragState()

  const scopeContext = {
    ...dragState.getState(),
    registry,

    onMouseDown: (mouseDownEvent, { droppableId, index, item, ref }) => {
      if (disabled) return
      const geo = new GeometryHelper(mouseDownEvent, ref)
      dragState.init({ droppableId, index, geo })

      startDragDrop({
        onMouseMove: (ev) => {
          geo.update(ev)
          dragState.updateLocation(geo)

          // Figure out if we're floating over a droppable area
          const drp = Object.values(registry.droppables).find(({ ref }) => geo.isHovering(ref))

          if (!drp) {
            // Not floating over anything, return to default location
            dragState.updateFloating(droppableId, index)
            return
          }

          // Let the droppable element know that dragging is happening
          drp.onDrag && drp.onDrag()

          if (geo.isHovering(registry.placeholder)) {
            // placeholder - do nothing
            return
          }

          // Figure out if we're floating over a draggable
          const drg = drp.draggables.find((drg) => {
            // If it's the one being dragged then ignore it
            if (drp.id === droppableId && drg.index === index) {
              return false
            }
            return geo.isHovering(drg.ref)
          })

          let floatIndex = 0
          if (drg) {
            floatIndex = drg.index
            if (geo.isBottomHalf(drg.ref)) {
              floatIndex = drg.index + 1
            }
          } else {
            // Over a droppable but not a draggable
            // Put at front or end of list depending on which end it's closer to
            if (geo.isBottomHalf(drp.ref)) {
              floatIndex = drp.draggables.length
              if (floatIndex > 0) {
                // Don't put it to the end of list if it is above the first draggable (not including the item being dragged)
                let firstRef = drp.draggables[0].ref
                if (droppableId === drp.id && index === 0 && drp.draggables.length > 1) {
                  firstRef = drp.draggables[1].ref
                }
                if (geo.isAbove(firstRef)) {
                  floatIndex = 0
                }
              }
            }
          }
          dragState.updateFloating(drp.id, floatIndex)
        },

        onMouseUp: (ev) => {
          const { floating } = dragState.getState()
          const from = { id: droppableId, index }
          const to = { ...floating }
          if (to.id === droppableId && to.index > index) {
            to.index = to.index - 1
          }

          onDrop(from, to)
          dragState.reset()
        },
      })
    },
  }

  return (
    <DragDropContextProvider scope={scope} value={scopeContext}>
      {children}
    </DragDropContextProvider>
  )
}

export function Droppable({ scope, onDrag, id, Floater, children }) {
  const { registry } = useDragDropContext(scope)
  const droppableRef = useRef()
  const childWithRef = React.cloneElement(React.Children.only(children), { ref: droppableRef })

  registry.registerDroppable(id, droppableRef, onDrag)

  return <DroppableContext.Provider value={{ id, Floater, onDrag }}>{childWithRef}</DroppableContext.Provider>
}

export function Draggable({ scope, droppableId, index, item, children }) {
  const context = useDragDropContext(scope)
  const draggableRef = useRef()
  const childWithRef = React.cloneElement(React.Children.only(children), { ref: draggableRef })

  context.registry.registerDraggable(droppableId, index, draggableRef)

  const onMouseDown = (mouseDownEvent) => {
    context.onMouseDown(mouseDownEvent, {
      droppableId,
      index,
      item,
      ref: draggableRef,
    })
  }

  return <DraggableContext.Provider value={onMouseDown}>{childWithRef}</DraggableContext.Provider>
}

Draggable.Map = function DraggableMap({ scope, collection, children }) {
  const context = useDragDropContext(scope)
  const { id: droppableId, Floater } = useContext(DroppableContext)

  const newChildren = collection.map((item, index) => (
    <Draggable key={index} scope={scope} droppableId={droppableId} index={index} item={item}>
      {children(item)}
    </Draggable>
  ))

  let floaterElement = null

  if (context.dragging) {
    // If the item being dragged is from this droppable, put it in the floater
    if (context.dragging.id === droppableId) {
      const floaterStyle = {
        position: 'fixed',
        left: context.draggingLoc.x + 'px',
        top: context.draggingLoc.y + 'px',
        width: context.dragging.width + 'px',
      }
      floaterElement = ReactDOM.createPortal(
        <div style={floaterStyle}>
          <Floater>{newChildren[context.dragging.index]}</Floater>
        </div>,
        document.body
      )

      // Set to null for now so we don't mess up the placeholder location
      newChildren[context.dragging.index] = null
    }

    // If the drop location is within this droppable, add the placeholder in the right spot
    if (context.floating.id === droppableId) {
      newChildren.splice(context.floating.index, 0, context.renderPlaceholder(true))
    }

    // Filter out the null value (if it exists)
    newChildren.filter((c) => Boolean(c))
  }

  return (
    <>
      {newChildren}
      {floaterElement}
    </>
  )
}

Draggable.Mover = function DraggableMover({ children }) {
  const onMouseDown = useContext(DraggableContext)
  const childWithEvent = React.cloneElement(React.Children.only(children), { onMouseDown })
  return childWithEvent
}

Draggable.Placeholder = function DraggablePlaceholder({ scope, children }) {
  const context = useDragDropContext(scope)
  const { id: droppableId } = useContext(DroppableContext)
  const ref = useRef()

  // Function components always render in-line
  if (typeof children === 'function') {
    const show = context.floating && context.floating.id === droppableId
    const child = children(show)
    if (show) {
      context.registry.registerPlaceholder(ref)
    }
    return React.cloneElement(child, { ref, key: 'placeholder' })
  }

  // Otherwise, placeholder is inserted into Map
  context.renderPlaceholder = () => {
    const child = React.Children.only(children)
    context.registry.registerPlaceholder(ref)
    return React.cloneElement(child, { ref, key: 'placeholder' })
  }

  return null
}
