import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

export default function Toast({ timeout, onClose, className, children }) {
  const timerRef = useRef()
  useEffect(() => {
    // Auto-clear timerRef after X ms of inactivity
    // Set to 0 for no timeout
    if (timeout === undefined) {
      timeout = 5000
    } else {
      timeout = parseInt(timeout)
    }
    clearTimeout(timerRef.current)
    if (timeout > 0) {
      timerRef.current = setTimeout(onClose, timeout)
    }
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [timeout])
  const content = (
    <div className="position-fixed top-0 start-50 translate-middle-x p-3">
      <div className={'toast show align-items-center ' + className} role="alert">
        <div className="d-flex">
          <div className="toast-body flex-grow-1">{children}</div>
          <button onClick={onClose} type="button" className="btn-close me-2 m-auto" />
        </div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(content, document.body)
}
