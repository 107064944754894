import React from 'react'

export default function Waitable({ waiting, small, children }) {
  let spinClass = 'spinner-border'
  if (small) spinClass += ' spinner-border-sm'
  if (waiting) {
    return (
      <div className="position-relative d-inline-block">
        <div className="invisible">{children}</div>
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className={spinClass} />
        </div>
      </div>
    )
  }
  return children
}
