import React, { useRef } from 'react'

export default function Modal({ children, onClose }) {
  const bgRef = useRef()
  const bgClick = (ev) => {
    ev.stopPropagation()
    // Make sure it is not being triggerred on a child element
    if (ev.target === bgRef.current) {
      onClose && onClose()
    }
  }

  return (
    <>
      <div
        ref={bgRef}
        id="custom_modal_container"
        className="d-flex justify-content-center position-fixed top-0 start-0 w-100 h-100"
        onClick={bgClick}
        style={{ zIndex: '1030', background: 'rgba(0, 0, 0, 0.8)' }}
      >
        {children}
      </div>
    </>
  )
}
