import { useApolloClient, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React, { useContext, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Modal from '@@components/modal'
import Toast from '@@components/toast'
import Waitable from '@@components/waitable'
import { useWebsocket, WebsocketProvider } from '@@lib/websocket'
import { getIdToken } from '@@pages/login'

import SessionMain from './_main'
import { COMPLETE_SESSION_MUTATION, GET_SESSION_QUERY } from './_queries'
import SessionSidebar from './_sidebar'

export const SessionContext = React.createContext()

function OnlineBadge({ isOnline }) {
  if (isOnline) {
    return (
      <div className="badge bg-success fw-normal">
        <i className="bi bi-arrow-left-right me-2" />
        Connected
      </div>
    )
  }

  return (
    <div className="badge bg-secondary fw-normal">
      <i className="bi bi-cloud-slash me-2" />
      Not Connected
    </div>
  )
}

class IssueReported extends Error {
  name = 'IssueReported'
}

function ReportIssue() {
  const [toast, setToast] = useState(null)
  const [desc, setDesc] = useState('')
  const dropdownRef = useRef()
  const ws = useWebsocket()

  const dismiss = () => {
    const dropdown = bootstrap.Dropdown.getInstance(dropdownRef.current) /* eslint no-undef: "off" */
    setDesc('')
    dropdown.hide()
  }

  const onSubmit = () => {
    // report error
    const referenceNumber = Date.now() // So that the logs can be matched easily
    console.debug('referenceNumber', referenceNumber)
    Sentry.captureException(new IssueReported(`${desc}\nReference: ${referenceNumber}`), {
      extra: { desc },
      tags: { referenceNumber },
    })
    setToast('Error report sent.')
    dismiss()
    ws.sendMessage({
      type: 'ReportIssue',
      data: { referenceNumber },
    }).then(({ error }) => {
      if (error) {
        console.error(error)
        Sentry.captureException(new IssueReported(`Error report websocket send error. Reference: ${referenceNumber}`))
      }
    })
  }

  return (
    <div className="dropdown">
      <button
        ref={dropdownRef}
        id="report_error"
        type="button"
        className="btn btn-link p-0 m-0 fs-5"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
      >
        <i className="bi bi-flag" />
      </button>
      <div className="dropdown-menu p-3 shadow" style={{ minWidth: '30rem' }}>
        <h3>Report an issue</h3>
        <div className="my-2">
          <textarea
            className="form-control"
            placeholder="Describe the issue here"
            rows="3"
            value={desc}
            onChange={(ev) => setDesc(ev.target.value)}
          />
        </div>
        <div>
          <button onClick={onSubmit} type="button" className="btn btn-primary">
            Submit
          </button>
          <button onClick={dismiss} type="button" className="btn btn-outline-secondary ms-2">
            Cancel
          </button>
        </div>
      </div>
      {toast && (
        <Toast onClose={() => setToast(null)} className="fs-6">
          {toast}
        </Toast>
      )}
    </div>
  )
}

function SessionLayout() {
  const { session, refetch, close } = useContext(SessionContext)
  const [refetching, setRefetching] = useState(false)
  const client = useApolloClient()
  useWebsocket({
    onMessage: ({ type, data, reply }) => {
      switch (type.toLowerCase()) {
        case 'participantconnection':
          client.cache.modify({
            id: client.cache.identify(session.participant),
            fields: {
              isOnline() {
                return data.isOnline
              },
            },
          })
          break

        case 'eventcomplete':
          reply({
            type: 'UnloadEvent',
          })
          refetch()
          break
      }
    },
  })

  // TODO: Check if a task is running when it shouldn't
  const refreshClick = async () => {
    setRefetching(true)
    await refetch()
    setRefetching(false)
  }

  let pname = `${session.participant.first_name} ${session.participant.last_name}`
  if (pname.trim() === '') {
    pname = session.participant.externalId
  }

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row bg-light border-bottom">
          <div className="flex-grow-1 p-4 d-flex align-items-center">
            <h1 className="m-0 fs-4 fw-light align-self-center">{pname}</h1>
            <div className="ms-2">
              <OnlineBadge isOnline={session.participant.isOnline} />
            </div>
            <div className="ms-4">
              <Waitable small waiting={refetching}>
                <button
                  id="refresh_session_button"
                  onClick={refreshClick}
                  type="button"
                  className="btn btn-link p-0 m-0 fs-5"
                >
                  <i className="bi bi-arrow-repeat" />
                </button>
              </Waitable>
            </div>
            <div className="ms-4">
              <ReportIssue />
            </div>
          </div>
          <div className="p-4 align-self-center">
            <button onClick={close} type="button" className="btn-close" id="close_session_button" />
          </div>
        </div>

        <div className="flex-fill d-flex overflow-auto">
          <div className="flex-grow-1 overflow-auto position-relative">
            <SessionMain />
          </div>
          <div className="border-start overflow-auto" style={{ minWidth: '400px', maxWidth: '35%' }}>
            <SessionSidebar />
          </div>
        </div>
      </div>
    </>
  )
}

function WithWebsocket({ session, refetch }) {
  const history = useHistory()
  const [closing, setClosing] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const client = useApolloClient()
  const ws = useWebsocket()

  const redirectToParticipantPage = () => {
    const redirectPath = `/study/${session.participant.study.id}/participants/${session.participant.id}`
    history.push(redirectPath)
  }

  const close = () => {
    if (session.state !== 'started') {
      redirectToParticipantPage()
    } else {
      setClosing(true)
    }
  }

  const saveAndClose = async () => {
    setWaiting(true)
    const { error } = await client.mutate({
      mutation: COMPLETE_SESSION_MUTATION,
      variables: {
        input: {
          id: session.id,
        },
      },
    })
    if (error) {
      console.error(error)
    }
    await ws.sendMessage({
      type: 'SessionEnded',
      data: {
        sessionId: session.id,
      },
    })
    redirectToParticipantPage()
  }

  return (
    <SessionContext.Provider value={{ refetch, close, saveAndClose, waiting, session }}>
      <SessionLayout />
      {closing && (
        <Modal onClose={() => setClosing(waiting) /* Don't close if waiting */}>
          <div className="align-self-center card shadow">
            <Waitable waiting={waiting}>
              <div className="card-body text-center">
                <h5 className="my-2">This session is still in progress.</h5>
                <h5>Would you like to end it first?</h5>
              </div>
              <div className="card-footer d-flex">
                <button id="close_session_yes" type="button" onClick={saveAndClose} className="btn btn-primary me-1">
                  Yes
                </button>
                <button
                  id="close_session_no"
                  type="button"
                  onClick={redirectToParticipantPage}
                  className="btn btn-outline-secondary ms-1"
                >
                  No
                </button>
              </div>
            </Waitable>
          </div>
        </Modal>
      )}
    </SessionContext.Provider>
  )
}

export default function SessionPage() {
  const { params } = useRouteMatch()
  const { loading, error, data, refetch } = useQuery(GET_SESSION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        id: params.id,
      },
    },
  })

  if (error) throw error
  if (loading && !data) return <div className="spinner-border" />

  return (
    <WebsocketProvider role="researcher" token={getIdToken()} participantId={data.getSession.session.participant.id}>
      <WithWebsocket session={data.getSession.session} refetch={refetch} />
    </WebsocketProvider>
  )
}
